import React, { Component } from "react";
import "../../../assets/css/ProductsStyles.css";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="Products-main-container">
        <div className="Partners-heading-text-container">Our Products</div>
        <div className="Products-body-container">
          <div className="product-logo-container">
            <img
              src={require("../../../assets/image/smallcase-sq-logo.png")}
              alt="Smallcase"
            />
          </div>
          <div className="product-logo-container">
            <img src={require("../../../assets/image/gcore.png")} alt="Gcore" />
          </div>
        </div>
      </div>
    );
  }
}
export default Products;
