import React, { Component } from "react";
import "../../../assets/css/PartnersStyles.css";

const Styles = {
  imageStyles: {
    width: "50%"
  }
};
class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="Partners-main-container">
        <div className="Partners-heading-text-container">Our Partners</div>
        <div>
          <div className="partners-logo-list">
            <div className="slide">
              <img
                src={require("../../../assets/image/Angel-Broking.jpg")}
                style={{
                  width: "250px",
                  height: "70px"
                }}
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src={require("../../../assets/image/mymoneysage.png")}
                style={{
                  width: "250px",
                  height: "70px"
                }}
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src={require("../../../assets/image/kingsman.png")}
                style={{
                  width: "250px",
                  height: "80px"
                }}
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src={require("../../../assets/image/cProfit.jpg")}
                style={{
                  width: "250px",
                  height: "70px"
                }}
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src={require("../../../assets/image/invest-ascent-logo.jpg")}
                style={{
                  width: "250px",
                  height: "70px"
                }}
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src={require("../../../assets/image/RayabhariLogo.png")}
                style={{
                  width: "250px",
                  height: "70px"
                }}
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src={require("../../../assets/image/Wealth_Marga_Logo_2.png")}
                style={{
                  width: "260px"
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
