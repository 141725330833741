import React, { Component } from "react";
import "../../../assets/css/howItWorks.css";
import HowItWorksHeader from "./howItWorksHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
const Styles = {
  howItWorksgridStyles: {
    display: "flex",
    justifyContent: window.innerWidth >= 960 ? "space-between" : "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column"
  },
  howItWorksPaperStyle: {
    backgroundColor: "transparent",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14)"
  }
};
class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      howItWorksData: [
        {
          howItWorkImage: (
            <img
              src={require("../../../assets/image/How it works_DMAT account icon.svg")}
              alt="pexels-908288."
              // style={Styles.serviceImageStyle}
            />
          ),
          howItWorkNumber: "01",
          howItWorkDescription: "Demat account will be opened for customer."
        },
        {
          howItWorkImage: (
            <img
              src={require("../../../assets/image/How it works_Mutual Contract.svg")}
              alt="pexels-908288."
              // style={Styles.serviceImageStyle}
            />
          ),
          howItWorkNumber: "02",
          howItWorkDescription:
            "Mutual contract between customer and GTurns is sign up and undertake power of attorney."
        },
        {
          howItWorkImage: (
            <img
              src={require("../../../assets/image/How it works_Investment Plan.svg")}
              alt="pexels-908288."
              // style={Styles.serviceImageStyle}
            />
          ),
          howItWorkNumber: "03",
          howItWorkDescription:
            "Customer has to select the plan & amount for investment."
        },
        {
          howItWorkImage: (
            <img
              src={require("../../../assets/image/How it works_Team.svg")}
              alt="pexels-908288."
              // style={Styles.serviceImageStyle}
            />
          ),
          howItWorkNumber: "04",
          howItWorkDescription:
            "GTurns has expertise team, perform investment on behalf of customer."
        },
        {
          howItWorkImage: (
            <img
              src={require("../../../assets/image/How it works_Service Charge.svg")}
              alt="pexels-908288."
              // style={Styles.serviceImageStyle}
            />
          ),
          howItWorkNumber: "05",
          howItWorkDescription:
            "Once the profit is booked GTurns charges service fee as a commission."
        },
        {
          howItWorkImage: (
            <img
              src={require("../../../assets/image/How it works_Withdraw.svg")}
              alt="pexels-908288."
              // style={Styles.serviceImageStyle}
            />
          ),
          howItWorkNumber: "06",
          howItWorkDescription:
            "Customer can withdraw the invested profit from the linked bank account."
        },
        {
          howItWorkImage: (
            <img
              src={require("../../../assets/image/How it works_Report.svg")}
              alt="pexels-908288."
              // style={Styles.serviceImageStyle}
            />
          ),
          howItWorkNumber: "07",
          howItWorkDescription:
            "Report and invoice is sent monthly to keep transparency between customer & GTurns."
        }
      ]
    };
  }
  render() {
    return (
      <div className="howItWorks-main-container">
        <HowItWorksHeader />
        <div className="howItWorks-body-container">
          <div className="howItWorks-body-contents">
            <div className="howItWorks-dashed-line-div1">
              <div className="howItWorks-dashed-line1" />
            </div>
            <div className="howItWorks-dashed-line-div2">
              <div className="howItWorks-dashed-line2" />
            </div>
            <div className="howItWorks-dashed-line-div3">
              <div className="howItWorks-dashed-line3" />
            </div>
            <div className="howItWorks-card1-container">
              <div className="howItWorks-card-no-1">
                <div className="howItWorks-card1-circle-section">
                  <div className="howItWorks-card1-square-section">
                    <img
                      src={require("../../../assets/image/How it works_DMAT account icon.svg")}
                      alt="pexels-908288."
                    />
                  </div>
                </div>
              </div>
              <div className="howItWorks-card-count-1">01</div>
              <div className="howItWorks-card-no-1-text">
                <div className="howItWorks-card-text-heading">
                  Demat account will be opened for customer.
                </div>
                {/* <div className="howItWorks-card-text-description">
                  We healp you to create DEMAT account. We will apply all the
                  security policy while managing your account. so that there is
                  no possibility of missusing it.
                </div> */}
              </div>
            </div>
            <div className="howItWorks-dashed-line-div4">
              <div className="howItWorks-dashed-line4" />
            </div>
            <div className="howItWorks-dashed-line-div5">
              <div className="howItWorks-dashed-line5" />
            </div>
            <div className="howItWorks-dashed-line-div6">
              <div className="howItWorks-dashed-line6" />
            </div>
            <div className="howItWorks-card2-container">
              <div className="howItWorks-card-count-2">02</div>
              <div className="howItWorks-card-no-2-text">
                <div className="howItWorks-card-no-2-text-heading">
                  Mutual contract between customer and GTurns is sign up and
                  undertake power of attorney.
                </div>
                {/* <div className="howItWorks-card-no-2-text-description">
                  Contract will help both of us to take the process smoothly.
                  Wewill care all the major terms and condition in our contract.
                  letshave everything secure and systematic.
                </div> */}
              </div>
              <div className="howItWorks-card-no-2-container">
                <div className="howItWorks-card-no-2-circle-container">
                  <div className="howItWorks-card-no-2-square-coantainer">
                    <img
                      src={require("../../../assets/image/How it works_Mutual Contract.svg")}
                      alt="pexels-908288."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="howItWorks-dashed-line-div7">
              <div className="howItWorks-dashed-line7" />
            </div>
            <div className="howItWorks-dashed-line-div5">
              <div className="howItWorks-dashed-line5" />
            </div>
            <div className="howItWorks-dashed-line-div8">
              <div className="howItWorks-dashed-line8" />
            </div>
            <div className="howItWorks-card1-container">
              <div className="howItWorks-card-no-3">
                <div className="howItWorks-card3-circle3-section">
                  <div className="howItWorks-card3-square3-section">
                    <img
                      src={require("../../../assets/image/How it works_Investment Plan.svg")}
                      alt="pexels-908288."
                    />
                  </div>
                </div>
              </div>
              <div className="howItWorks-card-count-1">03</div>
              <div className="howItWorks-card-no-1-text">
                <div className="howItWorks-card-text-heading">
                  Customer has to select the plan & amount for investment.
                </div>
                {/* <div className="howItWorks-card-text-description">
                  You have all the rights to select your investment planand
                  investment amount. We also suggest you the bestplan suits for
                  you.
                </div> */}
              </div>
            </div>
            <div className="howItWorks-dashed-line-div4">
              <div className="howItWorks-dashed-line4" />
            </div>
            <div className="howItWorks-dashed-line-div5">
              <div className="howItWorks-dashed-line5" />
            </div>
            <div className="howItWorks-dashed-line-div6">
              <div className="howItWorks-dashed-line6" />
            </div>
            <div className="howItWorks-card2-container">
              <div className="howItWorks-card-count-2">04</div>
              <div className="howItWorks-card-no-2-text">
                <div className="howItWorks-card-no-2-text-heading">
                  GTurns has expertise team, perform investment on behalf of
                  customer.
                </div>
                {/* <div className="howItWorks-card-no-2-text-description">
                  After your investment you will be free from risk. Our
                  expertise,experienced team will trade on behalf of you. We
                  will make surethere is no harm for base amount.S
                </div> */}
              </div>
              <div className="howItWorks-card-no-4">
                <div className="howItWorks-card4-circle4-section">
                  <div className="howItWorks-card4-square4-section">
                    <img
                      src={require("../../../assets/image/How it works_Team.svg")}
                      alt="pexels-908288."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="howItWorks-dashed-line-div7">
              <div className="howItWorks-dashed-line7" />
            </div>
            <div className="howItWorks-dashed-line-div5">
              <div className="howItWorks-dashed-line5" />
            </div>
            <div className="howItWorks-dashed-line-div8">
              <div className="howItWorks-dashed-line8" />
            </div>
            <div className="howItWorks-card1-container">
              <div className="howItWorks-card-no-1">
                <div className="howItWorks-card1-circle-section">
                  <div className="howItWorks-card1-square-section">
                    <img
                      src={require("../../../assets/image/How it works_Service Charge.svg")}
                      alt="pexels-908288."
                    />
                  </div>
                </div>
              </div>
              <div className="howItWorks-card-count-1">05</div>
              <div className="howItWorks-card-no-1-text">
                <div className="howItWorks-card-text-heading">
                  Once the profit is booked GTurns charges service fee as a
                  commission.
                </div>
                {/* <div className="howItWorks-card-text-description">
                  As we taking care of all these process, we wiil chargeyou a
                  small amount of service fee on your profit.
                </div> */}
              </div>
            </div>
            <div className="howItWorks-dashed-line-div4">
              <div className="howItWorks-dashed-line4" />
            </div>
            <div className="howItWorks-dashed-line-div5">
              <div className="howItWorks-dashed-line5" />
            </div>
            <div className="howItWorks-dashed-line-div6">
              <div className="howItWorks-dashed-line6" />
            </div>
            <div className="howItWorks-card2-container">
              <div className="howItWorks-card-count-2">06</div>
              <div className="howItWorks-card-no-2-text">
                <div className="howItWorks-card-no-2-text-heading">
                  Customer can withdraw the invested profit from the linked bank
                  account.
                </div>
                {/* <div className="howItWorks-card-no-2-text-description">
                  You can withdraw investment profit at any time. there is
                  norestriction to take your money.
                </div> */}
              </div>
              <div className="howItWorks-card-no-2-container">
                <div className="howItWorks-card-no-2-circle-container">
                  <div className="howItWorks-card-no-6-square-coantainer">
                    <img
                      src={require("../../../assets/image/How it works_Withdraw.svg")}
                      alt="pexels-908288."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="howItWorks-dashed-line-div7">
              <div className="howItWorks-dashed-line7" />
            </div>
            <div className="howItWorks-dashed-line-div5">
              <div className="howItWorks-dashed-line5" />
            </div>
            <div className="howItWorks-dashed-line-div8">
              <div className="howItWorks-dashed-line8" />
            </div>
            <div className="howItWorks-card1-container">
              <div className="howItWorks-card-no-1">
                <div className="howItWorks-card3-circle3-section">
                  <div className="howItWorks-card3-square3-section">
                    <img
                      src={require("../../../assets/image/How it works_Report.svg")}
                      alt="pexels-908288."
                    />
                  </div>
                </div>
              </div>
              <div className="howItWorks-card-count-1">07</div>
              <div className="howItWorks-card-no-1-text">
                <div className="howItWorks-card-text-heading">
                  Report and invoice is sent monthly to keep transparency
                  between customer & GTurns.
                </div>
                {/* <div className="howItWorks-card-text-description">
                  We will send the report each and every month without failthis
                  will help you to check the growth. very soon our systemwill
                  help you to get the amount any time.
                </div> */}
              </div>
            </div>
          </div>
          <div className="howItWorksMobileView">
            <Grid container style={Styles.howItWorksgridStyles}>
              {this.state.howItWorksData.map((HIW, i) => (
                <Grid key={i}>
                  <div className="howItWorks-dashed-line-div1">
                    <div className="howItWorks-dashed-line1" />
                  </div>
                  <Paper
                    className="howItWorks-cards-Paper"
                    style={Styles.howItWorksPaperStyle}
                  >
                    <div className="howItWorks-card-main-container">
                      <div className="howItWorks-card-image-container">
                        <div className="howItWorks-card1-circle-section">
                          <div className="howItWorks-card1-square-section">
                            {HIW.howItWorkImage}
                          </div>
                        </div>
                      </div>
                      <div className="howItWorks-card-text-container">
                        <div className="howItWorks-card-number-text-container">
                          {HIW.howItWorkNumber}
                        </div>
                        <div className="howItWorks-card-description-text-container">
                          {HIW.howItWorkDescription}
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
export default HowItWorks;
