import React, { Component } from "react";
import "../../../../assets/css/DashboardStyles/bannerStyles.css";
// import { start } from "repl";
export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulbHovering: false,
      target: false,
      dataDriven: false,
      investmentPlan: false,
      report: false,
      goal: false,
      scroll: "hi",
    };
  }
  componentWillUnmount() {
    // clearInterval(this.timer);
    // clearTimeout(this.delayTimout);
  }
  componentDidMount() {
    // this.delayTimout = setTimeout(() => {
    //   let i = 0;
    //   let intr = [0, 1200, 2500];
    //   this.timer = setInterval(() => {
    //     if (
    //       this.myRef.scrollLeft !== 0 &&
    //       this.myRef.scrollLeft !== 1200 &&
    //       this.myRef.scrollLeft !== 2500
    //     ) {
    //       console.log(typeof this.myRef.scrollLeft);
    //       clearInterval(this.timer);
    //       clearTimeout(this.delayTimout);
    //       return;
    //     }
    //     this.myRef.scrollLeft = intr[i++];
    //   }, 2000);
    // }, 80);
  }
  render() {
    return (
      <div
        style={{
          color: "#fff",
          background: "#19150799",
          height: "100%",
          width: document.documentElement.clientWidth,
          overflow: "hidden",
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <svg style={{ height: "100%", width: "100%" }} />
        </div>
        <div
          style={{
            width: "100%",
            position: "relative",
            top: "-100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              height: "100%",
            }}
          >
            <div
              ref={(ref) => {
                this.myRef = ref;
              }}
              className="banner-backgroundImage"
              style={{
                overflowX: "auto",
                height: "100%",
                display: "flex",
                width: "100%",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundImage:
                  "url(" +
                  require("../../../../assets/image/bannerImages/HomePage_Banner_Updated_3.png") +
                  ")",
              }}
            >
              <div
                className="background-image-contain"
                // style={{
                //   display: "flex",
                //   // justifyContent: "center",
                //   width: "100%",
                //   marginTop: 100,
                //   flexDirection: "column",
                // }}
              >
                <span
                  style={{
                    textAlign: "center",
                  }}
                  className="aboutUs-heading"
                >
                  Your Investment is in Safe Hands
                </span>
                <div
                  style={{
                    color: "rgba(204, 204, 204, 0.8) ",
                    fontSize: "16px",
                    marginTop: "1%",
                  }}
                >
                  <span>
                    {" "}
                    <b
                      style={{
                        marginRight: "2px",
                      }}
                    >
                      .
                    </b>
                    Portfolio management service
                  </span>
                  <span style={{ marginLeft: "3%" }}>
                    <b style={{ marginRight: "2px" }}> .</b>
                    Financial advisories
                  </span>
                  <span style={{ marginLeft: "3%" }}>
                    <b style={{ marginRight: "2px" }}>.</b>
                    Assets management
                  </span>
                </div>
              </div>
              {/* <div style={{ display: "flex" }}>
                <div style={{ width: 282.6 }}>
                  {!this.state.startinvesting && (
                    <img
                      // onMouseEnter={() =>
                      //   this.setState({ startinvesting: true })
                      // }
                      src={require("../../../../assets/image/bannerImages/01-start-saving.png")}
                      alt="Loading"
                      style={{ height: 250, top: 211, position: "relative" }}
                    />
                  )}
                  {this.state.startinvesting && (
                    <div
                      // onMouseLeave={() =>
                      //   this.setState({ startinvesting: false })
                      // }
                      style={{
                        height: 250,
                        width: 448.6,
                        borderRadius: 10,
                        background: "#00000054",
                        cursor: "pointer",
                        position: "relative",
                        top: 211,
                        color: "#fff",
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 30,
                          padding: 30,
                          textTransform: "capitalize",
                        }}
                      >
                        Saving is like earning
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          letterSpacing: 1,
                          fontWeight: 300,
                          color: "#eee",
                        }}
                      >
                        Start saving to achive your goal and create wealth for
                        your family
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div
                // onMouseEnter={() => this.setState({ thinkImage: true })}
                // onMouseLeave={() => this.setState({ thinkImage: false })}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 224,
                }}
              >
                {!this.state.thinkImage && (
                  <img
                    src={require("../../../../assets/image/bannerImages/02-think-invest.png")}
                    alt="Loading"
                    style={{ position: "relative", top: 211, height: 250 }}
                  />
                )} */}
              {/* {this.state.thinkImage && (
                  <div
                    style={{
                      height: 250,
                      width: 224,
                      borderRadius: 10,
                      background: "#00000054",
                      cursor: "pointer",
                      position: "relative",
                      top: -160,
                      color: "#fff",
                      padding: 20,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        fontSize: 30,
                        padding: 30,
                        textTransform: "capitalize"
                      }}
                    >
                      Think and invest
                    </div>
                    <div
                      style={{
                        fontSize: 16,
                        letterSpacing: 1,
                        fontWeight: 300,
                        color: "#eee"
                      }}
                    >
                      Some details goes here
                    </div>
                  </div>
                )} */}
              {/* 
                <div>
                  {!this.state.bulbHovering && (
                    <img
                      onMouseEnter={() => this.setState({ bulbHovering: true })}
                      src={require("../../../../assets/image/bannerImages/chalk_effect-think-icon.png")}
                      alt="Loading"
                      style={{
                        position: "relative",
                        top: 158,
                        cursor: "pointer",
                        height: 100,
                      }}
                    />
                  )}
                  {this.state.bulbHovering && (
                    <img
                      onMouseLeave={() =>
                        this.setState({ bulbHovering: false })
                      }
                      src={require("../../../../assets/image/bannerImages/chalk_effect-think-icon-hover.png")}
                      alt="Loading"
                      style={{
                        position: "relative",
                        top: 130,
                        left: 2,
                        cursor: "pointer",
                        height: 130,
                      }}
                    />
                  )}
                </div>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 211,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div style={{ zIndex: 45 }}>
                    <img
                      src={require("../../../../assets/image/bannerImages/market-analysis-search (1).png")}
                      alt="market-analysis-searchimage"
                      style={{
                        position: "relative",
                        height: 100,
                        cursor: "pointer",
                        top: 260,
                        left: 52,
                      }}
                    />
                  </div>
                  <div style={{ zIndex: 20 }}>
                    <img
                      src={require("../../../../assets/image/bannerImages/earth-1.gif")}
                      alt="Earth gif"
                      style={{
                        position: "relative",
                        top: 116,
                        height: 120,
                        cursor: "pointer",
                        opacity: 0.8,
                      }}
                    />
                  </div>

                  <img
                    src={require("../../../../assets/image/bannerImages/03-market-analysis.png")}
                    alt="Loading"
                    style={{
                      position: "relative",
                      height: 286,
                      cursor: "pointer",
                    }}
                    className="market-analysis-image"
                  />
                </div>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{ width: 401 }}
                  // onMouseEnter={() => this.setState({ dataDriven: true })}
                  // onMouseLeave={() => this.setState({ dataDriven: false })}
                >
                  {!this.state.dataDriven && (
                    <img
                      src={require("../../../../assets/image/bannerImages/04-data-driven.png")}
                      alt="Loading"
                      style={{
                        position: "relative",
                        top: 211,
                        height: 250,
                        cursor: "pointer",
                      }}
                      className="data-driven-image"
                    />
                  )}
                  {this.state.dataDriven && (
                    <div
                      // onMouseLeave={() => this.setState({ dataDriven: false })}
                      style={{
                        height: 250,
                        width: 645,
                        borderRadius: 10,
                        background: "#00000054",
                        cursor: "pointer",
                        position: "relative",
                        top: 2,
                        color: "#fff",
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 30,
                          padding: 30,
                          textTransform: "capitalize",
                        }}
                      >
                        Data Driven Model
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          letterSpacing: 1,
                          fontWeight: 300,
                          color: "#eee",
                        }}
                      >
                        High probability investments based on data driven and
                        proven scientific model Effective money management in
                        all market conditions .
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div style={{ display: "flex" }}>
                <div
                  style={{ width: 361 }}
                  // onMouseEnter={() => this.setState({ investmentPlan: true })}
                  // onMouseLeave={() => this.setState({ investmentPlan: false })}
                >
                  <img
                    src={require("../../../../assets/image/bannerImages/costom-investment-icon.png")}
                    alt="Loading"
                    style={{
                      position: "relative",
                      top: 204,
                      height: 64,
                      cursor: "pointer",
                      left: 99,
                    }}
                  />
                  {!this.state.investmentPlan && (
                    <img
                      src={require("../../../../assets/image/bannerImages/05-costom-investment.png")}
                      alt="Loading"
                      style={{
                        position: "relative",
                        top: 143,
                        height: 250,
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {this.state.investmentPlan && (
                    <div
                      onMouseLeave={() =>
                        this.setState({ investmentPlan: false })
                      }
                      style={{
                        height: 250,
                        width: 570,
                        borderRadius: 10,
                        background: "#00000054",
                        cursor: "pointer",
                        position: "relative",
                        top: 205,
                        color: "#fff",
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 30,
                          padding: 30,
                          textTransform: "capitalize",
                        }}
                      >
                        Custom Investment Plans
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          letterSpacing: 1,
                          fontWeight: 300,
                          color: "#eee",
                        }}
                      >
                        Aligning our investment plans to meet the needs of our
                        clients .
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div style={{ display: "flex" }}>
                <img
                  src={require("../../../../assets/image/bannerImages/06-help.png")}
                  alt="Loading"
                  style={{ position: "relative", top: 213, height: 250 }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{ width: 409 }}
                  // onMouseEnter={() => this.setState({ report: true })}
                  // onMouseLeave={() => this.setState({ report: false })}
                >
                  {!this.state.report && (
                    <img
                      src={require("../../../../assets/image/bannerImages/07-report.png")}
                      alt="Loading"
                      style={{
                        position: "relative",
                        top: 211,
                        height: 250,
                      }}
                    />
                  )}
                  {this.state.report && (
                    <div
                      // onMouseLeave={() => this.setState({ report: false })}
                      style={{
                        height: 250,
                        width: 649,
                        borderRadius: 10,
                        background: "#00000054",
                        cursor: "pointer",
                        position: "relative",
                        top: 205,
                        color: "#fff",
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 30,
                          padding: 30,
                          textTransform: "capitalize",
                        }}
                      >
                        Get Your Report
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          letterSpacing: 1,
                          fontWeight: 300,
                          color: "#eee",
                        }}
                      >
                        Get your report every month and We will help you to get
                        the report on Demand .
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div style={{ display: "flex" }}>
                <div
                  style={{ width: 467 }}
                  // onMouseEnter={() => this.setState({ goal: true })}
                  // onMouseLeave={() => this.setState({ goal: false })}
                >
                  {!this.state.goal && (
                    <img
                      src={require("../../../../assets/image/bannerImages/08-goal.png")}
                      alt="Loading"
                      style={{
                        position: "relative",
                        top: 212,
                        height: 250,
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {this.state.goal && (
                    <div
                      // onMouseLeave={() => this.setState({ goal: false })}
                      style={{
                        height: 340,
                        width: 637,
                        borderRadius: 10,
                        background: "#00000054",
                        cursor: "pointer",
                        position: "relative",
                        top: 205,
                        color: "#fff",
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 30,
                          padding: 30,
                          textTransform: "capitalize",
                        }}
                      >
                        Achive Your Goal
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          letterSpacing: 1,
                          fontWeight: 300,
                          color: "#eee",
                        }}
                      >
                        Your Goal is our vision .
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              {/* 
              <div style={{ display: "flex", minWidth: 292 }}>
                {!this.state.target && (
                  <img
                    onMouseEnter={() => this.setState({ target: true })}
                    onMouseLeave={() => this.setState({ target: false })}
                    src={require("../../../../assets/image/bannerImages/target-before-hover.png")}
                    alt="Loading"
                    style={{
                      position: "relative",
                      right: 76,
                      top: 126,
                      height: 163,
                      cursor: "pointer",
                    }}
                  />
                )}
                {this.state.target && (
                  <img
                    onMouseLeave={() => this.setState({ target: false })}
                    src={require("../../../../assets/image/bannerImages/target-after-hover.png")}
                    alt="Loading"
                    style={{
                      position: "relative",
                      right: 76,
                      top: 126,
                      height: 163,

                      cursor: "pointer",
                    }}
                  />
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
