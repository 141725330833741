import React, { Component } from "react";
import "../../../assets/css/faqStyle.css";

class Faqheader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="TransparentBlackLayer">
        <div className="faq-heading-text-image-container">
          <img
            src={require("../../../assets/image/FAQ_Page-image-2.png")}
            alt="Faq"
          />
          <div className="faq-header-container">Frequently Asked Questions</div>
        </div>
      </div>
    );
  }
}
export default Faqheader;
