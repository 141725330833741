import React, { Component } from "react";
import "../../../assets/css/ourMissionStyles.css";
// import Vision1 from "../../../assets/image/aboutUs-Vission-1.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="Mission-container">
        <div className="mission-heading">Our Vision</div>
        <div className="mission-content">
          <div className="mission-image-holder">
            <div className="misson-handskake-image">
              <img
                src={require("../../../assets/image/vission_handshake.svg")}
                className="mission-image"
                alt=""
                style={{ width: "80%" }}
              />
            </div>
            <div className="mission-image-text" style={{ marginTop: "14%" }}>
              A WIN-WIN STRATEGY
            </div>
          </div>
          <div className="mission-image-holder">
            <div className="mission-goal-image">
              <img
                src={require("../../../assets/image/vission_goal.svg")}
                className="mission-image"
                alt=""
                style={{ width: "80%" }}
              />
            </div>
            <div className="mission-image-text" style={{ marginTop: "14%" }}>
              ATTAIN INVESTOR’S GOAL
            </div>
          </div>
          <div className="mission-image-holder">
            <div className="mission-capital-protective-image">
              <img
                src={require("../../../assets/image/vission_capital protection-1.svg")}
                className="mission-image"
                alt=""
                style={{ width: "80%" }}
              />
            </div>
            <div className="mission-image-text" style={{ marginTop: "14%" }}>
              CAPITAL PROTECTION
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
