import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import "../../../assets/css/testimonialsStyles.css";
const styles = {
  testimonialImage: {
    backgroungSize: "contained",
    borderRadius: 75,
    height: 90,
    width: 90,
    position: "relative",
    top: 62,
    padding: 2,
    backgroundColor: "#fafafa",
    boxShadow:
      "6px 10px 23px 1px rgba(0, 0, 0, 0.2),    5px -1px 15px -7px rgba(0, 0, 0, 0.14),    0px -1px 1px -2px rgba(0, 0, 0, 0.14)"
  },
  cardContainer: {
    padding: 13
    // width: window.innerWidth >= 960 ? "35%" : "70%"
  },
  cardStyle: {
    backgroundColor: "rgb(241, 236, 236)",
    textAlign: "left",
    alignItems: "flex-start",
    justifyContent: "left",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 15px 15px black",
    zIndex: 3000
  },
  testimonialMessage: {
    color: "#000",
    lineHeight: 2,
    textAlign: "left",
    margin: 20,
    marginTop: 0,
    paddingLeft: 15,
    fontSize: 15,
    fontStyle: "italic"
  },
  senderName: {
    color: "#000",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    width: "87%",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: 60,
    opacity: 0.9,
    paddingRight: 20,
    fontSize: 15,
    paddingBottom: 20
  },
  senderRole: { fontSize: 15, color: "gray", paddingLeft: 10 }
};
class Header extends Component {
  render() {
    return (
      <div className="testimonials-container">
        <div className="testimonials-heading">What Our Customers Say</div>
        <div style={{ paddingBottom: 100 }}>
          <div className="testimonial-card-container">
            <div className="card-container" style={styles.cardContainer}>
              <img
                src={require("../../../assets/image/rajesh.jpg")}
                style={styles.testimonialImage}
                alt=""
              />
              <Card style={styles.cardStyle} className="card">
                <div className="testimonial-cardContainer">
                  <span
                    style={{
                      width: "90%",
                      marginTop: "15%",
                      marginLeft: "30px"
                    }}
                  >
                    <img
                      src={require("../../../assets/image/testimonials.svg")}
                      alt=""
                    />
                  </span>
                  <div className="testimonial-message-and-customer-name-container">
                    <div
                      className="testimonial-message-text-container"
                      style={styles.testimonialMessage}
                    >
                      <span>
                        Best investment advisor and portfolio management Service
                        provider
                      </span>
                    </div>
                    <div
                      className="testimonial-customer-name-container"
                      style={styles.senderName}
                    >
                      <span>
                        - Rajesh Surasgar,
                        <br />
                        <span style={styles.senderRole}>
                          Program Analyst Web Synergies.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            <div className="card-container" style={styles.cardContainer}>
              <img
                src={require("../../../assets/image/chetan.jpg")}
                style={styles.testimonialImage}
                alt=""
              />
              <Card style={styles.cardStyle} className="card">
                <div className="testimonial-cardContainer">
                  <span
                    style={{
                      width: "90%",
                      marginTop: "15%",
                      marginLeft: "30px"
                    }}
                  >
                    <img
                      src={require("../../../assets/image/testimonials.svg")}
                      alt=""
                    />
                  </span>
                  <div className="testimonial-message-and-customer-name-container">
                    <div
                      className="testimonial-message-text-container"
                      style={styles.testimonialMessage}
                    >
                      <span>
                        Trust and transparency is key to partnering with Lakshmi
                        Narayana R, and definitely, there is a steady protection
                        of wealth and good returns.
                      </span>
                    </div>
                    <div
                      className="testimonial-customer-name-container"
                      style={styles.senderName}
                    >
                      <span>
                        - Chetan Sridhar,
                        <br />
                        <span style={styles.senderRole}>
                          UX Developer, Akamai Technologies.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="card-container" style={styles.cardContainer}>
              <img
                src={require("../../../assets/image/shwetang.jpg")}
                style={styles.testimonialImage}
                alt=""
              />
              <Card style={styles.cardStyle} className="card">
                <div className="testimonial-cardContainer">
                  <span
                    style={{
                      width: "90%",
                      marginTop: "15%",
                      marginLeft: "30px"
                    }}
                  >
                    <img
                      src={require("../../../assets/image/testimonials.svg")}
                      alt=""
                    />
                  </span>
                  <div className="testimonial-message-and-customer-name-container">
                    <div
                      className="testimonial-message-text-container"
                      style={styles.testimonialMessage}
                    >
                      <span>
                        Totally worth investing with them in equities. The exit
                        logic they have ensures a minimal loss while trading.
                      </span>
                    </div>
                    <div
                      className="testimonial-customer-name-container"
                      style={styles.senderName}
                    >
                      <span>
                        - Shwetang Singh,
                        <br />
                        <span style={styles.senderRole}>
                          {" "}
                          Product Manager,Delhivery.com
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
