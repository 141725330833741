import React, { Component } from "react";
import "../../../assets/css/blogImageStyle.css";
class BlogImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="blog-image-container">
        <img
          className="blog-image"
          alt="blogImage"
          // src={require("../../../assets/image/Retirement_planning.jpg")}
          src={this.props.blogImage}
          style={{ boxShadow: "#80808050 2px 2px 3px" }}
        />
      </div>
    );
  }
}
export default BlogImage;
