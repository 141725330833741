import React, { Component } from "react";
import "../../../assets/css/blogListHowMuchRiskCards.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import apic from "../../../Core/Apic/apiClint";
const Styles = {
  gridStyles: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    padding: "0 2em",
  },
  cardStyles: {
    backgroundColor: "white",
    cursor: "pointer",
  },
};
class HowMuchRiskCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RiskCards: [],
    };
    this.handleGetBlogs = this.handleGetBlogs.bind(this);
  }
  async componentDidMount() {
    this.handleGetBlogs();
  }
  handleGetBlogs() {
    let user = {};
    apic.getBlogs(user, (result, status) => {
      // console.log(status, "stauts before case");
      switch (status) {
        case 0:
          console.log("Failed to connect to the server");
          alert("Failed to connect to the server");
          break;
        case 200:
          let jsonRes = JSON.parse(result);
          let blogArray = jsonRes
            .map((blogSta) => {
              if (blogSta.status === "Active") {
                return blogSta;
              } else {
                return null;
              }
            })
            .filter((elem) => {
              return elem !== null;
            });
          this.setState({ RiskCards: blogArray });
          // console.log(blogArray, "Blog data");

          break;
        case 400:
          // console.log("Invalid Params");
          alert(result);
          break;
        case 401:
          // alert("Invalid Params");
          break;
        default:
          console.log("current status", status);
        // alert(result, status);
      }
    });
  }
  readBlog = (id) => {
    this.props.history.push("/id/" + id);
    console.log(this.props, "Props");
  };
  dateFormater = (date) => {
    var monthsArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateContainer = date.split("-");
    var dateNum = dateContainer[2];
    var month = monthsArray[dateContainer[1] - 1];
    var year = dateContainer[0];
    var blogDate = month + " " + dateNum + ", " + year;
    return blogDate;
  };
  render() {
    return (
      <div className="HowMuchRiskCard-main-container">
        <div className="HowMuchRiskCard-body-container">
          <Grid container style={Styles.gridStyles}>
            {this.state.RiskCards.slice(0, 3).map((RiskCard, i) => (
              <Grid key={i} style={{ width: "100%" }}>
                <Paper
                  style={Styles.cardStyles}
                  onClick={() => {
                    this.readBlog(RiskCard.id);
                  }}
                >
                  <div className="HowMuchRiskCard-body-card-container">
                    <div className="HowMuchRiskCard-body-card-image-container">
                      <img
                        src={RiskCard.image}
                        alt="blog"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="HowMuchRiskCard-body-card-text-container">
                      <div>
                        <div className="HowMuchRiskCard-text-header-container">
                          {RiskCard.title}
                        </div>
                        <div className="HowMuchRiskCard-text-Name-container">
                          {RiskCard.author}
                        </div>
                      </div>
                      <div className="HowMuchRiskCard-text-Date-Read-more-container">
                        <div className="HowMuchRiskCard-text-Date-container">
                          {/* {RiskCard.date} */}
                          {/* {new Date(RiskCard.date)
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                            .replace(/ /g, "-")} */}
                          {this.dateFormater(RiskCard.date)}
                        </div>
                        <div
                          className="HowMuchRiskCard-text-Read-more-container"
                          onClick={() => {
                            this.readBlog(RiskCard.id);
                          }}
                        >
                          Read More
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}
export default HowMuchRiskCard;
