import React, { Component } from "react";
import "../../../assets/css/DashboardStyles/servicesStyles.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogDreamCar from "./DreamCar";
import DialogDreamHome from "./DreamHome";
import DialogMarriagePlan from "./MarriagePlan";
import DialogEducationPlan from "./EducationPlan";
import DialogTravelPlan from "./TravelPlan";
import DialogIncomePlan from "./IncomePlan";
import DialogInvestmentPlan from "./InvestmentPlan";
import DialogGrowYourMoneyPlan from "./GrowYourMoney";
import DialogRetirementPlan from "./retirementPlan";
const Styles = {
  gridStyles: {
    display: "flex",
    justifyContent: window.innerWidth >= 960 ? "space-between" : "center",
    alignItems: "center",
    width: "100%"
  },
  buttonStyle: {
    border: "1px solid #fbb938",
    width: "150px",
    color: "#fbb938",
    borderRadius: "3px"
  },
  descriptionDiv1Style: {
    letterSpacing: "0.3px",
    lineHeight: "1.8"
  },
  descriptionsDiv2Style: {
    marginTop: "5%",
    letterSpacing: "0.3px",
    lineHeight: "1.5"
  },
  servicePaperStyle: {
    margin: "10px",
    marginBottom: "50px",
    borderRadius: "5px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
  }
};
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      investmentPlanTitle: "",
      dialogState: "",
      services: [
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_retirementplan.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `When one retires and there is a likelihood of the non-earning period 
          extending for another two 
          decades or more, then investing a portion of the retirement funds in equity product gains importance.
           Remember, retirement income (through interest, dividends, etc.) will be subject to inflation even during 
           the retired years. equity investment with GTurns deliver
                higher inflation-adjusted returns than other assets.
                So build the right foundation for your future with GTurns and
                live a happy retired life!`,
          serviceTextTitle: "RETIREMENT PLAN",
          serviceButton: (
            <div>
              <Button
                id="retirementPlan"
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_moneygrowth.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `The process of investing allows you to grow, rather than maintain, your savings for greater financial rewards in the future. With many investment options available, it’s important to choose the investments that offer significant returns. Equity Investment with GTurns offer higher returns compared to Inflation adjusted returns, Bank deposits and other Schemes and hence helps to grow your corpus with high returns.`,
          serviceTextTitle: "HIGH RETURNS PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "growyourmoneyplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_educationplan.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `At the present rate of inflation, the increasing cost of education worries every parent. Today, a typical MBA course from top business school can cost anything between 5 to 10 lakh. Taking into account the present inflation rate, the education cost will only rise in the future. So, 10 years from now if your child wishes to pursue MBA from top business school, it will not cost anything less than 25 lakh. GTurns educational plan helps you beat inflation with better returns, so that your child can have a better education without any stress.`,
          serviceTextTitle: "EDUCATION PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "educationplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_travelplan.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `Bust your financial stress with equity investment in GTurns. Planning the finances for your holiday can be a headache but it doesn’t have to be if you plan in advance! Whether it’s an international trip or a local getaway, equity investment with GTurns is the best way to plan your holiday. GTurns is a great place to start with equity investment that allows you to save a certain amount of money and can also earn the benefits of power of compounding. It is best to stay invested for a longer period of time to create wealth. Start with equity investment today to plan for your next big holiday. Happy Vacation.`,
          serviceTextTitle: "TRAVEL PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "travelplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_home.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `Owning a home is a major milestone that tops many people’s lifetime to-do lists. Buying a home is a huge investment. It is one of those decisions that signal the beginning of a new stage in our lives. Before you jump into the wonderful world of home ownership, it is important to be financially ready for it and their list of financial fears too! But it does not have to be a stressful experience; once you plan it, in time, with GTurns.`,
          serviceTextTitle: "HOME PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "homeplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_dreamcar.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `Equity Investments: A debt-free way to your dream car. You often hear people say that it is the journey, not the destination that matters. The journey to your destination is always sweeter if you are travelling in your dream car. A dream car, however, wouldn’t remain a dream, if you plan your finances earlier. GTurns Equity Plan help you to buy a dream car without any debt. Stay invested with us and have a smooth journey.`,
          serviceTextTitle: "DREAM CAR PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "carplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_marriageplan.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `Bust your financial stress with equity investment in GTurns. Wedding can be a stressful event but managing your finances for it doesn’t have to be! Whether it’s a destination wedding or a local one, equity investments are your financial stress busters. GTurns is a great place to start is with an equity investments that allows you to save a certain amount of money and can also earn the benefits of power of compounding. It is best to stay invested for a longer period of time to create wealth. Start your equity investment with GTurns today to plan for a stress free wedding`,
          serviceTextTitle: "MARRIAGE PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "marriageplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_investmentplan.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `The process of investing allows you to grow, rather than maintain, your savings for greater financial rewards in the future. With many investment options available, it’s important to choose the investments that offer significant returns. Equity Investment with GTurns offer higher returns compared to Inflation adjusted returns, Bank deposits and other Schemes and hence helps to grow your corpus.`,
          serviceTextTitle: "INVESTMENT PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "investmentplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        },
        {
          servicesImage: (
            <img
              className="service-images-containers"
              src={require("../../../assets/image/homepage_income.svg")}
              alt="pexels-908288."
              style={Styles.serviceImageStyle}
            />
          ),
          serviceTextDescription: `Retiring early sounds like a dream come true for most people, but early retirement may not be for everyone. It usually means that you have to take some extreme measures in order to reach it. You may have to work long hours, cut your expenses a good amount, or even both. GTurns Income Plan helps you to plan for an early retirement without putting yourself through such extreme measures. GTurns Income Plan helps you generate additional income and let's you retire early.`,
          serviceTextTitle: "INCOME PLAN",
          serviceButton: (
            <div>
              <Button
                variant="outlined"
                style={Styles.buttonStyle}
                onClick={() => {
                  this.setState({ dialogState: "incomeplan" });
                  this.handleClickOpen();
                }}
              >
                Plan Now
              </Button>
            </div>
          )
        }
      ]
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen = dialogState => {
    this.setState({ open: true });
    console.log("service props", this.props);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="services-container">
        <div className="services-heading">Plan Your Goals</div>
        {/* ****** Not Required******************
        <div className="services-sub-heading">
          Goal without a plan is a wish. <br />
          Let us plan in making your goal a reality!
          <br /> Choose your goal:
        </div>  */}
        <div className="services-body-cotainer">
          <Grid container style={Styles.gridStyles}>
            {this.state.services.map((service, i) => (
              <Grid key={i}>
                <div className="services-card-image-container">
                  {service.servicesImage}
                </div>
                <Paper
                  className="services-cards-Paper"
                  style={Styles.servicePaperStyle}
                >
                  <div className="services-card-container">
                    <div className="services-card-image-text-container">
                      <div className="services-card-text-container">
                        <div className="services-card-text-heading-container">
                          {service.serviceTextTitle}
                        </div>
                        <div className="services-card-text-description-container">
                          <div>
                            <div style={Styles.descriptionDiv1Style}>
                              {service.serviceTextDescription.length > 250
                                ? service.serviceTextDescription.substring(
                                    0,
                                    250
                                  ) + "..."
                                : service.serviceTextDescription}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="services-card-button-container">
                      {service.serviceButton}
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={window.innerWidth > 800 ? "md" : "sm"}
          style={{ top: 50 }}
          className="dialogBox"
        >
          {this.state.dialogState === "carplan" ? (
            <DialogDreamCar handleClose={this.handleClose} {...this.props} />
          ) : this.state.dialogState === "homeplan" ? (
            <DialogDreamHome handleClose={this.handleClose} {...this.props} />
          ) : this.state.dialogState === "incomeplan" ? (
            <DialogIncomePlan handleClose={this.handleClose} {...this.props} />
          ) : this.state.dialogState === "investmentplan" ? (
            <DialogInvestmentPlan
              handleClose={this.handleClose}
              {...this.props}
            />
          ) : this.state.dialogState === "marriageplan" ? (
            <DialogMarriagePlan
              handleClose={this.handleClose}
              {...this.props}
            />
          ) : this.state.dialogState === "educationplan" ? (
            <DialogEducationPlan
              handleClose={this.handleClose}
              {...this.props}
            />
          ) : this.state.dialogState === "growyourmoneyplan" ? (
            <DialogGrowYourMoneyPlan
              handleClose={this.handleClose}
              {...this.props}
            />
          ) : this.state.dialogState === "travelplan" ? (
            <DialogTravelPlan handleClose={this.handleClose} {...this.props} />
          ) : (
            <DialogRetirementPlan
              handleClose={this.handleClose}
              {...this.props}
            />
          )}
        </Dialog>
      </div>
    );
  }
}

export default Services;
