import React, { Component } from "react";
import "../../../assets/css/howItWorksheaderStyles.css";
import Dialog from "@material-ui/core/Dialog";

const Styles = {
  dialogIconDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "black",
    cursor: "pointer",
    zIndex: 1400
  },
  dialogCloseIconStyle: {
    fontSize: "25px",
    color: "whitesmoke",
    marginTop: "10px",
    position: "absolute"
  },
  dialogIframeStyle: {
    display: "flex",
    flex: 1,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover"
  }
};

class howItWorksHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <div className="TransparentBlackLayer">
        <div className="howItWorks-heading-container">
          <div className="howItWorks-heading-text-image-container">
            <div className="howItWorks-heading-text-container">
              <div className="howItWorks-header-heading-container">
                How It Works
              </div>
              <div className="howItWorks-header-description-container">
                We at GTurns Data Consultants Private Limited are here to solve
                this problem and free you from the constraint and hassle of
                giving enough time, and providing you the right information for
                each trade, not only just by theory, but also by relentless
                trading practice guided by real-time, data-driven algorithms and
                decades of market expertise.
              </div>
            </div>

            {/* <div className="howItWorksVideo">
              <iframe
                title="How It Works Video"
                className="howItWorksVideoIframe"
                src="https://www.youtube.com/embed/Wd5AjJP7Oeo"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              </div> */}
            <div className="howItWorksVideo">
              <img
                src={require("../../../assets/image/thumbline1.png")}
                alt="How It Works Thumbline"
                style={{
                  width: "100%",
                  height: "100%",
                  marginLeft: "5%",
                  cursor: "pointer"
                }}
                onClick={this.handleClickOpen}
              />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  top: "-50%",
                  left: "-50%",
                  cursor: "pointer"
                }}
              >
                <i
                  className="material-icons"
                  style={{
                    fontSize: "45px",
                    width: "50px",
                    borderRadius: "50px",
                    color: "white",
                    backgroundColor: "#FFD118",
                    border: "2px solid #FFD118"
                  }}
                  onClick={this.handleClickOpen}
                >
                  play_arrow
                </i>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={window.innerWidth > 800 ? "md" : "sm"}
        >
          <div style={Styles.dialogIconDiv}>
            <i
              className="material-icons"
              style={Styles.dialogCloseIconStyle}
              onClick={this.handleClose}
            >
              clear
            </i>
          </div>
          <div style={Styles.dialogIframeStyle}>
            <iframe
              title="How It Works Video"
              width="100%"
              height="520px"
              src="https://www.youtube.com/embed/-WjRowZpAVs"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Dialog>
      </div>
    );
  }
}
export default howItWorksHeader;
