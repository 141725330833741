import React, { Component } from "react";
import "../../../assets/css/journeyStyles.css";

class Journey extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="journey-Container">
        <div className="TransparentBlackLayer">
          <div className="journey-heading-container">Journey</div>
          <div className="image-container">
            <img
              src={require("../../../assets/image/aboutUs_Journey_Updated_2020.png")}
              alt="aboutUs"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Journey;
