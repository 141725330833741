import React, { Component } from "react";
import "../../../assets/css/dashboardStyles.css";
import Banner1 from "./Banner1";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.showSlides = this.showSlides.bind(this);
    // this.plusSlides = this.plusSlides.bind(this);
  }
  async componentDidMount() {
    // var showSlides = this.showSlides;
    // this.showSlides(slideIndex);
    // let counter = setInterval(timer, 9000);
    // function timer() {
    //   showSlides((slideIndex += 1));
    //   console.log("counter");
    // }
  }
  componentWillUnmount() {
    // clearInterval();
  }
  // currentSlide(n) {
  //   this.showSlides((slideIndex = n));
  // }
  // plusSlides(n) {
  //   this.showSlides((slideIndex += n));
  // }
  // async showSlides(n) {
  //   var i;
  //   var slides = document.getElementsByClassName("mySlides");
  //   var dots = document.getElementsByClassName("dot");
  //   if (n > slides.length) {
  //     slideIndex = 1;
  //   }
  //   if (n < 1) {
  //     slideIndex = slides.length;
  //   }
  //   for (i = 0; i < slides.length; i++) {
  //     slides[i].style.display = "none";
  //   }
  //   for (i = 0; i < dots.length; i++) {
  //     dots[i].className = dots[i].className.replace(" active", "");
  //   }
  //   slides[slideIndex - 1].style.display = "block";
  //   dots[slideIndex - 1].className += " active";
  // }
  render() {
    return (
      <div className="Homepage-container" style={{ paddingTop: 0 }}>
        <div
          className="Homepage-banner1"
          style={
            {
              // position: "relative",
              // height: window.outerHeight - 130,
              // width: "100%",
              // minHeight: 499,
            }
          }
        >
          <Banner1 />
        </div>
        {/* <div
          style={{
            height: 850,
            width: "100%",
            flexDirection: "column",
            display: "flex",
            backgroundImage:
              "url(" + require("../../../assets/image/bg1home.jpg") + ")",
            backgroundSize: "cover"
          }}
        >
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              display: "flex"
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: 35
              }}
            >
              <div
                style={{
                  border: "0px solid red",
                  textAlign: "left",
                  padding: 20,
                  fontSize: 65,
                  fontWeight: 700
                }}
              >
                Data driven model
              </div>
              <div
                style={{
                  border: "0px solid red",
                  textAlign: "left",
                  padding: 20,
                  paddingTop: 10,
                  fontSize: 18,
                  letterSpacing: 1,
                  fontWeight: 300,
                  lineHeight: 1.7
                }}
              >
                High probability investments based on data driven and proven
                scientific model
                <br />
                Effective money management in all market conditions
              </div>
            </div>

            <div
              style={{
                height: 100,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end"
              }}
            >
              <div className="bottom-tab-container">
                <div className="svg-text-container">
                  <div>
                    <svg
                      style={{ width: "85px", height: "60px", opacity: 0.5 }}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffd118cc"
                        d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
                      />
                    </svg>
                  </div>
                  <div className="rupee-icon-text">
                    Effective money management in all market
                    <br /> conditions.
                  </div>
                </div>

                <div
                  style={{
                    borderLeft: "1px solid rgb(111, 110, 110)",
                    height: "60px",
                    margin: "auto"
                  }}
                />

                <div className="svg-text-container">
                  <div>
                    <svg
                      style={{
                        width: "85px",
                        height: "60px",
                        opacity: 0.5
                      }}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffd118cc"
                        d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
                      />
                    </svg>
                  </div>

                  <div className="rupee-icon-text">
                    Effective money management in all market
                    <br /> conditions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="slideshow-container">
          <div className="mySlides fade">
            <div
              className="Homepage-heading"
              style={{
                height: 520,
                width: "100%",
                flexDirection: "column",
                display: "flex",
                backgroundImage:
                  "url(" +
                  require("../../../assets/image/data-driven-2.jpg") +
                  ")",
                backgroundSize: "cover"
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  flexDirection: "column",
                  display: "flex",
                  background: "rgba(76, 76, 75, 0.89)"
                }}
              >
                <div className="Homepage-heading-text-title">
                  Data driven model
                </div>
                <div className="Homepage-heading-text-description">
                  High probability investments based on data driven and proven
                  scientific model
                  <br />
                  Effective money management in all market conditions
                </div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <div className="bottom-tab-container">
                    <div className="svg-text-container">
                      <div>
                        <svg
                          style={{ width: "85px", height: "60px" }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffd118cc"
                            d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
                          />
                        </svg>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          lineHeight: 1.5,
                          textAlign: "left",
                          fontWeight: 300,
                          letterSpacing: "0.3px",
                          opacity: 0.87
                        }}
                      >
                        Effective money management in all market
                        <br /> conditions.
                      </div>
                    </div>

                    <div
                      style={{
                        borderLeft: "1px solid rgb(111, 110, 110)",
                        height: "60px",
                        margin: "auto"
                      }}
                    />

                    <div className="svg-text-container">
                      <div>
                        <svg
                          style={{
                            width: "85px",
                            height: "60px"
                          }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffd118cc"
                            d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
                          />
                        </svg>
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          lineHeight: 1.5,
                          textAlign: "left",
                          fontWeight: 300,
                          letterSpacing: "0.3px",
                          opacity: 0.87
                        }}
                      >
                        Effective money management in all market
                        <br /> conditions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mySlides fade">
          <div
              className="Homepage-heading"
              style={{
                height: 520,
                width: "100%",
                flexDirection: "column",
                display: "flex",
                backgroundImage:
                  "url(" +
                  require("../../../assets/image/data-driven-2.jpg") +
                  ")",
                backgroundSize: "cover"
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  flexDirection: "column",
                  display: "flex",
                  background: "rgba(76, 76, 75, 0.89)"
                }}
              >
                <div className="Homepage-heading-text-title">
                  Data driven model
                </div>
                <div className="Homepage-heading-text-description">
                  High probability investments based on data driven and proven
                  scientific model
                  <br />
                  Effective money management in all market conditions
                </div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <div className="bottom-tab-container">
                    <div className="svg-text-container">
                      <div>
                        <svg
                          style={{ width: "85px", height: "60px" }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffd118cc"
                            d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
                          />
                        </svg>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          lineHeight: 1.5,
                          textAlign: "left",
                          fontWeight: 300,
                          letterSpacing: "0.3px",
                          opacity: 0.87
                        }}
                      >
                        Effective money management in all market
                        <br /> conditions.
                      </div>
                    </div>

                    <div
                      style={{
                        borderLeft: "1px solid rgb(111, 110, 110)",
                        height: "60px",
                        margin: "auto"
                      }}
                    />

                    <div className="svg-text-container">
                      <div>
                        <svg
                          style={{
                            width: "85px",
                            height: "60px"
                          }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffd118cc"
                            d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
                          />
                        </svg>
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          lineHeight: 1.5,
                          textAlign: "left",
                          fontWeight: 300,
                          letterSpacing: "0.3px",
                          opacity: 0.87
                        }}
                      >
                        Effective money management in all market
                        <br /> conditions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mySlides fade">
            <div className="numbertext">3 / 3</div>
            <img
              src={require("../../../assets/image/IMG_0700.jpg")}
              alt="df"
              style={{ width: "100%", background: "red" }}
            />
            <div className="text">Caption Three</div>
          </div>

          <a className="prev" onClick={() => this.plusSlides(-1)}>
            &#10094;
          </a>
          <a className="next" onClick={() => this.plusSlides(1)}>
            &#10095;
          </a>
        </div>
        <br />

        <div style={{ textAlign: "center" }}>
          <span className="dot" onClick={() => this.currentSlide(1)} />
          <span className="dot" onClick={() => this.currentSlide(2)} />
          <span className="dot" onClick={() => this.currentSlide(3)} />
        </div> */}
      </div>
    );
  }
}

export default Header;
