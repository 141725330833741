import React, { Component } from "react";
import "../../../assets/css/paginationStyles.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const styles = {
  buttonActive: {
    backgroundColor: "black",
    color: "rgb(208, 201, 201)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "38px",
    height: "31px",
    margin: 3,
    padding: 0,
    boxShadow: "#cec6c6 0px 1px 2px 2px"
  },
  buttonInActive: {
    backgroundColor: "gray",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "38px",
    height: "29px",
    margin: 3,
    padding: 0
    // boxShadow: "#cec6c6 0px 1px 0px 2px"
  }
};

class Paginations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="pagination-main-container">
        <Grid container wrap="nowrap" spacing={10}>
          <Grid item xs={12}>
            <div className="pagination-buttons-container">
              <Button
                variant="contained"
                disabled={this.props.currentPage === 1}
                style={
                  this.props.currentPage === 1
                    ? styles.buttonInActive
                    : styles.buttonActive
                }
                onClick={this.props.goToPrevious}
              >
                <i className="fa fa-angle-double-left" aria-hidden="true" />
              </Button>
              <Button variant="contained" style={styles.buttonActive}>
                {this.props.currentPage}
              </Button>
              {/* <Button variant="contained" style={styles.buttonInActive}>
                2
              </Button>
              <Button variant="contained" style={styles.buttonInActive}>
                3
              </Button>
              <Button variant="contained" style={styles.buttonInActive}>
                4
              </Button>
              <Button variant="contained" style={styles.buttonInActive}>
                5
              </Button>
              <Button variant="contained" style={styles.buttonInActive}>
                <i className="fa fa-ellipsis-h" aria-hidden="true" />
              </Button> */}
              <Button
                variant="contained"
                disabled={this.props.currentPage === 2}
                style={
                  this.props.currentPage === 2
                    ? styles.buttonInActive
                    : styles.buttonActive
                }
                onClick={this.props.goToNext}
              >
                <i className="fa fa-angle-double-right" aria-hidden="true" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Paginations;
