import React, { Component } from "react";
import "../../../assets/css/smallcase.css";

class Smallcase extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="small-case-main">
        <div className="smallcase-heading-text-container">
          Our Products and Services
        </div>
        <div className="smallcase-text-image-container">
          <div className="smallcase-text-container">
            <div className="text-container1">
              Momentum builds success. GTurns presents
              <span style={{ fontWeight: 700 }}> GTurns Momentum</span>, our
              tailor made stock portfolio, powered by smallcase
            </div>
          </div>
        </div>
        <div className="background-text">smallcase</div>
        <div className="smallcase-cards">
          <a
            target="_blank"
            href="https://gturns.smallcase.com/"
            className="smallcase-card-link"
            rel="noopener noreferrer"
          >
            <div className="smallcase-card-1">
              <div className="smallcase-card-header">GTurns Momentum</div>
              <div className="smallcase-card-description">
                GTurns Momentum strategy is best suited for active investors who
                seek to generate very good return
              </div>
            </div>
          </a>
        </div>
        <div className="smallcase-bottom-link">
          <a
            target="_blank"
            href="https://gturns.smallcase.com/"
            className="smallcase-card-link"
            rel="noopener noreferrer"
          >
            <div className="arrow-icon-bottom-link">
              <i className="material-icons">keyboard_arrow_right</i>
            </div>{" "}
            <span className="arrow-text-bottom-link">
              Invest now with smallcase
            </span>
          </a>
        </div>
        <div className="gcore-product-Info">
          <img
            src={require("../../../assets/image/gcore.png")}
            alt="Gcore-logo"
          />
          <div className="gcore-description">
            At GTurns, we value our clients and understand that they need to see
            how their portfolio is growing, hence, GCore. Customers, can see the
            stocks purchased, current portfolio value and can also generate
            reports for any period of time since account inception on the fly
          </div>
        </div>
      </div>
    );
  }
}

export default Smallcase;
