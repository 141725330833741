import React, { Component } from "react";
import "../../assets/css/headerStyle.css";
import Dialog from "@material-ui/core/Dialog";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import GetStartedDialogContent from "./GetStartedDialog";
import ROIDialougeContent from "./ROIDialogue";
import Button from "@material-ui/core/Button";
import CalculatorIcon4 from "../../assets/image/Calculator_svg/calculator_4.svg";
import CalculatorIcon4Golden from "../../assets/image/Calculator_svg/calculator_4Golden.svg";
import Tooltip from "@material-ui/core/Tooltip";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      hostName: "",
      currentPage: "",
      open: false,
      displayROIDialogue: false,
      isHover: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleGetStartedDialogOpen = this.handleGetStartedDialogOpen.bind(
      this
    );
    this.handleGetStaretedDialogClose = this.handleGetStaretedDialogClose.bind(
      this
    );
    this.handleROIDialogueOpen = this.handleROIDialogueOpen.bind(this);
    this.handleROIDialogueClose = this.handleROIDialogueClose.bind(this);
  }
  handleGetStartedDialogOpen() {
    this.setState({ open: true });
  }
  handleGetStaretedDialogClose() {
    this.setState({ open: false });
  }

  handleROIDialogueOpen() {
    this.setState({ displayROIDialogue: true });
  }

  handleROIDialogueClose() {
    this.setState({ displayROIDialogue: false });
  }

  async componentDidMount() {
    var hostName = window.location.host;
    var currentPage = window.location.href;
    var protocol = window.location.protocol;
    this.setState({
      hostName: protocol + "//" + hostName,
      currentPage: currentPage,
    });
    // console.log(
    //   "props",
    //   this.props,
    //   hostName,
    //   currentPage,
    //   window.location.protocol
    // );
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  }
  async componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  async handleScroll(event) {
    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;
    if (window.pageYOffset > sticky && window.pageYOffset > 5) {
      header.classList.add("Sticky");
    } else {
      header.classList.remove("Sticky");
    }
  }
  async toggleDrawer(event) {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }
  render() {
    const fullList = (
      <div className="header-anchor-container">
        <a
          id={
            this.state.currentPage === this.state.hostName + "/"
              ? "active"
              : "notActive"
          }
          href="/"
          className="header-anchor"
        >
          HOME
        </a>
        <a
          id={
            this.state.currentPage === this.state.hostName + "/about"
              ? "active"
              : "notActive"
          }
          href="/about"
          className="header-anchor"
        >
          ABOUT
        </a>
        <a
          id={
            this.state.currentPage === this.state.hostName + "/howitworks"
              ? "active"
              : "notActive"
          }
          href="/howitworks"
          className="header-anchor"
        >
          HOW IT WORKS
        </a>
        <a
          id={
            this.state.currentPage === this.state.hostName + "/blog" ||
            this.state.currentPage === this.state.hostName + "/id/:id"
              ? "active"
              : "notActive"
          }
          // href="/blog"
          href="/blog"
          className="header-anchor"
        >
          BLOG
        </a>
        <a
          id={
            this.state.currentPage === this.state.hostName + "/faq"
              ? "active"
              : "notActive"
          }
          href="faq"
          className="header-anchor"
        >
          FAQ
        </a>

        <div
          className="header-anchor-container"
          onMouseOver={() => this.setState({ isHover: true })}
          onMouseOut={() => this.setState({ isHover: false })}
          style={{ marginTop: "10px" }}
        >
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "1.3em",
                  color: "#FFFFFF",
                }}
              >
                Equity Calculator
              </span>
            }
            placement="Bottom"
          >
            <img
              src={this.state.isHover ? CalculatorIcon4Golden : CalculatorIcon4}
              alt="CalculatorIcon4"
              style={{
                display: "flex",
                height: "auto",
                width: "30px",
                marginLeft: "15px",
                cursor: "pointer",
              }}
              onClick={this.handleROIDialogueOpen}
            ></img>
          </Tooltip>
        </div>

        <Button
          style={{
            height: "40px",
            width: 180,
            backgroundColor: "#ffd118",
            border: "none",
            color: "#070707e3",
            borderRadius: "3px",
            marginLeft: "15px",
            marginTop: "10px",
            fontSize: "16px",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            whiteSpace: "nowrap",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={this.handleGetStartedDialogOpen}
        >
          <img
            src={require("../../assets/image/Button_Icon.svg")}
            alt="getStarted-icon"
            style={{ marginLeft: "5%" }}
          />
          <div style={{ marginLeft: "5%", marginRight: "5%" }}>GET STARTED</div>
        </Button>
        {/* <Button
          style={{
            height: '40px',
            width: 180,
            backgroundColor: '#ffd118',
            border: 'none',
            color: '#070707',
            borderRadius: '3px',
            marginLeft: '15px',
            marginTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',

            fontSize: '16px',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            outline: 'none'
          }}
          onClick={this.handleROIDialogueOpen}
        >
          Equity Calculator
        </Button> */}
      </div>
    );
    return (
      <div className="Header-Container">
        <header className="Header" id="myHeader">
          <div className="header-logo-container">
            <div className="header-logo-hover-changer">
              <img
                className="header-logo"
                src={require("../../assets/image/gTurns_gradient@4x.png")}
                alt=""
                onClick={() => {
                  window.location.pathname = "/";
                }}
              />
              <span className="header-company-name">GTurns</span>
            </div>
          </div>
          <div className="header-anchor-container">
            <div className="icon-holder">
              <i
                className="material-icons"
                onClick={() => this.toggleDrawer()}
                style={{ cursor: "pointer" }}
              >
                menu
              </i>
            </div>
            <div className="anchors-holder">{fullList}</div>
          </div>
        </header>
        <div>
          <SwipeableDrawer
            anchor="top"
            open={this.state.drawerOpen}
            onClose={() => this.toggleDrawer()}
            onOpen={() => this.toggleDrawer()}
          >
            {/* <div
              style={{
                height: window.pageYOffset > 5 ? 80 : 0,
                backgroundColor: "transparent"
              }}
            /> */}
            <div
              tabIndex={0}
              role="button"
              onClick={() => this.toggleDrawer()}
              onKeyDown={() => this.toggleDrawer()}
              style={{
                flexDirection: "row",
                display: "flex",
                float: "none",
                backgroundColor: "#1a1a1a",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {fullList}
            </div>
          </SwipeableDrawer>
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleGetStaretedDialogClose}
          maxWidth={window.innerWidth > 600 ? "md" : "sm"}
        >
          <div className="getStarted-Dialog-Component">
            <GetStartedDialogContent
              handleGetStaretedDialogClose={this.handleGetStaretedDialogClose}
            />
          </div>
        </Dialog>
        <Dialog
          open={this.state.displayROIDialogue}
          onClose={this.handleROIDialogueClose}
          maxWidth={window.innerWidth > 600 ? "lg" : "sm"}
          style={{ padding: "0px" }}
        >
          <div style={{ minWidth: "100%" }}>
            <ROIDialougeContent
              handleROIDialogueClose={this.handleROIDialogueClose}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Header;
