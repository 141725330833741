import React, { Component } from "react";
import "../../../assets/css/certificationStyles.css";
import Dialog from "@material-ui/core/Dialog";

const Styles = {
  imageStyles: {
    width: "80%",
    margin: "20px"
  }
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificate: "",
      open: false,
      images: [
        // require("../../../assets/image/Uttam_IV1-1.jpg"),
        // require("../../../assets/image/Uttam-SEBI-certificate.jpeg"),
        require("../../../assets/image/LAKSHMI_MF_CERT-1.jpg"),
        require("../../../assets/image/shravan-1.jpg"),
        // require("../../../assets/image/Uttam.2.png"),
        require("../../../assets/image/Laskhami.2.jpg"),
        // require("../../../assets/image/uttam_1-1.jpg"),
        require("../../../assets/image/suhel_cert2.jpg")
      ],
      currentIndex: 0,
      translateValue: 0,
      imageId: 0,
      isClicked: false
    };
    this.handleCarouselImageOpen = this.handleCarouselImageOpen.bind(this);
    this.handleCarouselImageClose = this.handleCarouselImageClose.bind(this);
  }
  handleCarouselImageOpen(imgId) {
    this.setState({ open: true, imageId: imgId });
    clearInterval(this.certificateSliderTimer);
    console.log("clicked");
  }
  handleCarouselImageClose() {
    this.setState({ open: false });
  }
  componentDidMount() {
    this.certificateSliderTimer = setInterval(this.goToNextSlide, 2000);
  }
  componentWillUnmount() {
    clearInterval(this.certificateSliderTimer);
  }
  goToPrevSlide = () => {
    if (this.state.currentIndex === 0) return;

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + this.slideWidth()
    }));
    clearInterval(this.certificateSliderTimer);
  };
  goToNextSlide = () => {
    if (this.state.currentIndex === this.state.images.length - 3) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      });
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -this.slideWidth()
    }));
    clearInterval(() => this.certificateSliderTimer);
  };
  slideWidth = () => {
    return document.querySelector(".certificateslide").clientWidth;
  };
  render() {
    return (
      <div className="certificaton-container">
        <div className="certificate-background-image">
          <div className="certificate-black-background">
            <div className="certificate-carousel-heading">We Are Certified</div>
            <div className="certificate-image-carousel">
              <div className="certificate-slider">
                <div
                  className="certificate-slider-wrapper"
                  style={{
                    transform: `translateX(${this.state.translateValue}px)`,
                    transition: "transform ease-out 0.45s"
                  }}
                >
                  {this.state.images.map((image, i) => (
                    <Slide
                      key={i}
                      image={image}
                      open={() => this.handleCarouselImageOpen(i)}
                      close={this.handleCarouselImageClose}
                    />
                  ))}
                </div>
                <LeftArrow goToPrevSlide={this.goToPrevSlide} />

                <RightArrow goToNextSlide={this.goToNextSlide} />
              </div>
            </div>
            <div className="certificate-image-holder">
              <div className="certificate-uttam-image">
                {this.state.images.map((images, id) => {
                  return (
                    <img
                      src={images}
                      alt="certificate"
                      key={id}
                      style={Styles.imageStyles}
                      onClick={() => this.handleCarouselImageOpen(id)}
                      onClose={this.handleCarouselImageClose}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          id="certificateDialogId"
          open={this.state.open}
          onClose={this.handleCarouselImageClose}
          maxWidth={window.innerWidth > 600 ? "md" : "sm"}
        >
          <div className="carousel-image-Dialog-Component">
            <img
              src={this.state.images[this.state.imageId]}
              alt="certificate modal"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}
const Slide = ({ image, open, close }) => {
  const styles = {
    backgroundImage: `url(${image})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  };
  return (
    <div
      className="certificateslide"
      style={styles}
      onClick={open}
      onClose={close}
    />
  );
};
const LeftArrow = props => {
  return (
    <div className="backArrow arrow" onClick={props.goToPrevSlide}>
      <i className="fa fa-chevron-left" aria-hidden="true" />
    </div>
  );
};

const RightArrow = props => {
  return (
    <div className="nextArrow arrow" onClick={props.goToNextSlide}>
      <i className="fa fa-chevron-right" aria-hidden="true" />
    </div>
  );
};
export default Header;
