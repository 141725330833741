import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import "../../../assets/css/contactInfoStyles.css";
import Button from "@material-ui/core/Button";
import apic from "../../../Core/Apic/apiClint";
const styles = {
  sendButton: {
    backgroundColor: "#FFD118",
    float: "left",
    height: 40,
    width: 142,
    borderRadius: 2,
    border: "none",
    outline: "none",
    fontSize: "16px",
    marginTop: 35,
    letterSpacing: "0.4px",
    cursor: "pointer",
  },
  subscribeButton: {
    backgroundColor: "#FFD118",
    height: 38,
    width: 142,
    borderRadius: 2,
    border: "none",
    outline: "none",
    fontSize: "16px",
    letterSpacing: "0.4px",
    cursor: "pointer",
  },
  messageInput: {
    width: window.innerWidth > 599 ? 390 : 275,
    height: 95,
    marginTop: 10,
    backgroundColor: "#363636",
    border: "none",
    outline: "none",
    color: "#f1f1f1",
    resize: "none",
    paddingLeft: 17,
    paddingTop: 10,
    borderRadius: "2px",
  },
  contactInput: {
    width: window.innerWidth > 599 ? 186 : 125,
    height: 38,
    marginLeft: 15,
    backgroundColor: "#363636",
    border: "none",
    outline: "none",
    position: "relative",
    right: 13,
    color: "#f1f1f1",
    paddingLeft: 15,
    borderRadius: "2px",
    fontSize: "14px",
  },
};
class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      senderName: "",
      senderEmail: "",
      subscriptionEmail: "",
      senderMessage: "",
      senderNameErr: "",
      senderEmailErr: "",
      subscriptionEmailErr: "",
      senderMessageErr: "",
      mailUrl: "",
      phone: "0000000000",
    };
    this.handleSenderName = this.handleSenderName.bind(this);
    this.handleSenderEmail = this.handleSenderEmail.bind(this);
    this.handleSenderMessage = this.handleSenderMessage.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleSubscription = this.handleSubscription.bind(this);
    this.handleSubscriptionEmail = this.handleSubscriptionEmail.bind(this);
    this.validateSubscriptionEmail = this.validateSubscriptionEmail.bind(this);
  }
  handleSenderEmail(event) {
    this.setState({ senderEmail: event.target.value, senderEmailErr: "" });
  }
  handleSubscriptionEmail(event) {
    this.setState({
      subscriptionEmail: event.target.value,
      subscriptionEmailErr: "",
    });
  }
  handleSenderName(event) {
    this.setState({ senderName: event.target.value, senderNameErr: "" });
  }
  handleSenderMessage(event) {
    this.setState({ senderMessage: event.target.value, senderMessageErr: "" });
  }
  validateSenderEmail(senderEmail) {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = pattern.test(senderEmail);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ senderEmail: "" });
      return { valid: false };
    }
  }
  validateSubscriptionEmail(subscriptionEmail) {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = pattern.test(subscriptionEmail);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ subscriptionEmail: "" });
      return { valid: false };
    }
  }

  validateSenderName(senderName) {
    const pattern = /^[a-zA-Z0-9 ]{3,}$/;
    const result = pattern.test(senderName);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ senderName: "" });
      return { valid: false };
    }
  }
  validateSenderMessage(senderMessage) {
    const pattern = /^[a-zA-Z0-9 ]{3,}$/;
    const result = pattern.test(senderMessage);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ senderMessage: "" });
      return { valid: false };
    }
  }
  async handleSubscription() {
    let subscriptionEmailValidation = this.validateSubscriptionEmail(
      this.state.subscriptionEmail
    );
    if (subscriptionEmailValidation.valid === false) {
      this.setState({
        subscriptionEmailErr: "Please Enter Valid Email ID",
      });
      return;
    }
    this.setState({
      mailUrl:
        "https://script.google.com/macros/s/AKfycbyB-b2tMtwh3wbjqjg7dHwY_XL3OApaQ79aP-isCon7zAIJVQ0/exec?name=" +
        "" +
        "&email=" +
        this.state.subscriptionEmail +
        "&phone=" +
        "--" +
        "&message=" +
        "Subscription message",
    });
    await apic
      .postSubscription({
        email: this.state.subscriptionEmail,
      })
      .then((response) => {
        let responseData = JSON.parse(response);

        if (responseData.success === true) {
          alert("Your message has been recieved. Thank you for showing your interest in us");
          this.setState({
            subscriptionEmail: "",
          });
        } else {
          alert(responseData.message);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }

  async handleSendMessage() {
    let user = {};
    let okToLogin = true;
    let senderNameValidation = this.validateSenderName(this.state.senderName);
    let senderEmailValidation = this.validateSenderEmail(
      this.state.senderEmail
    );
    let senderMessageValidation = this.validateSenderMessage(
      this.state.senderMessage
    );
    if (senderNameValidation.valid) user.senderName = this.state.senderName;
    else okToLogin = false;
    if (senderEmailValidation.valid) user.senderEmail = this.state.senderEmail;
    else okToLogin = false;
    if (senderMessageValidation.valid)
      user.senderMessage = this.state.senderMessage;
    else okToLogin = false;
    if (okToLogin) {
      this.setState({
        mailUrl:
          "https://script.google.com/macros/s/AKfycbyB-b2tMtwh3wbjqjg7dHwY_XL3OApaQ79aP-isCon7zAIJVQ0/exec?name=" +
          this.state.senderName +
          "&email=" +
          this.state.senderEmail +
          "&phone=" +
          this.state.phone +
          "&message=" +
          "Drop Us a line message:" +
          this.state.senderMessage,
      });

      await apic
        .postContactUs({
          name: this.state.senderName,
          email: this.state.senderEmail,
          message: this.state.senderMessage,
        })
        .then((response) => {
          let responseData = JSON.parse(response);

          if (responseData.success === true) {
            alert("Your message has been recieved. Thank you for showing your interest in us");
            this.setState({
              senderName: "",
              senderEmail: "",
              senderMessage: "",
            });
          } else {
            alert(responseData.message);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    } else {
      if (senderNameValidation.valid === false) {
        this.setState({
          senderNameErr: "Please enter valid name",
        });
      }
      if (senderEmailValidation.valid === false) {
        this.setState({
          senderEmailErr: "Please enter valid email id",
        });
      }
      if (senderMessageValidation.valid === false) {
        this.setState({
          senderMessageErr: "Please type your message",
        });
      }
    }
  }
  render() {
    return (
      <div className="contactInfo-container">
        <div className="contactInfo-subContainer">
          <div className="contactInfo-companySlogan-container">
            <div className="companySlogan">
              <div>
                <img
                  src={require("../../../assets/image/gTurns_gradient@4x.png")}
                  alt="Gturns logo"
                  className="contactInfo-gturns-logo"
                />
              </div>
              <div className="slogan-description">
                <p style={{ paddingTop: "2%" }}>
                  We are a company who knows how investing works. Not only by
                  theory, but also by relentless trading practise guided by
                  real-time, data-driven algorithms and decades of market
                  expertise.
                </p>
                <p style={{ paddingTop: "8%" }}>
                  CIN # U93090KA2018PTC112836 | ARN # 042019 | Logo Trademark #
                  3826450
                </p>
              </div>
              <div className="socialMediaHeading">FOLLOW US</div>
              <div className="socialMedia-container">
                <div className="icons-holder">
                  <a
                    href=" https://www.twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="twitter-icon">
                      <i className="fab fa-twitter-square" />
                    </div>
                  </a>
                  <a
                    href=" https://www.linkedin.com/in/gturns-data-consultants-9569a1165/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="linkedin-icon">
                      <i className="fab fa-linkedin" />
                    </div>
                  </a>
                  <a
                    href=" https://www.facebook.com/Gturns-Data-Consultants-Private-Limited-202771133875380/?ref=br_rs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="facebook-icon">
                      <i className="fab fa-facebook-square" />
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCmO3psg_h3ww7t5hSh0F_Dw/featured"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="twitter-icon">
                      <i className="fab fa-youtube" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="formContainers">
              <div className="emailSubscription">
                <div
                  className="input-container-one"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.state.subscriptionEmail && (
                      <iframe
                        id="drop-us-line-frame"
                        title="drop-us-line-frame"
                        style={{ display: "none" }}
                        src={this.state.subscriptionEmail}
                      />
                    )}
                    <input
                      id="SubscriptionEmailInput"
                      value={this.state.subscriptionEmail}
                      type="text"
                      placeholder="Email"
                      style={styles.contactInput}
                      onChange={this.handleSubscriptionEmail}
                      autoComplete="email"
                    />
                    <div
                      style={{
                        textAlign: "left",
                        height: "15px",
                        color: "#ef4d4d",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "2%",
                        marginTop: "2%",
                      }}
                    >
                      {this.state.subscriptionEmailErr}
                    </div>
                  </div>
                  <Button
                    style={styles.subscribeButton}
                    onClick={this.handleSubscription}
                  >
                    SUBSCRIBE
                  </Button>
                </div>
              </div>

              <div className="dropMessage">
                <div
                  className="contact-info-heading"
                  style={{ float: "left", marginTop: 0 }}
                >
                  DROP US A LINE
                </div>
                <div className="input-container-one">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      id="dropUsLineName"
                      value={this.state.senderName}
                      type="text"
                      placeholder="Name"
                      style={styles.contactInput}
                      onChange={this.handleSenderName}
                      autoComplete="name"
                    />
                    <div
                      style={{
                        textAlign: "left",
                        height: "15px",
                        color: "#ef4d4d",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "2%",
                        marginTop: "2%",
                      }}
                    >
                      {this.state.senderNameErr}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      id="dropUsLineEmail"
                      value={this.state.senderEmail}
                      type="text"
                      placeholder="Email"
                      autoComplete="email"
                      style={styles.contactInput}
                      onChange={this.handleSenderEmail}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        height: "15px",
                        color: "#ef4d4d",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "2%",
                        marginTop: "2%",
                      }}
                    >
                      {this.state.senderEmailErr}
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.state.mailUrl && (
                      <iframe
                        id="drop-us-line-frame"
                        title="drop-us-line-frame"
                        style={{ display: "none" }}
                        src={this.state.mailUrl}
                      />
                    )}
                    <textarea
                      id="dropUsLineMessage"
                      value={this.state.senderMessage}
                      type="text"
                      placeholder="Your Message"
                      style={styles.messageInput}
                      height="100%"
                      rows={6}
                      onChange={this.handleSenderMessage}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        height: "15px",
                        color: "#ef4d4d",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "2%",
                        marginTop: "2%",
                      }}
                    >
                      {this.state.senderMessageErr}
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    style={styles.sendButton}
                    onClick={this.handleSendMessage}
                  >
                    SEND
                  </Button>
                </div>
                <div className="contactInfo-anchor-container" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;