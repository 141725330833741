import React, { Component } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import Radio from "@material-ui/core/Radio";
import { Button, TextField, Select, MenuItem } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import "../../assets/css/ROICalculator.css";
import apic from "../../Core/Apic/apiClint";

class ROIDialogueContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "HDFCBANK",
      period: "past",
      scrips: "",
      loaded: true,
      investedAmount: 100000,
      sliderValue: "10",
      returnValue: 0,
      returnPtgValue: 0,
      radioGroupState: "past",
      resultUnavailable: false,
      totalReturn: 0,
      scripsStore: [
        {
          "Company Name": "HDFC Bank",
          "Scrip Name": "HDFCBANK",
        },
        { "Company Name": "Kotak Mahindra Bank", "Scrip Name": "KOTAKBANK" },
        { "Company Name": "Asian Paints", "Scrip Name": "ASIANPAINT" },
        { "Company Name": "Bata India", "Scrip Name": "BATAINDIA" },
        { "Company Name": "Jubiliant Food works", "Scrip Name": "JUBLFOOD" },
        { "Company Name": "Tata Consultancy Services", "Scrip Name": "TCS" },
        {
          "Company Name": "Hindustan Unilever Ltd",
          "Scrip Name": "HINDUNILVR",
        },
        { "Company Name": "Ultra Tech Cement", "Scrip Name": "ULTRACEMCO" },
        { "Company Name": "Reliance", "Scrip Name": "RELIANCE" },
        { "Company Name": "BajaFinance", "Scrip Name": "BAJFINANCE" },
      ],
    };

    this.periodRadio = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.calculateROI = this.calculateROI.bind(this);
    this.handleSliderValue = this.handleSliderValue.bind(this);
    this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);
  }

  calculateROI = async () => {
    if (this.state.company && this.state.period && this.state.investedAmount) {
      this.setState({ loaded: false });
      await apic
        .fetchROI({
          params: {
            sliderValue: this.state.sliderValue,
            investedAmount: this.state.investedAmount,
            company: this.state.company,
          },
        })
        .then((response) => {
          this.setState({ loaded: true, resultUnavailable: false });
          let ROIResult = JSON.parse(response);

          this.setState({
            totalReturn: ROIResult.totalReturn.toFixed(2),
            returnPtgValue: ROIResult.ROR.toFixed(2),
            returnValue: ROIResult.returnAmount.toFixed(2),
          });
        })
        .catch((error) => {
          this.setState({ resultUnavailable: true });
        });
    }
  };

  componentDidMount() {
    this.calculateROI();
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState(
      {
        ...this.state,
        [event.target.name]: value,
      },
      () => {
        this.calculateROI();
      }
    );
  };

  handleRadioGroupChange = (event) => {
    this.setState(
      {
        radioGroupState: event.target.value,
        period: event.target.value,
      },
      () => {
        this.calculateROI();
      }
    );
  };

  handleSliderValue = (event) => {
    // console.log(
    //   "event.target['data-value']",
    //   event.target.attributes.getNamedItem("data-value").value
    // );
    let sliderValue = event.target.attributes.getNamedItem("data-value").value;
    this.setState({
      sliderValue,
    });
  };
  render() {
    return (
      <div className="calculator_Whole_page">
        <div className="calculatorPage">
          <div className="calc-Header">
            <div className="calc-HeaderContainer">
              <div className="calc-Lable">CALCULATOR</div>
              <div className="calc-Close">
                <CancelIcon
                  id="calc-cancelIcon"
                  onClick={this.props.handleROIDialogueClose}
                />
              </div>
            </div>
          </div>

          <div className="calc-Body">
            <div className="calc-InputContainer">
              <div className="calc-Periods">
                <RadioGroup
                  aria-label="Periods"
                  name="Periods"
                  id="calc-RadioGroup"
                  value={this.state.radioGroupState}
                  ref={this.periodRadio}
                >
                  <div className="calc-Past">
                    <label>
                      <Radio
                        name="period"
                        style={{ color: "#ffe600" }}
                        onChange={this.handleRadioGroupChange}
                        value="past"
                      />
                      <span style={{ textTransform: "uppercase" }}>Past</span>
                    </label>
                  </div>
                  <div className="calc-Future">
                    <label>
                      <Radio
                        style={{ color: "#ffe600" }}
                        name="period"
                        onChange={this.handleRadioGroupChange}
                        value="future"
                      />
                      <span style={{ textTransform: "uppercase" }}>Future</span>
                    </label>
                  </div>
                </RadioGroup>
              </div>
              <div className="calc-ScripSelector">
                {/* <div className="calc-ScripsLable">Scrips/Company Name</div> */}
                <div className="calc-Selector"></div>
                <Select
                  variant="outlined"
                  name="company"
                  displayEmpty
                  id="calc-ScripSelectorText"
                  onChange={this.handleChange}
                  value={this.state.company}
                >
                  <MenuItem value="" disabled>
                    Select a company
                  </MenuItem>
                  {this.state.scripsStore.map((eachScrip) => (
                    <MenuItem
                      value={eachScrip["Scrip Name"]}
                      key={eachScrip["Scrip Name"]}
                    >
                      {eachScrip["Company Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="calc-InvestedAmount">
                {/* <div className="calc-InvestedLable">Invested</div> */}
                <span>
                  <TextField
                    // type="Number"
                    className="calc-Text"
                    id="calc-InvestedTextField"
                    variant="outlined"
                    label="Amount to invest"
                    type="number"
                    name="investedAmount"
                    value={this.state.investedAmount}
                    onChange={this.handleChange}
                  />
                </span>
              </div>
              <div className="calc-YearSlider">
                <div className="calc-YearLable">When could you invest?</div>
                <div className="calc-YearList">
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="1"
                    onClick={this.handleSliderValue}
                  >
                    1yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="2"
                    onClick={this.handleSliderValue}
                  >
                    2yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="3"
                    onClick={this.handleSliderValue}
                  >
                    3yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="4"
                    onClick={this.handleSliderValue}
                  >
                    4yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="5"
                    onClick={this.handleSliderValue}
                  >
                    5yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="6"
                    onClick={this.handleSliderValue}
                  >
                    6yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="7"
                    onClick={this.handleSliderValue}
                  >
                    7yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="8"
                    onClick={this.handleSliderValue}
                  >
                    8yr
                  </div>
                  <div
                    className="calc-Year"
                    name="sliderValue"
                    data-value="9"
                    onClick={this.handleSliderValue}
                  >
                    9yr
                  </div>
                  <div
                    className="calc-LastYear"
                    name="sliderValue"
                    data-value="10"
                    onClick={this.handleSliderValue}
                  >
                    10yr
                  </div>
                </div>
                <div className="calc-SlideSelector">
                  <input
                    type="range"
                    min="1"
                    max="10"
                    name="sliderValue"
                    value={this.state.sliderValue}
                    onChange={this.handleChange}
                    className="calc-Slider"
                  />
                </div>
              </div>
              {/* <div className="calc-ButtonContainer">
                <div className="calc-Button">
                  <Button variant="contained">CALCULATE</Button>
                </div>
              </div> */}
              <div className="calc-ResultHeader">Result</div>
              <div className="calc-ResultBody">
                <div className="calc-ResultLable">
                  <div className="calc-ResultReturn">Return</div>
                  <div className="calc-ResultReturn">Return % </div>
                  <div className="calc-ResultReturn">Investment Amount</div>
                  <div className="calc-ResultReturn">Total Returns</div>
                </div>
                <div className="calc-ResultValue">
                  <div className="calc-ReturnValue">
                    &#x20B9; {this.state.returnValue}
                  </div>
                  <div className="calc-ReturnValue">
                    {this.state.returnPtgValue}
                  </div>
                  <div className="calc-ReturnValue">
                    &#x20B9; {this.state.investedAmount}
                  </div>
                  <div className="calc-ReturnValue">
                    &#x20B9; {this.state.totalReturn}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="calc-Footer">
            {this.state.period == "future" ? (
              <>
                <p
                  style={{
                    display: this.state.period === "future" ? "flex" : "none",
                    width: "300px",
                    lineHeight: "1.5",
                  }}
                >
                  The future returns are calculated based on the backdated
                  performance of the scripts.
                </p>
                <p
                  style={{
                    display: this.state.loaded ? "none" : "inline",
                    fontWeight: "bold",
                  }}
                >
                  Please wait while data is being loaded......
                </p>
                <p
                  style={{
                    display: this.state.resultUnavailable ? "inline" : "none",
                    fontWeight: "bold",
                  }}
                >
                  Result currently unavailable due to technical error.
                  {/* {this.state.totalReturn} */}
                </p>
              </>
            ) : (
              <p
                style={{
                  display: this.state.resultUnavailable ? "inline" : "none",
                  fontWeight: "bold",
                }}
              >
                The future returns are calculated based on the backdated
                performance of the scripts.
                {/* {this.state.totalReturn} */}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default ROIDialogueContent;
