import React, { Component } from "react";
import "../../../assets/css/footerStyle.css";

class PrivicyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="fotter-privacy-policy-main-container">
        <div className="fotter-privicy-policy-body-container">
          <div className="fotter-privicy-policy-content-header-container">
            Introduction
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              GTurns Limited (TM)) operates the web site accessible from the URL
              www.GTurns.com (the &#39;Web Site&#39;).
            </p>
            <p className="fotter-p-classes">
              GTurns Limited is committed to protecting your privacy and
              utilising technology that gives you the most powerful, safe,
              online experience that you can get anywhere else.
            </p>
            <p className="fotter-p-classes">
              GTurns Limited does, however, gather certain information that is
              provided by you to the Web Site. GTurns Limited believes that by
              collecting this information GTurns Limited is able to provide you
              with a personalised web experience, and to assist its own sales
              team and advertisers in efficiently reaching an appropriate
              audience.
            </p>
            <p className="fotter-p-classes">
              GTurns Ltd requests the minimum information it believes necessary
              to achieve this goal.
            </p>
            <p className="fotter-p-classes">
              The following discloses GTurns Data consultants pvt ltd
              information gathering and dissemination practices for the Web
              Site:
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            System Data:
          </div>
          <div className="privicy-policy-content-container">
            {" "}
            <p className="fotter-p-classes">
              GTurns Ltd uses your IP address to help diagnose problems with our
              server and to administer the web site. Your IP address is used to
              identify you and your shopping cart and to gather broad
              demographic information.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Registration Data:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              The Web Site&#39;s registration form may require users to give us
              contact information (like their name and e-mail address), unique
              identifiers (like their PAN Number) and demographic information
              (like their pin code, age or income level). The Web Site uses
              customer contact information from the registration form to send
              the users information about GTurns Ltd and promotional material
              from some of GTurns Data consultants pvt ltd partners. The
              customer&#39;s contact information is also used to contact the
              visitor when necessary. Unique identifiers (such as PAN numbers)
              are collected to verify the user&#39;s identity. Demographic and
              profile data is also collected at the Web Site. We use this data
              to tailor the visitor&#39;s experience at the Web Site, showing
              them contents that we think they might be interested in, and
              displaying the contents according to their preferences. This
              information is shared with advertisers on an aggregate basis.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Links and Co-Branded Sites:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              This site contains links to other Web Sites which are not
              necessarily been screened or reviewed by GTurns.com. GTurns Ltd is
              neither responsible for the privacy practices or the contents of
              such Web Sites nor shall be held answerable for the inaccurate,
              inappropriate or offensive data, material, information, products
              and services that may be viewed on those sites.
            </p>
            <p className="fotter-p-classes">
              GTurns Ltd operate a number of cobranded sites. Policies regarding
              information collection and sharing may differ on some of these
              co-branded sites. If you have any particular questions, you should
              check the privacy statement that appears on each co-branded site.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Order Forms:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              The Web Site uses an order form for customers to request
              information, products and services. The Web Site collects
              visitors&#39; contact information (like their e-mail address),
              unique identifiers (like their PAN number) financial information
              (like their account or credit card numbers) and demographic
              information (like their pin code, age or income level). Contact
              information from the order form is used to send orders,
              information about GTurns Ltd, and promotional material from some
              of GTurns Data consultants pvt ltd partners to the visitors of the
              Web Site. The visitor&#39;s contact information is also used to
              get in touch with the visitor when necessary. Financial
              information that is collected is used to check the users&#39;
              qualifications and bill the user for products and services. Unique
              identifiers (such as PAN numbers) are collected from Web Site
              visitors to verify the user&#39;s identity.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Surveys and Contests:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              The Web Site&#39;s online surveys ask visitors for contact
              information (like their e-mail address) and demographic
              information (like their pin code, age or income level). The Web
              Site uses contact data from its surveys to send the user
              information about GTurns Ltd and promotional material from some of
              GTurns. Data consultants pvt ltd partners. The customer&#39;s
              contact information is also used to contact the visitor when
              necessary. Users may opt-out of receiving future mailings; see the
              choice/opt-out section below. Demographic and profile data is also
              collected at the Web Site. The Web Site use this data to tailor
              its visitor&#39;s experience at the Web Site, showing them content
              that it thinks they might be interested in, and displaying the
              content according to their preferences. This information is shared
              with advertisers on an aggregate basis.
            </p>
            <p className="fotter-p-classes">
              GTurns. Ltd run contests on the Web Site in which we ask visitors
              for contact information (like their e-mail address). GTurns Ltd
              uses contact data from its contests to send users, information
              about GTurns. Ltd and promotional material from some of our
              partners. The customer&#39;s contact information is also used to
              contact the visitor when necessary. Users may opt-out of receiving
              future mailings; see the choice/opt-out section below.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Third-Party Advertisement Servers:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              GTurns Ltd engages the services of an outside advertisement
              company to display advertisement on the Web Site. These
              advertisements may contain cookies. While GTurns Ltd uses cookies
              in other parts of the Web Site, cookies received with banner
              advertisements are collected by the Advertisement company, and
              GTurns Ltd does not have access to this information.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Protection of your Data and Information:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              The privacy and protection of your data and information provided
              to us is of vital importance. GTurns Limited shall ensure to
              safeguard the security and confidentiality of any information you
              share with us. Any personally identifiable information of the
              customers obtained by us shall not be used or shared other than
              for the purposes to which the customers consents. However security
              and confidentiality of information cannot be guaranteed cent
              percent. Hence despite our utmost efforts to protect your personal
              information, GTurns Limited and GTurns.com cannot warrant the
              security of any information you transmit to us through our online
              services. Such transmission of your personal information is done
              at your own risk.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Disclosure of Customer Information:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              GTurns Limited and GTurns.com shall not deliberately disclose
              customer&#39;s personally identifiable information to any
              unauthorized third person, save and except where such information
              shall be disclosed in good faith and for the purpose of law for
              the time being in force or to protect the rights and property of
              GTurns Limited and GTurns.com.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Public Forums:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              The Web Sites makes chat rooms, forums, message boards and/or news
              groups available to its users. Please remember that any
              information that is disclosed in these areas becomes public
              information and you should exercise caution when deciding to
              disclose your personal information.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Supplemental Information :
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              The Web Site supplements the information that you provide with
              information that is received from third parties.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Children&#39;s Guidelines:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">The Web Site:</p>
            <ul className="fotter-unOrdered-list-class">
              <li>
                <p className="fotter-p-classes">
                  DOES NOT collect online information from children without
                  prior parental/guardian consent or parental/guardian
                  notification, which will include an opportunity for the parent
                  to prevent use of the information and participation in the
                  activity. Without prior parental consent, online information
                  will only be used to respond directly to the child&#39;s
                  request and will not be used for other purposes without prior
                  parental consent;
                </p>
              </li>
              <li>
                <p className="fotter-p-classes">
                  DOES NOT collect personally identifiable offline contact
                  information without prior parental consent;
                </p>
              </li>
              <li>
                <p className="fotter-p-classes">
                  DOES NOT distribute to third parties any personally
                  identifiable information without prior parental consent;
                </p>
              </li>
              <li>
                <p className="fotter-p-classes">
                  DOES NOT give the ability to publicly post or otherwise
                  distribute personally identifiable contact information without
                  prior parental consent; and
                </p>
              </li>
              <li>
                <p className="fotter-p-classes">
                  DOES NOT entice by the prospect of a special game, prize or
                  other activity, to divulge more information than is needed to
                  participate in the activity.
                </p>
              </li>
            </ul>
            <p className="fotter-p-classes">
              Further to the above, in light of the recently notified
              Information Technology (Reasonable security practices and
              procedures and sensitive personal data or information) Rules,
              2011.(Hereinafter referred to as the Rules)
            </p>
            <p className="fotter-p-classes">
              Following amendments deem required under the Privacy Policy –
            </p>
            <p className="fotter-p-classes">
              The following shall be deemed to be sensitive information –
            </p>
            <p className="fotter-p-classes">
              Sensitive personal data or information of a person means such
              personal information which consists of information relating to;―
            </p>
            <p className="fotter-p-classes">
              (i) password;
              <br /> (ii) financial information such as Bank account or credit
              card or debit card or other payment instrument details ; <br />
              (iii) physical, physiological and mental health condition;
              <br /> (iv) sexual orientation; <br />
              (v) medical records and history;
              <br /> (vi) Biometric information; <br />
              (vii) any detail relating to the above clauses as provided to body
              corporate for providing service; and <br />
              (viii) any of the information received under above clauses by body
              corporate for processing, stored or processed under lawful
              contract or otherwise:
            </p>
            <p className="fotter-p-classes">
              provided that, any information that is freely available or
              accessible in public domain or furnished under the Right to
              Information Act, 2005 or any other law for the time being in force
              shall not be regarded as sensitive personal data or information
              for the purposes of Rules.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Collection of Information:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              Any personal information collected from the client would be
              collected in order to satisfy the following conditions –
            </p>
            <p className="fotter-p-classes">
              1. Compliance with KYC guidelines as per SEBI / Exchanges / Or any
              other Act or regulation requiring the same and any internal KYC
              guidelines formulated by GTurns.
              <br /> 2. In order to provide enhanced trading experience to the
              client.
              <br /> 3. In order to provide any further information to be
              provided to regulators.
            </p>
            <p className="fotter-p-classes">
              Such information may be collected in a manner that the client is
              always aware of the collection and purported usage of the same.
              The client shall have an option to withdraw consent to share the
              information. However such intimation shall be required in writing.
              Such intimation shall only be taken into account from the date on
              which the same is received. GTurns may stop services in case such
              intimation is received in case the same is required under the
              regulations. Such information shall only be used for the purpose
              it is collected.
            </p>
            <p className="fotter-p-classes">
              Further once the information is not required the same would be
              deleted provided the same is in compliance with the Regulatory
              requirements. Clients would be allowed an option not to disclose
              any information to be collected, provided the same is in
              compliance with regulatory requirements.
            </p>
            <p className="fotter-p-classes">
              For any grievances the clients should send communication to
              igc@GTurns.com. GTurns shall endeavor to resolve issues in a time
              bound manner. It shall be our endeavor to resolve such issues
              within 30 days from receipt.
            </p>
          </div>
          <div className="fotter-privicy-policy-content-header-container">
            Disclosure of information:
          </div>
          <div className="privicy-policy-content-container">
            <p className="fotter-p-classes">
              1. Disclosure of sensitive information would be as per the terms
              agreed by the clients. For any change in such terms, the clients
              would be informed. 
              <br />
              2. Provided no consent may be required in case the same is being
              shared as per compliance guidelines or as required by regulators. 
              <br />
              3. Any information being disclosed to third parties would be in
              accordance with the Rules and would be done provided such third
              party has the same level of compliance guidelines as GTurns. 
              <br />
              4. GTurns shall allow clients to review their personal information
              and the same shall be allowed to be edited provided exchange
              guidelines relevant to the same are followed.
            </p>
            <p className="fotter-p-classes">
              GTurns shall maintain, reasonable security practices and
              procedures and maintain a comprehensive documented information
              security programme. In the event of an information security
              breach, GTurns or a person on its behalf shall be required to
              demonstrate, as and when called upon to do so by the agency
              mandated under the law, that we have implemented security control
              measures as per their documented information security programme
              and information security policies and information security
              policies that contain managerial, technical, operational and
              physical security control measures that are commensurate with the
              information assets being protected with the nature of business
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default PrivicyPolicy;
