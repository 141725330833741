import React, { Component } from "react";
import "../../../assets/css/DashboardStyles/servicesDialogStyles.css";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { withRouter } from "react-router-dom";
const materialStyles = theme => ({
  whiteColor: {
    color: "#c1bebe",
    right: "-17%"
  }
});
const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "1.5px solid gray",
    fontSize: 14,
    width: "100%",
    padding: "10px 39px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "#c1bebe",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4
      // borderColor: "#80bdff",
      //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);
const Styles = {
  iconStyle: {
    color: "darkgray",
    cursor: "pointer",
    top: 10,
    position: "relative"
  },
  buttonStyle: {
    backgroundColor: "#FFD118",
    color: "black",
    height: "15px",
    minWidth: "25px",
    padding: 0,
    fontSize: "6px",
    borderRadius: "2px"
  },
  textBoxStyle: {
    outline: "none",
    height: "30px",
    border: "1.5px solid gray",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    width: "100%",
    color: "#c1bebe",
    textAlign: "center"
  },
  backButton: {
    backgroundColor: "transparent",
    color: "rgb(132, 129, 129)",
    right: 25
  },
  nextButton: {
    backgroundColor: "transparent",
    color: "#FFD118",
    right: 25
  }
};

class dilogDreamCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      errorYear: "",
      amount: "",
      errorAmount: "",
      dropDownValues: [3, 5, 7, 9, "10+"],
      isSIP: false
    };
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleNextPress = this.handleNextPress.bind(this);
    this.handleEnterAmountChange = this.handleEnterAmountChange.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
  }
  handleYearChange(e) {
    console.log("year", e.target.value);
    this.setState({ year: e.target.value, errorYear: "" });
  }
  handleNextPress() {
    console.log("next pressed", this.props);
    let inputValues = {};
    let okToAdd = true;
    let yearValidation = this.validateYear(this.state.year);
    let amountValidation = this.validateAmount(this.state.amount);
    if (amountValidation.valid) {
      inputValues.amount = this.state.amount;
    } else {
      okToAdd = false;
      this.setState({
        errorAmount:
          this.state.amount === ""
            ? "Please enter the amount"
            : this.state.isSIP
            ? "Minimum investment for SIP is 5000 Rs"
            : "Minimum investment for One time is 100000 Rs"
      });
    }
    if (yearValidation.valid) inputValues.years = this.state.year;
    else {
      okToAdd = false;
      this.setState({ errorYear: "Please select a year" });
    }
    if (okToAdd) {
      inputValues.heading = "Planning to buy your dream car?";
      inputValues.miniDescription =
        "Equity Investments: a debt-free way to your dream car. You often hear people say that it is the journey, not the destination that matters. And the journey to your destination is always sweeter if you are travelling in your dream car. But, a dream car shouldn’t remain a dream if you plan your finances earlier . GTurns Equity Plan help you to buy a dream car without any debt .Stay invested with us and have a smooth journey .";
      inputValues.mainDesc = {};
      inputValues.mainDesc.title = "Invest for your dream car";
      inputValues.mainDesc.p =
        "Owning a car is every Indian’s dream. So you are not alone in desiring for it. Whether you are a 2-wheeler owner or one without a vehicle, you always dream of owning a car. So how to plan for it? Plan for this with equity investment.";
      inputValues.mainDesc.shortTermReturn = "20%";
      inputValues.mainDesc.mediumTermReturn = "20%";
      inputValues.mainDesc.longTermReturn = "20%";
      inputValues.isEndGoal = true;
      inputValues.isSIP = this.state.isSIP;
      this.props.getPlanningComponentProps(inputValues);
      this.props.history.push("/planingComponents#dreamcarplan");
    }
  }
  validateAmount(amount) {
    if (
      amount !== null && this.state.isSIP ? amount >= 5000 : amount >= 100000
    ) {
      return { valid: true };
    } else {
      return { valid: false };
    }
  }
  validateYear(year) {
    if (year !== "") {
      return { valid: true };
    } else {
      return { valid: false };
    }
  }
  handleEnterPress(event) {
    console.log(event.keyCode, event.which);
    if (event.which === 13) {
      this.handleNextPress();
    }
  }
  handleEnterAmountChange(event) {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      this.setState({ amount: event.target.value, errorAmount: "" });
    } else {
      //this.setState({ amount: parseInt(this.state.amount) });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className="dialogDreamCarBackgroundImage">
        <div className="dialogTransparentBackground">
          <div className="dialog-body-container">
            <div className="dialog-cancle-icon-container">
              <i
                className="material-icons"
                onClick={this.props.handleClose}
                style={Styles.iconStyle}
              >
                clear
              </i>
            </div>
            <div className="dialog-gturns-icon-container">
              <img
                src={require("../../../assets/image/gTurns_gradient@4x.png")}
                alt="Gturns Logo"
                style={{
                  width: "80px",
                  cursor: "pointer"
                }}
              />
            </div>
            <div className="dialog-service-heading-container">
              Drive your dream car !
            </div>
            <div
              className="dialog-dream-car-period-selector"
              style={{ display: "flex", color: "#fff" }}
            >
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  border: "0px solid #fff",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.setState({ isSIP: false });
                }}
              >
                <div>
                  <i className="material-icons">
                    <i className="material-icons">
                      {!this.state.isSIP
                        ? "radio_button_checked"
                        : "radio_button_unchecked"}
                    </i>
                  </i>
                </div>
                <div style={{ marginLeft: 10 }}>One Time</div>
              </div>
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  border: "0px solid #fff",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.setState({ isSIP: true });
                }}
              >
                <div>
                  <i className="material-icons">
                    {this.state.isSIP
                      ? "radio_button_checked"
                      : "radio_button_unchecked"}
                  </i>
                </div>
                <div style={{ marginLeft: 10 }}>Monthly(SIP)</div>
              </div>
            </div>
            <div className="dialog-no-of-years-question-text-and-text-box-container">
              <div className="dialog-dream-car-question-container">
                How soon you are planning for your dream car ?
              </div>
              <div className="dialog-dream-car-text-box-and-buttons-container">
                <select
                  className="native-select"
                  value={this.state.year}
                  onChange={this.handleYearChange}
                >
                  <option className="yearSelector" value="">
                    Please select no. of years
                  </option>
                  {this.state.dropDownValues.map((item, id) => {
                    return (
                      <option
                        className="yearSelector"
                        key={id}
                        style={{ width: "100%" }}
                        value={item}
                      >
                        {item}
                      </option>
                    );
                  })}
                </select>
                <div className="error-message">{this.state.errorYear}</div>
              </div>
            </div>
            <div className="dialog-dream-car-investment-question-and-text-box-container">
              <div className="dialog-dream-car-question-container">
                {this.state.isSIP
                  ? "How much would you like invest Monthly?"
                  : "How much would you like to invest ?"}
              </div>
              <div className="dialog-dream-car-investment-text-box-container">
                <input
                  className="amount-select"
                  type="text"
                  placeholder="Enter amount"
                  onChange={this.handleEnterAmountChange}
                  onKeyPress={this.handleEnterPress}
                  value={this.state.amount}
                />
                <div className="error-message">{this.state.errorAmount}</div>
              </div>
            </div>

            <div className="dialog-dream-car-next-back-buttons">
              <div className="dialog-dream-car-back-button" />
              <div className="dialog-dream-car-next-button">
                <Button
                  style={Styles.nextButton}
                  onClick={this.handleNextPress}
                >
                  NEXT
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(materialStyles)(dilogDreamCar));
