import React, { Component } from "react";
import "../../../assets/css/footerStyle.css";
import PrivacyPolicy from "./PrivacyPolicy.jsx";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = {
  uTagStyles: {
    paddingBottom: "1px",
    borderBottom: "1px solid #80808080",
    fontWeight: 300
  },
  appBar: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "black",
    color: "#FFD118",
    paddingRight: "0px"
  },
  flex: {
    flex: 1
  }
};
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <div className="FooterContainer">
        <div className="footer-anchor-container">
          <p className="footer-anchor">
            <span style={styles.uTagStyles}>Terms & Conditions</span>
          </p>
          <p className="footer-anchor">
            <span style={styles.uTagStyles}>Policies & Procedures</span>
          </p>
          <p className="footer-anchor">
            <span style={styles.uTagStyles} onClick={this.handleClickOpen}>
              Privacy Policy
            </span>
          </p>
        </div>
        <div></div>
        <div className="footer-copyrights-container" style={{flexDirection:"column"}}>
          <div style={{display:"flex"}}>
          <p className="footer-anchor-sub">
            <span>&copy; {1900 + new Date().getYear()} GTurns.</span>
          </p>
          <div>
            <span> All Rights Reserved</span>
          </div>
          </div>
          <div style={{display:"flex" ,padding:"2px 0"}}>
          Powered by : 
              <a
                href="https://www.rayabharitech.com/"
                id="infoHref"
                target="_blank"
                rel="noopener noreferrer"
                style={{textDecoration: "none"}}
              >
                <span style={{color:"rgba(204, 204, 204, 0.8)"}}>
                  Rayabhari Technologies Pvt Ltd.
                </span>
              </a>
              </div>  
          
        </div>
        
        
        <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
          <AppBar style={styles.appBar}>
            <div className="fotter-privacy-policy-Heading-container">
              Website Privacy policy
            </div>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <PrivacyPolicy />
        </Dialog>
      </div>
    );
  }
}

export default Footer;
