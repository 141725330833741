import React, { Component } from "react";
import "../../../assets/css/galleryStyles.css";
import Button from "@material-ui/core/Button";

const Styles = {
  buttonStyle: {
    border: "2px solid #FFD118",
    width: "150px",
    borderRadius: "2px",
    color: "#FFD118",
    // borderRadius: "3px",
    letterSpacing: "0.5px"
  }
};
class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    var hostName = window.location.host;
    var currentPage = window.location.href;
    var protocol = window.location.protocol;
    this.setState({
      hostName: protocol + "//" + hostName,
      currentPage: currentPage
    });
    console.log(
      "props",
      this.props,
      hostName,
      currentPage,
      window.location.protocol
    );
  }
  render() {
    return (
      <div className="gallery-container">
        <div className="gallery-header-text-container">Office Gallery</div>
        <div className="gallery-image-video-container">
          <div className="image-holder">
            <div className="holder-button-background">
              {/* <div>
                <div className="holder-text">Gallery Images</div>
              </div> */}
              <div className="holder-button">
                <Button
                  className="ImageButton"
                  variant="outlined"
                  style={Styles.buttonStyle}
                  onClick={() => {
                    window.location.pathname = "/galleryImages";
                  }}
                >
                  Images
                </Button>
              </div>
            </div>
          </div>
          <div className="video-holder">
            <div className="holder-button-background">
              {/* <div>
                <div className="holder-text">Gallery Images</div>
              </div> */}
              <div className="holder-button">
                <Button
                  className="ImageButton"
                  variant="outlined"
                  style={Styles.buttonStyle}
                  onClick={() => {
                    window.location.pathname = "/galleryVideos";
                  }}
                >
                  VIDEOS
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
