class Api_Client {
  host = "https://adminapi.gturns.com";
  // host = "http://localhost:1338";
  getFaqs(user, cb) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4) {
        console.log(this.status, "Current Status");
        cb(xhttp.responseText, this.status);
        // console.log("response text", xhttp.responseText);
      }
    };
    xhttp.open("GET", this.host + "/getFaqStatus", true);
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send();
  }
  getBlogs(user, cb) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4) {
        cb(xhttp.responseText, this.status);
      }
    };
    xhttp.open("GET", this.host + "/getBlogs", true);
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send();
  }
  getIndvidualBlogs(Blog, cb) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4) {
        cb(xhttp.responseText, this.status);
      }
    };
    xhttp.open("GET", this.host + "/Blogs?id=" + Blog.id, true);
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send();
  }
  postContactUs(contact, cb) {
    return new Promise((resolve, reject) => {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
          resolve(xhttp.responseText);
        }
        //  reject(this.readyState)
      };
      xhttp.open("POST", this.host + "/contactus", true);
      xhttp.setRequestHeader("Content-type", "application/json");
      let data = JSON.stringify({
        name: contact.name,
        email: contact.email,
        message: contact.message,
      });
      xhttp.send(data);
    });
  }
  postSubscription(subscription, cb) {
    return new Promise((resolve, reject) => {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
          resolve(xhttp.responseText);
        }
        //  reject(this.readyState)
      };
      xhttp.open("POST", this.host + "/subscribe", true);
      xhttp.setRequestHeader("Content-type", "application/json");
      let data = JSON.stringify({
        email: subscription.email,
      });
      xhttp.send(data);
    });
  }
  getStartedUser(user, cb) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4) {
        cb(xhttp.responseText, this.status);
      }
    };
    xhttp.open(
      "GET",
      "https://script.google.com/macros/s/AKfycbyB-b2tMtwh3wbjqjg7dHwY_XL3OApaQ79aP-isCon7zAIJVQ0/exec?name=" +
      user.name +
      "&email=" +
      user.email +
      "&phone=" +
      user.phone +
      "&message=" +
      user.message +
      "",
      true
    );
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send();
  }
  fetchROI(userParams, cb) {
    return new Promise((resolve, reject) => {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
          resolve(xhttp.responseText);
        }
        //  reject(this.readyState)
      };
      xhttp.open(
        "GET",
        this.host +
        `/calcROI?sliderValue=${userParams.params.sliderValue}&investedAmount=${userParams.params.investedAmount}&company=${userParams.params.company}`,
        true
      );
      xhttp.setRequestHeader("Content-type", "application/json");
      xhttp.send();
    });
  }
}
var apic = new Api_Client();
export default apic;