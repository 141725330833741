import React, { Component } from "react";
import "../../../assets/css/galleryImagesStyles.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const Styles = {
  imageGridStyles: {
    display: "flex",
    justifyContent: window.innerWidth >= 960 ? "space-evenly" : "center",
    alignItems: "flex-start",
    width: "100%"
  },
  ImagesPaperStyle: {
    margin: "10px",
    marginBottom: "50px",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14)"
  },
  galleryImageStyles: {
    width: "100%",
    height: "100%"
  }
};
class GalleryImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gTurnsGalleryImages: [
        <img
          src={require("../../../assets/image/IMG_7827.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_7838.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_0492.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_7776.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_7793.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/workstation1.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_1325.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_1327.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_1328.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />,
        <img
          src={require("../../../assets/image/IMG_1330.jpg")}
          style={Styles.galleryImageStyles}
          alt=""
        />
      ]
    };
  }
  render() {
    return (
      <div className="gallery-Images-main-container">
        <div className="gallery-images-header-contaier" />
        <div className="gallery-images-heading">Office Images</div>
        <div className="gallery-images-body-container">
          <div className="gallery-images-container">
            <Grid container style={Styles.imageGridStyles}>
              {this.state.gTurnsGalleryImages.map((Images, i) => (
                <Grid key={i}>
                  <Paper
                    className="services-cards-Paper"
                    style={Styles.ImagesPaperStyle}
                  >
                    <div className="gallery-images">{Images}</div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default GalleryImages;
