import React, { Component } from "react";
import "../../../assets/css/galleryImagesStyles.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const Styles = {
  videoGridStyles: {
    display: "flex",
    justifyContent: window.innerWidth >= 960 ? "space-evenly" : "center",
    alignItems: "flex-start",
    width: "100%"
  },
  videoPaperStyle: {
    margin: "10px",
    marginBottom: "50px",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14)"
  }
};
class GalleryVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gTurnsGalleryVideos: [
        <iframe
          title="gallery-videos"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/H1sOwvX0i4w"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />,
        <iframe
          title="gallery-videos"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/Vv3ucAT7jOE"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />,
        <iframe
          title="gallery-videos"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/AzMWvdfiVR4"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />,
        <iframe
          title="gallery-videos"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/Wd5AjJP7Oeo"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />,
        <iframe
          title="gallery-videos"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/xtAIfjoNsak"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />,
        <iframe
          title="gallery-videos"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/I8d5fFHqZdI"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ]
    };
  }

  render() {
    return (
      <div className="gallery-Videos-main-container">
        <div className="gallery-images-header-contaier" />
        <div className="gallery-Videos-heading">Videos</div>
        <div className="gallery-videos-body-container">
          <div className="gallery-videos-container">
            <Grid container style={Styles.videoGridStyles}>
              {this.state.gTurnsGalleryVideos.map((Videos, i) => (
                <Grid key={i}>
                  <Paper
                    className="services-cards-Paper"
                    style={Styles.videoPaperStyle}
                  >
                    <div className="gallery-videos">{Videos}</div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default GalleryVideos;
