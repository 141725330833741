import React, { Component } from "react";
import "./App.css";
import AboutUsPage from "./views/Sections/aboutUs/aboutUs";
import OurMissionPage from "./views/Sections/ourMission/ourMission";
import JourneyPage from "./views/Sections/journey/journey";
import TeamPage from "./views/Sections/team/team";
import GalleryPage from "./views/Sections/gallery/gallery";
import ServicesPage from "./views/Sections/Dashboard/services";
import CertificationPage from "./views/Sections/certification/certification";
import TestimonialsPage from "./views/Sections/testimonials/testimonials.jsx";
import ContactInfoPage from "./views/Sections/contactInfo/contactInfo";
import MapPage from "./views/Sections/Map/map";
import FooterPage from "./views/Sections/footer/footer";
import Header from "./views/Header/header";
import FAQpage from "./views/Sections/faq/faq";
import DashboardPage from "./views/Sections/Dashboard/home";
import Blog from "./views/Sections/blog/blog";
import BlogsList from "./views/Sections/blogsList/blogsList";
import HowItWorks from "./views/Sections/howItWorks/howItWorks";
import Partners from "./views/Sections/Partners/Partners";
import GalleryImage from "./views/Sections/gallery/galleryImages";
import GalleryVideo from "./views/Sections/gallery/galleryVideos";
import PlanningComponent from "./views/Sections/PlaningComponents/planingComponents";
import ProductsPage from "./views/Sections/Products/index";
import SmallCase from "./views/Sections/SmallCase/smallcase";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

var componentProps = {};
function getPlanningComponentProps(prop) {
  console.log("planning props", prop);
  componentProps = prop;
}
class App extends Component {
  render() {
    return (
      <div className="App">
        <Helmet>
          <title>GTurns-Portfolio Management Service</title>
          <meta
            name="GTurns-Portfolio Management Service"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <Header />
        <Router>
          <Switch>
            <Route exact path="/" component={DashboardContainer} />
            <Route path="/about" component={AboutUsContainer} />
            <Route path="/howitworks" component={HowItWorksContainer} />
            <Route path="/faq" component={FAQcontainer} />
            <Route
              path="/blog"
              component={BlogsListContainer}
              {...this.props}
            />
            <Route path="/id/:id" component={Blogcontainer} {...this.props} />
            <Route path="/galleryImages" component={GalleryImages} />
            <Route path="/galleryVideos" component={GalleryVideos} />
            <Route
              path="/planingComponents"
              component={PlanningComponentContainer}
            />
            <Route path="/:id" component={DashboardContainer} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;

class PlanningComponentContainer extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>Planning Component</title>
          <meta
            name="Homepage"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <div className="parallax">
          <PlanningComponent {...componentProps} />
          <ContactInfoPage />
          <FooterPage />
        </div>
      </div>
    );
  }
}
class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parallaxImageUrl: require("./assets/image/AboutUs_BG_1.png")
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.changeBackgroundImage(
        require("./assets/image/architecture-black-and-white-buildings-264594.jpg")
      );
    }, 4000);
  }
  changeBackgroundImage(image) {
    this.setState({
      parallaxImageUrl: image
    });
  }
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns-Portfolio Management Service</title>
          <meta
            name="description"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <div
          className="parallax"
          style={{ background: "rgba(14, 14, 14, 0.93)" }}
        >
          {/* ./assets/image/AboutUs_BG_1.png  {backgroundImage:"url("+this.state.parallaxImageUrl+')' }*/}
          <DashboardPage
            parent={this}
            changeImage={this.changeBackgroundImage}
          />
          <SmallCase />
          <ServicesPage
            getPlanningComponentProps={prop => getPlanningComponentProps(prop)}
          />
          <Partners />
          {/* <ProductsPage /> */}
          <div className="parallax3">
            <TestimonialsPage />
          </div>
          <ContactInfoPage />
          <FooterPage />
        </div>
      </div>
    );
  }
}
class HowItWorksContainer extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns | How It Works</title>
          <meta
            name="How It Works"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <div className="parallax">
          <HowItWorks />
          <ContactInfoPage />
          <FooterPage />
        </div>
      </div>
    );
  }
}
class GalleryImages extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns | Gallery Images</title>
          <meta
            name="Gallery Images"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <GalleryImage />
      </div>
    );
  }
}
class GalleryVideos extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns | Gllery Videos</title>
          <meta
            name="Gllery Videos"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <GalleryVideo />
      </div>
    );
  }
}
class AboutUsContainer extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns | About Us</title>
          <meta
            name="About Us"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <div className="parallax">
          <AboutUsPage />
        </div>
        <div>
          <OurMissionPage />
          <div className="parallax2">
            <JourneyPage />
          </div>
          <TeamPage />
          {/* <GalleryPage />
          <CertificationPage id="certi" /> */}
          <MapPage />
          <ContactInfoPage />
          <FooterPage />
        </div>
      </div>
    );
  }
}

class FAQcontainer extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns | FAQ</title>
          <meta
            name="FAQ"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <div className="parallax">
          <FAQpage />
          <ContactInfoPage />
          <FooterPage />
        </div>
      </div>
    );
  }
}
class Blogcontainer extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns | Blogs</title>
          <meta
            name="Blogs"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <Blog {...this.props} />
        <ContactInfoPage />
        <FooterPage />
      </div>
    );
  }
}
class BlogsListContainer extends Component {
  render() {
    return (
      <div className="main-tabs-container">
        <Helmet>
          <title>GTurns | Blog List</title>
          <meta
            name="Blog List"
            content="This is what you want to show as the page content in the Google SERP Listing"
          />
        </Helmet>
        <BlogsList {...this.props} />
        <ContactInfoPage />
        <FooterPage />
      </div>
    );
  }
}
