import React, { Component } from "react";
import "../../../assets/css/blogStyles.css";
import BlogImage from "./blogImage";
import BlogSocialMedia from "./blogSocialMedia";
import apic from "../../../Core/Apic/apiClint";
import BlogYMAL from "../blog/blogYMAL";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogId: "",
      blogData: [],
      parsedData: [],
      allBlogIds: [],
    };
  }
  componentDidMount() {
    this.GetBlog(this.props.match.params.id);
    this.handleGetBlogs();
  }
  GetBlog(id) {
    let blog = {};
    blog.id = id;
    apic.getIndvidualBlogs(blog, (result, status) => {
      // console.log(status, "stauts before case");
      switch (status) {
        case 0:
          console.log("Failed to connect to the server");
          alert("Failed to connect to the server");
          break;
        case 200:
          // console.log("Result");
          let jsonRes = JSON.parse(result);
          let blogArray = jsonRes
            .map((blogSta) => {
              if (blogSta.status === "Active") {
                return blogSta;
              } else {
                return null;
              }
            })
            .filter((elem) => {
              return elem !== null;
            });
          this.setState({ blogData: blogArray });

          break;
        case 400:
          // console.log("Invalid Params");
          alert(result);
          break;
        case 401:
          // alert("Invalid Params");
          break;
        default:
          console.log("current status", status);
        // alert(result, status);
      }
    });
    console.log(this.state.blogData);
  }
  handleGetBlogs() {
    let user = {};
    apic.getBlogs(user, (result, status) => {
      // console.log(status, "stauts before case");
      switch (status) {
        case 0:
          console.log("Failed to connect to the server");
          alert("Failed to connect to the server");
          break;
        case 200:
          let jsonRes = JSON.parse(result);
          let blogArray = jsonRes
            .map((blogSta) => {
              if (blogSta.status === "Active") {
                return blogSta;
              } else {
                return null;
              }
            })
            .filter((elem) => {
              return elem !== null;
            });
          let blogId = blogArray.map((blog) => blog.id);

          this.setState({
            allBlogIds: blogId,
          });
          // console.log(blogArray, "Blog data");

          break;
        case 400:
          // console.log("Invalid Params");
          // alert(result);
          break;
        case 401:
          // alert("Invalid Params");
          break;
        default:
          console.log("current status", status);
        // alert(result, status);
      }
    });
  }
  nextBlog = (id) => {
    console.log("all blog", this.state.allBlogIds);
    console.log("current blog", id);
    for (let i = 0; i < this.state.allBlogIds.length - 1; i++) {
      if (this.state.allBlogIds[i] === id) {
        console.log("next blog", this.state.allBlogIds[i + 1]);
        this.setState({ selectedID: this.state.allBlogIds[i + 1] });
        this.props.history.push("/id/" + this.state.allBlogIds[i + 1]);
        window.location.reload();
        window.scrollTo(0, 0);
      }
    }
  };
  PrevBlog = (id) => {
    console.log("all blog", this.state.allBlogIds);
    console.log("current blog", id);
    for (let i = 1; i < this.state.allBlogIds.length; i++) {
      if (this.state.allBlogIds[i] === id) {
        this.props.history.push("/id/" + this.state.allBlogIds[i - 1]);
        window.location.reload();
        window.scrollTo(0, 0);
      }
    }
  };
  dateFormater = (date) => {
    var monthsArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateContainer = date.split("-");
    var dateNum = dateContainer[2];
    var month = monthsArray[dateContainer[1] - 1];
    var year = dateContainer[0];
    var blogDate = month + " " + dateNum + ", " + year;
    return blogDate;
  };

  render() {
    return (
      <div className="blog-main-container">
        <div className="blog-main-header-container" id="addaddres" />
        {this.state.blogData.length > 0 ? (
          <div className="blog-body-container">
            {this.state.blogData.map((blogs, id) => (
              <div className="blog-body-contents-container" key={id}>
                <div className="blog-header-container">
                  <div className="blog-header-text-container">
                    {/* What really matters when investing for retirement */}
                    {blogs.title}
                  </div>
                  <div className="blog-name-date-like-view-container">
                    <div className="blog-name-date-container">
                      <div className="blog-name-container">
                        {/* By Lakshmi Narayana */}
                        {blogs.author}
                      </div>
                      <div className="blog-date-container">
                        {/* March 25, 2019 */}

                        {this.dateFormater(blogs.date)}
                      </div>
                    </div>
                    <div className="blog-likes-view-container">
                      <div className="blog-likes-container">
                        <i
                          className="fa fa-thumbs-up"
                          aria-hidden="true"
                          style={{ marginRight: "10px", color: "#ABABAB" }}
                        />
                        <div>45</div>{" "}
                        <div className="likes" style={{ marginLeft: "10px" }}>
                          {" "}
                          Likes
                        </div>
                      </div>
                      <div className="blog-views-container">
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          style={{ marginRight: "10px", color: "#ABABAB" }}
                        />
                        <div>60 </div>{" "}
                        <div className="views" style={{ marginLeft: "10px" }}>
                          {" "}
                          Views
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <BlogImage blogImage={blogs.image} />
                <div className="blog-body-text-1-container">
                  {blogs.briefDescription}
                  {console.log(blogs)}
                </div>
                <div
                  className="blog-body-text-2-container"
                  dangerouslySetInnerHTML={{
                    __html: blogs.detailedDescription,
                  }}
                ></div>
                <div />

                {/* <BlogTable /> */}
                {/* <div className="blog-body-text-4-container">
                  Essentially, wise investing is all about creating a portfolio
                  of investments which grow your money faster than inflation
                  increases your expenses, so that you have enough money in your
                  retired life. Having a greater margin of safety helps you lead
                  a peaceful retired life, especially when you encounter
                  extraordinary situations like health emergencies
                </div> */}
                {/* <BlogTYSK />
                <BlogYMAL /> */}
              </div>
            ))}
            <BlogSocialMedia />
          </div>
        ) : (
          <div
            style={{
              minHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 25,
            }}
          >
            Blog is not avaliable at this moment..
          </div>
        )}

        <div style={{ display: "contents", alignItems: "center" }}>
          <div
            className="researchDesk-Heading-bottom-line"
            style={{ width: "85%" }}
          />
        </div>
        <div className="divider-next-pervious">
          <div className="blog-likes-view-container">
            <div className="blog-likes-container">
              <i
                className="fa fa-thumbs-up"
                aria-hidden="true"
                style={{ marginRight: "10px", color: "#ABABAB" }}
              />
              <div>45</div>{" "}
              <div className="likes" style={{ marginLeft: "10px" }}>
                {" "}
                Likes
              </div>
            </div>
            <div className="blog-views-container">
              <i
                className="fa fa-eye"
                aria-hidden="true"
                style={{ marginRight: "10px", color: "#ABABAB" }}
              />
              <div>60 </div>{" "}
              <div className="views" style={{ marginLeft: "10px" }}>
                {" "}
                Views
              </div>
            </div>
          </div>

          <div className="blog-views-container">
            <button
              disabled={
                this.props.match.params.id === this.state.allBlogIds[0]
                  ? true
                  : false
              }
              className="views"
              id={
                this.props.match.params.id === this.state.allBlogIds[0]
                  ? "previous-container-inActive"
                  : "previous-container-active"
              }
              onClick={() => {
                this.PrevBlog(this.props.match.params.id);
              }}
            >
              PREVIOUS
            </button>{" "}
            <button
              disabled={
                this.props.match.params.id ===
                this.state.allBlogIds[this.state.allBlogIds.length - 1]
                  ? true
                  : false
              }
              className="views"
              id={
                this.props.match.params.id ===
                this.state.allBlogIds[this.state.allBlogIds.length - 1]
                  ? "next-container-inActive"
                  : "next-container-active"
              }
              style={{
                marginRight: "10px",
              }}
              onClick={() => {
                this.nextBlog(this.props.match.params.id);
              }}
            >
              NEXT
            </button>
          </div>
        </div>
        <BlogYMAL {...this.props}></BlogYMAL>
      </div>
    );
  }
}
export default Blog;
