import React, { Component } from "react";
import "../../../assets/css/blogsListStyles.css";
import ResearchDesk from "./researchDesk";
import HowMuchRiskCards from "./howMuchRiskCards";
import BlogListCarousel from "./blogListCarousel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import apic from "../../../Core/Apic/apiClint";
// import BlogApiData from './BlogsAPIData'

const maxlimit = 75;
const Styles = {
  gridStyles: {
    display: "flex",
    justifyContent: window.innerWidth >= 960 ? "space-between" : "space-evenly",
    alignItems: "center",
    width: "100%",
  },
  blogResearchDeskImageStyle: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
};
class BlogsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBlog1: false,
      allBlog: [],
      selectedBlogId: "",
    };
    this.handleGetBlogs = this.handleGetBlogs.bind(this);
  }
  async componentWillMount() {
    await this.handleGetBlogs();
    // console.log(this.props);
  }
  blog1Display = () => {
    this.setState({ showBlog1: true });
    console.log("blog1");
  };
  blog2Display = () => {
    this.setState({ showBlog1: false });
    console.log("blog2");
  };
  async handleGetBlogs() {
    let user = {};
    await apic.getBlogs(user, (result, status) => {
      // console.log(status, "stauts before case");
      switch (status) {
        case 0:
          console.log("Failed to connect to the server");
          alert("Failed to connect to the server");
          break;
        case 200:
          let jsonRes = JSON.parse(result);
          // console.log(result)
          let blogArray = jsonRes
            .map((blogSta) => {
              if (blogSta.status === "Active") {
                return blogSta;
              } else {
                return null;
              }
            })
            .filter((elem) => {
              return elem !== null;
            });
          this.setState({ allBlogs: blogArray });
          if (this.state.allBlogs !== null) {
            this.setState({ selectedBlogId: this.state.allBlogs[0].id });
          }
          // console.log(this.state.selectedBlogId);
          break;
        case 400:
          // console.log("Invalid Params");
          // alert(result);
          break;
        case 401:
          // alert("Invalid Params");
          break;
        default:
          console.log("current status", status);
        // alert(result, status);
      }
    });
  }
  ChangeSelectedBlogId = (id) => {
    console.log(id);
    this.setState({ selectedBlogId: id });
  };
  render() {
    return (
      <div className="blogsList-main-container">
        <div className="blogsListHeaderBackground" />
        <div className="blogsList-body-container">
          <span
            className="temp-blog-header"
            style={{ textAlign: "center", padding: "1.5em 0em" }}
          >
            Blogs
          </span>
          {/* <BlogApiData 
        selectedBlog={this.state.selectedBlogId}
        allBlogs = {this.state.allBlog} 
      /> */}
          <div className="blogsList-body-content-container">
            <div className="carousel-blogs">
              <div className="blogsList-corosal-container">
                {" "}
                <BlogListCarousel {...this.props} />
              </div>
              <div className="blogsList-3cards-container">
                {" "}
                <HowMuchRiskCards {...this.props} />
              </div>
            </div>
            <ResearchDesk
              ChangeBlogId={(id) => this.ChangeSelectedBlogId(id)}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default BlogsList;
