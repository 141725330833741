import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "../../../assets/css/mapStyles.css";

const styles = {
  cardStyle: {
    height: "100%",
    width: window.innerWidth >= 600 ? "85%" : "",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    backgroundColor: "#333",
    color: "white"
  },
  ancharStyles: {
    color: "#ccc",
    fontSize: "13px",
    textDecoration: "none",
    opacity: 0.8
  },
  descriptionTextStyles: {
    width: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "baseline",
    lineHeight: "1.4em",
    marginTop: "5px"
  },
  iconStyles: {
    width: window.innerWidth > 1800 ? "25%" : "50%",
    marginBottom: "8px",
    display: "flex",
    justifyContent: "flex-end"
  }
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="map-container">
        <div className="contactus-heading">Contact Us</div>
        <Grid container className="grid-container">
          <Grid item xs={3} className="map-card-container">
            <Card style={styles.cardStyle}>
              <div>
                <div className="card-image-container">
                  <img
                    src={require("../../../assets/image/AboutUs-contact.png")}
                    alt=""
                  />
                </div>
                <div className="card-info-container">
                  <div className="map-card-header">Customer Support</div>
                  <div className="phone-no-container">
                    <div className="card-deails-sub-container">
                      <div style={styles.iconStyles}>
                        <i className="material-icons">call</i>
                      </div>
                      <div style={styles.descriptionTextStyles}>
                        <a href="tel:+919740335757" style={styles.ancharStyles}>
                          +91-97403 35757
                        </a>
                        <a href="tel:+918971473282" style={styles.ancharStyles}>
                          +91-89714 73282
                        </a>
                      </div>
                    </div>
                    <div className="card-deails-sub-container">
                      <div style={styles.iconStyles}>
                        <i className="material-icons">email</i>
                      </div>
                      <div style={styles.descriptionTextStyles}>
                        <a
                          href="mailto:info@gturns.com"
                          style={styles.ancharStyles}
                        >
                          info@gturns.com
                        </a>
                        <a
                          href="mailto:support@gturns.com"
                          style={styles.ancharStyles}
                        >
                          support@gturns.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={3} className="map-card-container">
            <Card style={styles.cardStyle}>
              <div>
                <div className="card-image-container">
                  <a
                    target="_blank"
                    href="https://goo.gl/maps/zvqWxTj8xuo"
                    className="map-link-anchor"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../../assets/image/AboutUs-contact-Location.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="card-info-container">
                  <div className="map-card-header">Address</div>
                  <p className="card-info-description">
                    #83 Sri Vasavi Arcade, 2nd floor,
                    <br /> 17th cross, 21st main, Banashankari IInd stage,
                    Bangalore - 560070
                  </p>

                  <a
                    style={{ display: "table-cell", marginTop: "10px" }}
                    target="_blank"
                    href="https://goo.gl/maps/zvqWxTj8xuo"
                    className="map-link-anchor"
                    rel="noopener noreferrer"
                  >
                    Find In Map
                  </a>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={3} className="map-card-container">
            <Card style={styles.cardStyle}>
              <div className="businessInfo-container">
                <div className="card-image-container">
                  <img
                    src={require("../../../assets/image/Gturns-aboutuspage-contactus-promotors-partners.png")}
                    alt=""
                  />
                </div>
                <div className="card-info-container">
                  <div className="map-card-header">Promoters/Partners</div>
                  <div
                    className="business-proposal-sub-header"
                    style={styles.ancharStyles}
                  />
                  <div style={styles.descriptionTextStyles}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        width: "100%",
                        alignItems: "center"
                      }}
                    >
                      <div>
                        {" "}
                        <i
                          className="material-icons"
                          style={{
                            marginRight: "10px",
                            fontSize: 16,
                            opacity: 0.87
                          }}
                        >
                          email
                        </i>
                      </div>
                      <a
                        href="mailto:businessproposal@gturns.com"
                        style={styles.ancharStyles}
                      >
                        lakshminarayana@gturns.com
                      </a>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        marginTop: "10px"
                      }}
                    >
                      <div>
                        <i
                          className="material-icons"
                          style={{
                            marginRight: "10px",
                            fontSize: 16,
                            opacity: 0.87
                          }}
                        >
                          call
                        </i>
                      </div>
                      <a href="tel:+919740335757" style={styles.ancharStyles}>
                        +91-97403 35757
                      </a>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        marginTop: "5px"
                      }}
                    >
                      <div>
                        <i
                          className="material-icons"
                          style={{
                            marginRight: "10px",
                            fontSize: 16,
                            opacity: 0.87
                          }}
                        >
                          call
                        </i>
                      </div>
                      <a href="tel:+918971473282" style={styles.ancharStyles}>
                        +91-89714 73282
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Header;
