import React, { Component } from "react";
import "../../../assets/css/planingComponentStyle.css";
import Button from "@material-ui/core/Button";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { withRouter } from "react-router-dom";
// import ChartTest from "./ChartTest";
const styles = {
  buttonStyle: {
    color: "black",
    height: "15px",
    minWidth: "25px",
    padding: 0,
    fontSize: "6px",
    borderRadius: "2px",
    backgroundColor: "#f7f7f7",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.11) 0px 0px 3px 2px, rgba(0, 0, 0, 0) 1px 1px 2px 0px"
  },
  radioButtonStyles: {
    color: "rgb(255, 209, 24)"
  }
};
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

class PlanningComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investmentFrequency: "one time",
      sliderVal: 100000,
      clicked: false,
      checkedOntime: true,
      checkedMonthly: false,
      yearRange: 1,
      rangeMin: 5000,
      rangeMax: 100000000,
      hash: "dreamcar",
      monthlyAmount: [],
      dataSource: {
        chart: {
          caption: "GTurns Growth Rate Comparision",
          yaxisname: "% Growth in GTurns",
          xaxisname: "Time ",
          showhovereffect: "1",
          numbersuffix: " Rs",
          drawcrossline: "1",
          plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
          theme: "fusion"
        },
        categories: [
          {
            category: [
              // {
              //   label: "2012"
              // },
              // {
              //   label: "2013"
              // },
              // {
              //   label: "2014"
              // },
              // {
              //   label: "2015"
              // },
              // {
              //   label: "2016"
              // }
            ]
          }
        ],
        dataset: [
          {
            seriesname: "GTurns",
            data: [
              // {
              //   value: "20"
              // },
              // {
              //   value: "22"
              // },
              // {
              //   value: "27"
              // },
              // {
              //   value: "22"
              // },
              // {
              //   value: "29"
              // }
            ]
          },
          {
            seriesname: "Nifty",
            data: [
              // {
              //   value: "18"
              // },
              // {
              //   value: "19"
              // },
              // {
              //   value: "21"
              // },
              // {
              //   value: "21"
              // },
              // {
              //   value: "24"
              // }
            ]
          },
          {
            seriesname: "Savings",
            data: [
              // {
              //   value: "12"
              // },
              // {
              //   value: "13"
              // },
              // {
              //   value: "15"
              // },
              // {
              //   value: "15"
              // },
              // {
              //   value: "17"
              // }
            ]
          }
        ]
      }
    };
    this.handleSliderValueChange = this.handleSliderValueChange.bind(this);
    this.handleClicked = this.handleClicked.bind(this);
    this.handleRadioButtons = this.handleRadioButtons.bind(this);
  }
  componentWillMount() {
    if (false) {
      let slValue =
        parseInt(this.props.amount) / parseInt(this.props.years) / 12;
      this.setState(
        {
          sliderVal: slValue,
          yearRange: parseInt(this.props.years),
          rangeMax: 100000000,
          rangeMin: 100000
        },
        () => {
          this.calculate(this.state);
        }
      );
    } else {
      this.setState(
        {
          sliderVal: parseInt(this.props.amount),
          yearRange: parseInt(this.props.years),
          rangeMin: 5000,
          rangeMax: 500000
        },
        () => {
          this.calculate(this.state);
        }
      );
    }
  }
  componentDidUpdate(prev, curr, next) {
    console.log(this.state.checkedMonthly, curr.checkedMonthly);
    console.log(curr);
    console.log(this.state);
    if (
      this.state.yearRange !== curr.yearRange ||
      this.state.sliderVal !== curr.sliderVal ||
      this.state.checkedOntime !== curr.checkedOntime ||
      this.state.checkedMonthly !== curr.checkedMonthly
    )
      this.calculate(curr);
  }
  componentDidMount() {
    if (
      this.props.amount !== undefined &&
      this.props.amount !== null &&
      this.props.mainDesc !== undefined &&
      this.props.mainDesc !== null
    ) {
      window.scroll(0, 0);
      let hash = window.location.hash;
      this.setState({
        hash:
          hash === "#marriagePlan"
            ? "marriageplan"
            : hash === "#retirementplan"
            ? "retirementplan"
            : hash === "#educationplan"
            ? "educationplan"
            : hash === "#travelplan"
            ? "travelplan"
            : hash === "#dreamhomeplan"
            ? "home"
            : hash === "#dreamcarplan"
            ? "dreamcar"
            : hash === "#growyourmoney"
            ? "moneygrowth"
            : hash === "#investmentplan"
            ? "investmentplan"
            : "income"
      });
      // this.setState({
      //   sliderVal: parseInt(this.props.amount),
      //   yearRange: parseInt(this.props.years)
      // });
      this.setState(
        {
          checkedOntime: !this.props.isSIP,
          checkedMonthly: this.props.isSIP
        },
        () => {
          if (this.props.isSIP)
            this.setState({ rangeMin: 5000, rangeMax: 1000000 });
          else this.setState({ rangeMin: 100000, rangeMax: 100000000 });
          this.calculate(this.state);
        }
      );
    } else {
      this.props.history.push("/");
    }
  }
  handleSliderValueChange(e, sliderVal) {
    this.setState({ sliderVal: parseInt(e.target.value) });
    console.log("slider value", e.target.value);
  }
  handleClicked(clicked) {
    if (this.state.clicked === true) {
      this.setState({ clicked: false });
    } else {
      this.setState({ clicked: true });
    }
  }
  handleRadioButtons = isSIP => {
    if (isSIP) {
      this.setState(
        {
          checkedOntime: false,
          checkedMonthly: true,
          investmentFrequency:
            this.state.investmentFrequency === "monthly"
              ? "one time"
              : "monthly"
        },
        () => {
          if (this.state.checkedMonthly) {
            this.setState({
              investmentFrequency: "monthly",
              rangeMin: 5000,
              rangeMax: 500000
              //  sliderVal: parseInt(this.props.amount)
            });
          } else {
            this.setState({
              investmentFrequency: "one time",
              rangeMin: 100000,
              sliderVal:
                this.state.sliderVal >= 100000 ? this.state.sliderVal : 100000,
              rangeMax: 100000000
            });
          }
        }
      );
    } else {
      this.setState(
        {
          checkedOntime: true,
          checkedMonthly: false,
          investmentFrequency:
            this.state.investmentFrequency === "monthly"
              ? "one time"
              : "monthly"
        },
        () => {
          if (this.state.checkedMonthly) {
            this.setState({
              investmentFrequency: "monthly",
              rangeMin: 5000,
              rangeMax: 500000
              //  sliderVal: parseInt(this.props.amount)
            });
          } else {
            this.setState({
              investmentFrequency: "one time",
              rangeMin: 100000,
              sliderVal:
                this.state.sliderVal >= 100000 ? this.state.sliderVal : 100000,
              rangeMax: 10000000
            });
          }
        }
      );
    }
  };
  calculate(state) {
    console.log(
      "===================== Calculation started ========================"
    );

    console.log(this.state.checkedOntime ? "Onetime" : "montly");
    console.log(this.state.yearRange);
    console.log(this.state.sliderVal);
    console.log(this.state.dataSource);

    let dataSource = this.state.dataSource;

    dataSource.dataset = [];
    if (this.state.checkedOntime) {
      dataSource.chart.xaxisname = "Time in Years";
      dataSource.dataset = [
        {
          seriesname: "GTurns",
          data: []
        },
        {
          seriesname: "Nifty",
          data: []
        },
        {
          seriesname: this.state.yearRange <= 3 ? "savings" : "FD",
          data: []
        }
      ];
      dataSource.dataset[0].data[0] = { value: this.state.sliderVal };
      dataSource.dataset[1].data[0] = { value: this.state.sliderVal };
      dataSource.dataset[2].data[0] = { value: this.state.sliderVal };

      dataSource.categories[0].category = [];
      dataSource.categories[0].category[0] = { label: 0 + "" };
      for (let i = 1; i <= this.state.yearRange; i++) {
        console.log(i);
        dataSource.categories[0].category[i] = { label: i + "" };

        dataSource.dataset[0].data[i] = {
          value:
            dataSource.dataset[0].data[i - 1].value +
            dataSource.dataset[0].data[i - 1].value * 0.2
        };
        dataSource.dataset[1].data[i] = {
          value:
            dataSource.dataset[1].data[i - 1].value +
            dataSource.dataset[1].data[i - 1].value * 0.13
        };
        dataSource.dataset[2].data[i] = {
          value:
            dataSource.dataset[2].data[i - 1].value +
            dataSource.dataset[2].data[i - 1].value *
              (this.state.yearRange <= 3 ? 0.04 : 0.08)
        };
      }
      this.setState({
        dataSource,
        projectedValue:
          dataSource.dataset[0].data[dataSource.dataset[0].data.length - 1]
            .value
      });

      console.log(dataSource);
    } else {
      dataSource.chart.xaxisname = "Time in Months";
      dataSource.dataset = [
        {
          seriesname: "GTurns",
          data: []
        },
        {
          seriesname: "Nifty",
          data: []
        },
        {
          seriesname: this.state.yearRange <= 3 ? "savings" : "RD",
          data: []
        }
      ];
      dataSource.dataset[0].data[0] = { value: this.state.sliderVal };
      dataSource.dataset[1].data[0] = { value: this.state.sliderVal };
      dataSource.dataset[2].data[0] = { value: this.state.sliderVal };

      dataSource.categories[0].category = [];
      dataSource.categories[0].category[0] = { label: 0 + "" };
      for (let i = 1; i <= this.state.yearRange * 12; i++) {
        console.log(i);
        dataSource.categories[0].category[i] = { label: i + "" };

        dataSource.dataset[0].data[i] = {
          value:
            dataSource.dataset[0].data[i - 1].value +
            dataSource.dataset[0].data[i - 1].value * (0.2 / 12) +
            this.state.sliderVal
        };
        dataSource.dataset[1].data[i] = {
          value:
            dataSource.dataset[1].data[i - 1].value +
            dataSource.dataset[1].data[i - 1].value * (0.13 / 12) +
            this.state.sliderVal
        };
        dataSource.dataset[2].data[i] = {
          value:
            dataSource.dataset[2].data[i - 1].value +
            dataSource.dataset[2].data[i - 1].value *
              (this.state.yearRange <= 3 ? 0.04 / 12 : 0.08 / 12) +
            this.state.sliderVal
        };
      }
      this.setState({
        dataSource,
        projectedValue:
          dataSource.dataset[0].data[dataSource.dataset[0].data.length - 1]
            .value
      });

      console.log(dataSource);
    }
    console.log(
      "===================== Calculation Ended ========================"
    );
  }
  calculate1(state) {
    console.log(
      "===================== Calculation started ========================"
    );
    console.log("invested", this.state);
    console.log("year", state);

    let months = this.state.yearRange * 12;
    let interest = 20;

    let monthly = interest / 12;

    let monthlyAmount = [];
    monthlyAmount[0] = this.state.sliderVal + this.state.sliderVal;
    for (let i = 1; i < months; i++) {
      let nAmount = 0;
      if (!this.state.checkedOntime) {
        nAmount = this.state.sliderVal;
      }
      let amount = monthlyAmount[i - 1] + nAmount;
      monthlyAmount[i] = amount + amount * (monthly / 100);
    }
    this.setState({ projectedValue: monthlyAmount[monthlyAmount.length - 1] });
    console.log(monthlyAmount);
    this.setState({ monthlyAmount });
    console.log(this.state.sliderVal * (this.state.yearRange * 12));
    console.log(
      "chart type",
      state.yearRange <= 2
        ? "Savings"
        : state.yearRange > 2 && state.checkedOntime
        ? "FD"
        : "RD"
    );
    //Set chart Data
    let dataSource = this.state.dataSource;
    dataSource.categories[0].category = [];
    dataSource.dataset[0] = { seriesname: "GTurns", data: [] };
    dataSource.dataset[1] = { seriesname: "NIFTY", data: [] };
    dataSource.dataset[2] = {
      seriesname:
        this.state.yearRange <= 2
          ? "Savings"
          : this.state.checkedOntime
          ? "FD"
          : "RD",
      data: []
    };
    this.setState({ dataSource });
    for (let i = 0; i < months; i++) {
      this.state.dataSource.categories[0].category.push({ label: i + 1 + "" });
      this.state.dataSource.dataset[0].data.push({
        value: monthlyAmount[i] + ""
      });
      this.state.dataSource.dataset[1].data.push({
        value: monthlyAmount[i] - monthlyAmount[i] * (10 / 100) + ""
      });
      this.state.dataSource.dataset[2].data.push({
        value: monthlyAmount[i] - monthlyAmount[i] * (25 / 100) + ""
      });
    }
    console.log(this.state.dataSource.categories[0].category);
    this.setState({ dataSource: this.state.dataSource });
  }
  customFixTo(num) {
    let st = (num + "").split(".");
    return (
      st[0] + "." + ((st[1] && st[1][0]) || 0) + ((st[1] && st[1][1]) || 0)
    );
  }
  getMoneyDetails(num) {
    // if(num>=100000000) return ((num/100000000).toFixed(2))+'M'
    if (num >= 10000000) return this.customFixTo(num / 10000000) + "Cr";
    if (num >= 100000) return this.customFixTo(num / 100000) + "Lac";
    return this.customFixTo(num);
  }

  handleChange = e => {
    this.setState({ yearRange: e.target.value });
    let value = parseInt(e.target.value);
    if (value > 0 && value <= 25) {
      this.setState({ yearRange: e.target.value });
    } else if (value > 25) {
      this.setState({ yearRange: 25, inputYear: 25 });
    } else if (value <= 0) {
      this.setState({ yearRange: 1, inputYear: 1 });
    }
  };
  render() {
    return (
      <div
        className="Header-bar"
        style={{
          backgroundColor: "transparent",
          width: "100%"
        }}
      >
        <div className="TransparentBlackLayer">
          <div className="planing-component-main-container">
            <div className="planing-component-plan-description-section">
              <div className="planing-component-plan-image-section">
                <img
                  className="planning-report-image"
                  src={require("../../../assets/image/homepage_" +
                    this.state.hash +
                    ".svg")}
                  alt="GTurns Logo"
                />
              </div>
              <div className="planing-component-plan-text-section">
                <div className="planing-component-plan-text-heading-section">
                  {this.props.heading}
                </div>
                <div className="planing-component-plan-text-description-section">
                  {this.props.miniDescription}
                </div>
              </div>
            </div>
            <div className="planing-components-body-container">
              <div className="planning-components-body-content-containers">
                <div className="planning-components-body-period-types-containers">
                  <div className="planning-components-body-period-name-containers">
                    Investment type
                  </div>
                  <div className="planning-components-body-period-selectors-containers">
                    <div className="period-selectors-onetime">
                      <div className="period-selectors-onetime-radiobutton">
                        <div className="radiocontainer">
                          <input
                            type="radio"
                            checked={!this.state.checkedMonthly}
                            name="periodRadio"
                            readOnly
                          />
                          <span
                            className="checkmark"
                            onClick={() => this.handleRadioButtons(false)}
                          />
                        </div>
                      </div>
                      <div
                        className="period-selectors-onetime-text"
                        onClick={() => this.handleRadioButtons(false)}
                      >
                        One time
                      </div>
                    </div>
                    <div className="period-selectors-monthly">
                      <div className="period-selectors-onetime-radiobutton">
                        <div className="radiocontainer">
                          <input
                            type="radio"
                            checked={this.state.checkedMonthly}
                            name="periodRadio"
                            readOnly
                          />
                          <span
                            className="checkmark"
                            onClick={() => this.handleRadioButtons(true)}
                          />
                        </div>
                      </div>
                      <div
                        className="period-selectors-onetime-text"
                        onClick={() => this.handleRadioButtons(true)}
                      >
                        Monthly (SIP)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="planning-components-body-invested-excepted-containers">
                  <div className="planning-components-investment-container">
                    <div className="planing-component-plan-text-heading-section">
                      Invested Amount
                      <div
                        style={{
                          fontSize: "14px",
                          color: "rgba(110, 109, 109, 0.76)",
                          paddingTop: "2%",
                          paddingLeft: "5%",
                          width: "15%",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {this.state.checkedMonthly ? "(Monthly)" : "(One time)"}
                      </div>
                    </div>
                    <div className="planning-components-investment-amount-container">
                      <div className="planning-amount">
                        ₹{"  "}{" "}
                        {!isNaN(this.state.sliderVal) &&
                          this.getMoneyDetails(this.state.sliderVal)}
                      </div>
                    </div>
                    <div className="planning-components-range-slider-container">
                      <input
                        type="range"
                        min={this.state.rangeMin}
                        max={this.state.rangeMax}
                        className="amount-slider"
                        id="myRange"
                        value={
                          !isNaN(this.state.sliderVal) && this.state.sliderVal
                        }
                        onChange={this.handleSliderValueChange}
                      />
                    </div>
                    <div className="slider-increase-decrase-buttons">
                      <Button
                        style={styles.buttonStyle}
                        onClick={() => {
                          let inc = 0;
                          // if (this.state.checkedOntime) {
                          //   inc = (parseInt(this.props.amount) * 10) / 100;
                          // } else {
                          // }
                          console.log(this.state.sliderVal);
                          inc = (parseInt(this.state.sliderVal) * 10) / 100;
                          console.log(inc);
                          let newVal = this.state.sliderVal - inc;
                          if (newVal < this.state.rangeMin)
                            newVal = this.state.rangeMin;
                          if (this.state.sliderVal > this.state.rangeMin) {
                            this.setState({
                              sliderVal: newVal
                            });
                          }
                        }}
                      >
                        <i className="fa fa-minus" aria-hidden="true" />
                      </Button>
                      <Button
                        style={styles.buttonStyle}
                        onClick={() => {
                          let inc = 0;
                          console.log(this.state.sliderVal);
                          inc = (parseInt(this.state.sliderVal) * 10) / 100;
                          console.log(inc);
                          let newVal = this.state.sliderVal + inc;
                          if (newVal > this.state.rangeMax)
                            newVal = this.state.rangeMax;
                          if (this.state.sliderVal < this.state.rangeMax)
                            this.setState({
                              sliderVal: newVal
                            });
                        }}
                      >
                        <i className="fa fa-plus" aria-hidden="true" />
                      </Button>
                    </div>
                  </div>
                  <div className="section-devider" />
                  <div className="planning-components-Excepted-container">
                    <div className="planing-component-plan-text-heading-section">
                      Expected returns
                    </div>
                    <div
                      className="excepted-returns-contents-container"
                      style={{ border: "none" }}
                    >
                      <div style={{ width: "100%" }}>
                        <div
                          style={{ padding: 4, fontSize: 14, color: "#666" }}
                        >
                          <input
                            className="planing-component-inputYear"
                            type="number"
                            min="1"
                            max="25"
                            value={this.state.yearRange}
                            onChange={this.handleChange}
                          />
                          Year
                          {this.state.yearRange > 1 ? "s" : ""}
                        </div>
                        <input
                          style={{ width: "99%", height: 2, marginBottom: 20 }}
                          type="range"
                          min={1}
                          max={25}
                          step="1"
                          className="amount-slider"
                          id="myRange2"
                          value={
                            !isNaN(this.state.yearRange) && this.state.yearRange
                          }
                          onChange={e =>
                            this.setState({
                              yearRange: parseInt(e.target.value)
                            })
                          }
                        />
                      </div>
                      <div style={{ width: "100%", display: "flex" }}>
                        {[1, 5, 10, 15, 20, 25].map((d, i) => (
                          <div
                            id={"sliderContainer" + i}
                            key={i}
                            style={{
                              // position:'relative',
                              // left:-20,
                              // border: "1px solid #ccc",
                              flex: 1,
                              display: "flex"
                              // justifyContent: "center",
                              // alignItems: "center"
                            }}
                          >
                            <div id={"slider" + i}>
                              <div
                                className="expected-noof-year-containers"
                                style={{
                                  fontSize: 12,
                                  color:
                                    parseInt(this.state.yearRange) === d
                                      ? "#ffd118"
                                      : "rgb(110, 109, 109)",
                                  backgroundColor:
                                    parseInt(this.state.yearRange) === d
                                      ? "black"
                                      : "transparent"
                                }}
                                onClick={() => this.setState({ yearRange: d })}
                              >
                                {d}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="planning-components-body-invested-projectd-container">
                      <div className="invested-value-container">
                        <div className="invested-value-header">
                          Total investment
                        </div>
                        <div className="invested-value-text">
                          {this.state.checkedOntime
                            ? !isNaN(this.state.sliderVal) &&
                              this.getMoneyDetails(this.state.sliderVal)
                            : !isNaN(this.state.sliderVal) &&
                              this.getMoneyDetails(
                                this.state.yearRange * 12 * this.state.sliderVal
                              )}
                        </div>
                      </div>
                      <div className="expected-value-container">
                        <div className="invested-value-header">
                          Projected value
                        </div>
                        <div className="invested-value-text">
                          {this.state.projectedValue &&
                            this.getMoneyDetails(this.state.projectedValue)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="planning-components-body-plan-info-containers">
                  <div className="plan-info-heading-text-container">
                    {this.props.mainDesc !== undefined &&
                      this.props.mainDesc.title}
                  </div>
                  <div className="plan-info-description-text-container">
                    {this.props.mainDesc !== undefined && this.props.mainDesc.p}
                  </div>
                  <div className="plan-info-description-text-container">
                    Equity markets have been booming in value since the start of
                    the liberalisation era. Whether it is in the short-term,
                    medium-term or long-term, equity fetches high return with
                    minimal risk. It needs a good understanding as to how the
                    equity markets operate in order to make most gains from
                    them. This is where GTurns plays an important role for you.
                  </div>
                  <div className="plan-info-description-text-container">
                    GTurns is managed by Investment professionals who have a
                    deep understanding of the Equity markets. They are deeply
                    committed to investors like you and believe in securing your
                    hard earned money and also fetching high returns, whether
                    the markets are going up or down. They rely on their
                    well-developed investment instincts and also scientific
                    techniques such as fundamental analysis,technical analysis
                    etc.
                    {/************* This Content is Not Required Said By Suhel ***************
                      They could fetch you short term returns of{" "}
                    {this.props.mainDesc !== undefined &&
                      this.props.mainDesc.shortTermReturn}
                    , medium terms returns of{" "}
                    {this.props.mainDesc !== undefined &&
                      this.props.mainDesc.mediumTermReturn}{" "}
                    and long term returns of{" "}
                    {this.props.mainDesc !== undefined &&
                      this.props.mainDesc.longTermReturn}{" "}
                    . */}
                  </div>
                  <div className="plan-info-description-text-container">
                    Invest in Equity markets through GTurns and earn secure,high
                    returns. Please drop a message to know more or call us at{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href="tel:+919740335757"
                    >
                      +91-9740335757 .
                    </a>
                  </div>
                </div>
                <div className="planning-components-body-returns-comparision-containers">
                  <div className="returns-comparing-charts-heading">
                    Expected returns comparing GTurns and NIFTY
                  </div>
                  <div className="returns-comparing-charts">
                    {/* Charts */}
                    {/* <ReactFusioncharts
        type="msline"
        width="100%"
        height="100%"
        dataFormat="JSON"
        dataSource={dataSource}
      /> */}
                    <ReactFC
                      type="msline"
                      width="100%"
                      height="700"
                      dataFormat="JSON"
                      dataSource={this.state.dataSource}
                    />
                    {/* <ChartTest /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PlanningComponent);
