import React, { Component } from "react";
import "../../../assets/css/blogsListResearchDeskStyles.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import apic from "../../../Core/Apic/apiClint";

const maxlimit = 75;
const Styles = {
  gridStyles: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "90%",
    flexWrap: "wrap",
  },
  blogResearchDeskImageStyle: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
};
class ResearchDesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResearchDesk: [],
      status: "",
    };
    this.handleGetBlogs = this.handleGetBlogs.bind(this);
  }
  componentDidMount() {
    // console.log(this.props);
    this.handleGetBlogs();
  }
  handleGetBlogs() {
    let user = {};
    apic.getBlogs(user, (result, status) => {
      // console.log(status, "stauts before case");
      switch (status) {
        case 0:
          console.log("Failed to connect to the server");
          alert("Failed to connect to the server");
          break;
        case 200:
          let jsonRes = JSON.parse(result);
          let blogArray = jsonRes
            .map((blogSta) => {
              if (blogSta.status === "Active") {
                return blogSta;
              } else {
                return null;
              }
            })
            .filter((elem) => {
              return elem !== null;
            });
          this.setState({ ResearchDesk: blogArray });
          // console.log(blogArray, "Blog data");

          break;
        case 400:
          // console.log("Invalid Params");
          // alert(result);
          break;
        case 401:
          // alert("Invalid Params");
          break;
        default:
          console.log("current status", status);
        // alert(result, status);
      }
    });
  }
  readBlog = (id) => {
    this.props.history.push("/id/" + id);
    console.log(this.props, "Props");
  };
  dateFormater = (date) => {
    var monthsArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateContainer = date.split("-");
    var dateNum = dateContainer[2];
    var month = monthsArray[dateContainer[1] - 1];
    var year = dateContainer[0];
    var blogDate = month + " " + dateNum + ", " + year;
    return blogDate;
  };
  render() {
    return (
      <div className="blogsList-researchDesk-main-container">
        <div className="blogsList-researchDesk-Heading-container">
          <div className="researchDesk-Research-desk-text">Research Desk</div>
          <div
            className="researchDesk-View-All-text"
            style={{ visibility: "hidden" }}
          >
            View All
          </div>
        </div>
        <div className="researchDesk-Heading-bottom-line" />
        <div className="blogsList-researchDesk-body-container">
          <div className="blogsList-researchDesk-container">
            <Grid container style={Styles.gridStyles}>
              {this.state.ResearchDesk.map((ReDe, i) => (
                <Grid key={i}>
                  <Paper className="ReSearch-desk-Paper">
                    <div className="research-desk-main-div">
                      <div
                        className="research-desk-image-container"
                        onClick={() => {
                          this.readBlog(ReDe.id);
                        }}
                      >
                        <img
                          src={ReDe.image}
                          alt="blog-1"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                      <div className="research-desk-text-container">
                        <div
                          style={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <div className="research-desk-text-container-header">
                            {ReDe.title}
                          </div>
                          <div className="research-desk-text-container-name-date">
                            <div>{ReDe.author}</div>
                            <div style={{ marginLeft: "20px" }}>
                              {/* {ReDe.date} */}

                              {this.dateFormater(ReDe.date)}
                            </div>
                          </div>
                          <div className="research-desk-text-container-Description">
                            {ReDe.briefDescription.length > maxlimit
                              ? ReDe.briefDescription.substring(
                                  0,
                                  maxlimit - 10
                                ) + "..."
                              : ReDe.briefDescription}
                          </div>
                        </div>
                      </div>
                      <div className="research-desk-current-status-container">
                        <div className="research-desk-current-status-like-view-container">
                          <div className="research-desk-current-status-likes-container">
                            <i
                              className="fa fa-thumbs-up"
                              aria-hidden="true"
                              style={{
                                marginRight: "5px",
                                color: "rgb(101, 101, 101)",
                              }}
                            />
                            <div
                              style={{
                                marginRight: "3px",
                              }}
                            >
                              45
                            </div>
                            <div className="blogListLikes">Likes</div>
                          </div>
                          <div className="research-desk-current-status-views-container">
                            <i
                              className="fa fa-eye"
                              aria-hidden="true"
                              style={{
                                marginRight: "5px",
                                color: "rgb(101, 101, 101)",
                              }}
                            />
                            <div
                              style={{
                                marginRight: "3px",
                              }}
                            >
                              60{" "}
                            </div>
                            <div className="blogListViews">Views</div>
                          </div>
                        </div>
                        <div
                          className="research-desk-current-status-Learn-more-container"
                          onClick={() => {
                            this.readBlog(ReDe.id);
                          }}
                        >
                          Learn More
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
export default ResearchDesk;
