import React, { Component } from "react";
import "../../assets/css/GetStartedDialogStyles.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
const styles = {
  TheInput: {
    fontSize: 12,
    lineHeight: 2.4,
    display: "flex",
    width: "100%"
  },
  buttonStyle: {
    width: 110,
    height: 45,
    backgroundColor: "#ffd118",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: window.innerWidth > 799 ? "fixed" : "relative",
    marginTop: window.innerWidth > 799 ? "1%" : "0%",
    marginLeft: window.innerWidth > 799 ? "1%" : "0%",
    boxShadow: "0px 0px 13px rgba(66, 64, 64, 0.63)"
  },
  formControlStyle: {
    width: window.innerWidth > 799 ? "140px" : "90%"
  }
};
class getStartedDialogContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      dmatAccount: "",
      message: "",
      errorName: "",
      errorPhone: "",
      errorEmail: "",
      errorDmatAccont: "",
      errorMessage: "",
      toggleState: false,
      checkYes: true,
      checkNo: false,
      mailUrl: ""
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleDmatAccount = this.handleDmatAccount.bind(this);
    this.handleGetStarted = this.handleGetStarted.bind(this);
    this.handleCheckedYesSelected = this.handleCheckedYesSelected.bind(this);
  }
  handleName(e) {
    this.setState({ name: e.target.value, errorName: "" });
  }
  handleEmail(e) {
    this.setState({ email: e.target.value, errorEmail: "" });
  }
  handlePhone(e) {
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value) || e.target.value === "") {
      this.setState({ phone: e.target.value, errorPhone: "" });
    } else {
      //this.setState({ amount: parseInt(this.state.amount) });
    }
  }
  handleMessage(e) {
    this.setState({ message: e.target.value, errorMessage: "" });
  }
  handleDmatAccount(e) {
    this.setState({ dmatAccount: e.target.value });
  }
  handleCheckedYesSelected = status => {
    this.setState({
      checkYes: !this.state.checkYes,
      checkNo: !this.state.checkNo
    });
  };
  validatePhone(phone) {
    const pattern = /^[0-9]{10}$/;
    const result = pattern.test(phone);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ phone: "" });
      return { valid: false };
    }
  }
  validateEmail(email) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = pattern.test(email);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ email: "" });
      return { valid: false };
    }
  }
  validateName(name) {
    const pattern = /^[a-zA-Z0-9 ]{3,}$/;
    const result = pattern.test(name);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ name: "" });
      return { valid: false };
    }
  }
  validateMessage(message) {
    const pattern = /^[a-zA-Z0-9 ]{3,}$/;
    const result = pattern.test(message);
    if (result === true) {
      return { valid: true };
    } else {
      this.setState({ message: "" });
      return { valid: false };
    }
  }
  handleGetStarted(toggleState) {
    let user = {};
    let okToLogin = true;
    let nameValidation = this.validateName(this.state.name);
    let emailValidation = this.validateEmail(this.state.email);
    let messageValidation = this.validateMessage(this.state.message);
    let phoneValidation = this.validatePhone(this.state.phone);
    if (nameValidation.valid) user.name = this.state.name;
    else okToLogin = false;
    if (emailValidation.valid) user.email = this.state.email;
    else okToLogin = false;
    if (messageValidation.valid) user.message = this.state.message;
    if (phoneValidation.valid) user.phone = this.state.phone;
    else okToLogin = false;
    if (okToLogin) {
      this.setState({
        mailUrl:
          "https://script.google.com/macros/s/AKfycbyB-b2tMtwh3wbjqjg7dHwY_XL3OApaQ79aP-isCon7zAIJVQ0/exec?name=" +
          this.state.name +
          "&email=" +
          this.state.email +
          "&phone=" +
          this.state.phone +
          "&message=" +
          this.state.message
      });
      this.props.handleGetStaretedDialogClose();
      alert(
        "Thank you for showing your interest in us. We will get back to you shortly"
      );
    } else {
      if (nameValidation.valid === false) {
        this.setState({
          errorName: "Please enter your name"
        });
      }
      if (emailValidation.valid === false) {
        this.setState({
          errorEmail: "Please enter valid EmailID"
        });
      }
      if (messageValidation.valid === false) {
        this.setState({
          errorMessage: "Please type your message"
        });
      }
      if (phoneValidation.valid === false) {
        this.setState({
          errorPhone: "Please enter valid phone number"
        });
      }
    }
  }
  render() {
    return (
      <div className="getStarted-dilog-main-container">
        <div className="getStarted-dilog-cancle-button-container">
          <i
            className="material-icons"
            style={{
              color: "rgb(110, 109, 109)",
              fontSize: 17,
              cursor: "pointer",
              padding: 10
            }}
            onClick={() => {
              this.props.handleGetStaretedDialogClose();
            }}
          >
            clear
          </i>
        </div>
        {this.state.toggleState === false ? (
          <div className="getStarted-dialog-body-container">
            <div className="getStarted-dialog-header-text-image-container">
              <div className="getStarted-dialog-header-text-container">
                <h3 className="getStarted-header-text-heading">
                  Let us know something about you
                </h3>
                <h6 className="getStarted-header-text-description">
                  Invest in Equity markets through GTurns and earn secure, high
                  returns.
                </h6>
              </div>
              <div className="getStarted-dialog-header-image-container">
                <img
                  className="getStarted-dialog-header-image"
                  src={require("../../assets/image/Get-started_popup-card-image.png")}
                  alt="Gturns Logo"
                />
              </div>
            </div>
            <form>
              <div className="getStarted-dialog-textFields-container">
                <div className="getStarted-dialog-name-email-container">
                  <div className="getStarted-dialog-name-container">
                    <TextField
                      label="Name"
                      style={{ width: "95%" }}
                      value={this.state.name}
                      onChange={this.handleName}
                      autoComplete="name"
                    />
                    <div className="errorGetStartedContainers">
                      {this.state.errorName}
                    </div>
                  </div>
                  <div className="getStarted-dialog-email-container">
                    <TextField
                      label="Email"
                      style={{ width: "95%" }}
                      value={this.state.email}
                      onChange={this.handleEmail}
                      autoComplete="email"
                    />
                    <div className="errorGetStartedContainers">
                      {this.state.errorEmail}
                    </div>
                  </div>
                </div>
                <div className="getStarted-dialog-phone-dmatAccount-container">
                  <div className="getStarted-dialog-phone-container">
                    <TextField
                      label="Phone"
                      style={{ width: "100%" }}
                      value={this.state.phone}
                      onChange={this.handlePhone}
                      autoComplete="Phone number"
                    />
                    <div className="errorGetStartedContainers">
                      {this.state.errorPhone}
                    </div>
                  </div>
                  <div className="getStarted-dialog-dmatAccount-container">
                    <div className="question-checkbox-continer">
                      <div className="getStarted-dialog-dmatAccount-question">
                        Do you have DEMAT account ?
                      </div>
                      <div className="getStarted-dialog-dmatAccount-selector">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-evenly"
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="Yeschecked"
                                checked={this.state.checkYes}
                                onChange={() =>
                                  this.handleCheckedYesSelected("Yes")
                                }
                                name="dematAcc"
                                color="default"
                                value="Yes"
                              />
                            }
                            label="Yes"
                          />{" "}
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="Nochecked"
                                checked={this.state.checkNo}
                                onChange={() =>
                                  this.handleCheckedYesSelected("No")
                                }
                                value="No"
                                name="dematAcc"
                                color="default"
                              />
                            }
                            label="No"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="errorGetStartedContainers">
                      {this.state.errorDmatAccont}
                    </div>
                  </div>
                </div>
                <div className="getStarted-dialog-message-container">
                  {this.state.mailUrl && (
                    <iframe
                      id="get-started-frame"
                      title="get-started-frame"
                      style={{ display: "none" }}
                      src={this.state.mailUrl}
                    />
                  )}
                  <TextField
                    id="multilineTextMessage"
                    label="Message"
                    fullWidth
                    type="text"
                    multiline
                    value={this.state.message}
                    onChange={this.handleMessage}
                    style={styles.TheInput}
                  />
                  <div className="errorGetStartedContainers">
                    {this.state.errorMessage}
                  </div>
                </div>
              </div>
            </form>
            <div className="getStarted-dialog-send-container">
              <Button
                style={styles.buttonStyle}
                onClick={this.handleGetStarted}
              >
                <i className="material-icons" style={{ color: "#000000c7" }}>
                  send
                </i>{" "}
                {"  "}NEXT
              </Button>
            </div>
          </div>
        ) : (
          <div className="getStarted-dialog-thank-you-message">
            <div className="getStarted-dialog-check-icon">
              <i
                className="material-icons"
                style={{ fontSize: "60px", color: "#ffd118" }}
              >
                check
              </i>
            </div>
            <div className="getStarted-dialog-thank-you-text">Thank you</div>
            <div className="getStarted-dialog-thank-you-description">
              For your interest in GTurns, we will get back to you soon.
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(getStartedDialogContent);
