import React, { Component } from "react";
import "../../../assets/css/blogListCarousel.css";
// import Carousel from "re-carousel";
class BlogListCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        {
          image: require("../../../assets/image/Gturns_Blog_Image_Updated.png"),
          title: "What is GTURNS"
        },
        {
          image: require("../../../assets/image/GTurns_Blog_investmentPlan.png"),
          title: "Investment Plans"
        },
        {
          image: require("../../../assets/image/calculator_Blog_Image.png"),
          title: "Equity Calculator"
        },
        {
          image: require("../../../assets/image/SmallCase_UpdatedImage_3.png"),
          title: "Smallcase"
        }
      ],

      currentIndex: 0,
      translateValue: 0
    };
  }
  componentDidMount() {
    setInterval(this.goToNextSlide, 3000);
  }
  componentWillUnmount() {
    clearInterval(this.goToNextSlide);
  }
  goToPrevSlide = () => {
    if (this.state.currentIndex === 0) return;

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + this.slideWidth()
    }));
  };
  goToNextSlide = () => {
    // Exiting the method early if we are at the end of the images array.
    // We also want to reset currentIndex and translateValue, so we return
    // to the first image in the array.
    if (this.state.currentIndex === this.state.images.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      });
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -this.slideWidth()
    }));
  };
  // goToSlide = index => {
  //   this.setState({
  //     currentIndex: index + 1,
  //     translateValue: index
  //   });
  // };
  slideWidth = () => {
    return document.querySelector(".blog-slide").clientWidth;
  };
  render() {
    return (
      <div className="blog-slider">
        <div
          className="slider-wrapper"
          style={{
            transform: `translateX(${this.state.translateValue}px)`,
            transition: "transform ease-out 0.45s"
          }}
        >
          {this.state.images.map((image, i) => (
            <Slide key={i} image={image.image} title={image.title} />
          ))}
        </div>
        <div className="bottom-left">
          <div className="titleSlide">
            {" "}
            {this.state.currentIndex === 0
              ? "What is GTURNS ?"
              : this.state.currentIndex === 1
              ? "Investment Plans"
              : this.state.currentIndex === 2
              ? "Equity Calculator"
              : this.state.currentIndex === 3
              ? "Small Case"
              : ""}
          </div>
        </div>
        <div className="blog-carousel-circle-icons">
          <div
            className="circle1"
            // goToNextSlide={this.goToNextSlide}
            style={{ color: this.state.currentIndex === 0 ? "white" : "" }}
            // onClick={() => this.goToSlide(0)}
          >
            &#8226;
          </div>
          <div
            className="circle2"
            style={{ color: this.state.currentIndex === 1 ? "white" : "" }}
            // onClick={() => this.goToSlide(1)}
          >
            &#8226;
          </div>
          <div
            className="circle3"
            style={{ color: this.state.currentIndex === 2 ? "white" : "" }}
            // onClick={() => this.goToSlide(2)}
          >
            &#8226;
          </div>
          <div
            className="circle4"
            style={{ color: this.state.currentIndex === 3 ? "white" : "" }}
            // onClick={() => this.goToSlide(3)}
          >
            &#8226;
          </div>
          {/* <Circles2 goToNextSlide={this.goToNextSlide} />
          <Circles3 goToNextSlide={this.goToNextSlide} />
          <Circles4 goToNextSlide={this.goToNextSlide} /> */}
        </div>
      </div>
    );
  }
}

const Slide = ({ image, title }) => {
  const styles = {
    backgroundImage: `url(${image})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
  };
  return (
    <div className="blog-slide" style={styles}>
      {/* <div className="bottom-left">
        <div className="titleSlide">{title}</div>
      </div> */}
    </div>
  );
};

// const Circles1 = props => {
//   return <div className="circle1">&#8226;</div>;
// };
// const Circles2 = props => {
//   return <div className="circle2">&#8226;</div>;
// };
// const Circles3 = props => {
//   return <div className="circle3">&#8226;</div>;
// };
// const Circles4 = props => {
//   return <div className="circle4">&#8226;</div>;
// };
export default BlogListCarousel;
