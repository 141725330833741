import React, { Component } from "react";
import "../../../assets/css/blogYMALStyles.css";
import Grid from "@material-ui/core/Grid";
import apic from "../../../Core/Apic/apiClint";
import calculator_Blog_Image from "../../../assets/image/calculator_Blog_Image.png";

const Styles = {
  gridStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
};
class BlogYMAL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResearchDesk: [],
      YMAL: [
        // {
        //   image: (
        //     <img
        //       alt=""
        //       src={require("../../../assets/image/compass.png")}
        //       style={{ height: "100%", width: "100%", borderRadius: "2px" }}
        //     />
        //   ),
        //   Text: "How much risk should you take",
        // },
        // {
        //   image: (
        //     <img
        //       alt=""
        //       src={require("../../../assets/image/pexels-photo.jpg")}
        //       style={{ height: "100%", width: "100%", borderRadius: "2px" }}
        //     />
        //   ),
        //   Text: "How much risk should you take",
        // },
        // {
        //   image: (
        //     <img
        //       alt=""
        //       src={require("../../../assets/image/pexels-photo-908288.jpeg")}
        //       style={{ height: "100%", width: "100%", borderRadius: "2px" }}
        //     />
        //   ),
        //   Text: "How much risk should you take",
        // },
        // {
        //   image: (
        //     <img
        //       alt=""
        //       src={require("../../../assets/image/shutterstock_405956278.jpg")}
        //       style={{ height: "100%", width: "100%", borderRadius: "2px" }}
        //     />
        //   ),
        //   Text: "How much risk should you take",
        // },
      ],
    };
  }
  readBlog = (id) => {
    this.props.history.push("/id/" + id);
    window.location.reload();
    window.scrollTo(0, 0);
    // console.log(this.props, "Props");
  };
  componentDidMount() {
    // console.log(this.props.match.params);
    this.handleGetBlogs();
  }
  handleGetBlogs() {
    let user = {};
    apic.getBlogs(user, (result, status) => {
      // console.log(status, "stauts before case");
      switch (status) {
        case 0:
          console.log("Failed to connect to the server");
          alert("Failed to connect to the server");
          break;
        case 200:
          let jsonRes = JSON.parse(result);
          let blogArray = jsonRes
            .map((blogSta) => {
              if (blogSta.status === "Active") {
                return blogSta;
              } else {
                return null;
              }
            })
            .filter((elem) => {
              return elem !== null;
            });
          this.setState({ YMAL: blogArray });
          // console.log(blogArray, "Blog data");

          break;
        case 400:
          // console.log("Invalid Params");
          // alert(result);
          break;
        case 401:
          // alert("Invalid Params");
          break;
        default:
          console.log("current status", status);
        // alert(result, status);
      }
    });
  }
  dateFormater = (date) => {
    var monthsArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateContainer = date.split("-");
    var dateNum = dateContainer[2];
    var month = monthsArray[dateContainer[1] - 1];
    var year = dateContainer[0];
    var blogDate = month + " " + dateNum + ", " + year;
    return blogDate;
  };
  render() {
    return (
      <div className="blog-YMAL-container">
        <div className="blog-YMAL-heading-container">You May Also Like</div>

        <div className="blog-YMAL-body-container">
          <Grid container spacing={8} style={Styles.gridStyles}>
            {this.state.YMAL.map((likes, id) => (
              <div
                className="blog-YMAL-links-container"
                onClick={() => {
                  this.readBlog(likes.id);
                }}
                key={id}
                style={{
                  cursor: "pointer",
                  display:
                    this.props.match.params.id === likes.id ? "none" : "flex",
                }}
              >
                <div className="blog-YMAL-links-image-container">
                  <img
                    src={likes.image}
                    alt="likes"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "2px",
                    }}
                  ></img>
                </div>
                <div className="blog-YMAL-links-text-container">
                  <div>
                    <div className="blog-YMAL-links-heading-name-container">
                      {likes.title}
                      {/* {console.log(likes)} */}
                    </div>
                    <div className="blog-YMAL-links-Name-container">
                      By {likes.author}
                    </div>
                  </div>
                  <div className="blog-YMAL-links-Date-Read-more-container">
                    <div className="blog-YMAL-links-Date-container">
                      {/* March 25, 2019 */}
                      {this.dateFormater(likes.date)}
                    </div>
                    <div
                      className="blog-YMAL-links-Read-more-container"
                      onClick={() => {
                        this.readBlog(likes.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Read More
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {this.state.YMAL.length % 2 === 0 ? (
              <div className="blog-YMAL-links-container-odd"></div>
            ) : (
              ""
            )}
          </Grid>
        </div>
      </div>
    );
  }
}
export default BlogYMAL;
