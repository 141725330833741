import React, { Component } from 'react';
import '../../../assets/css/teamStyles.css';
const styles = {
  founderImageStyle: {
    borderRadius: '250px',
    border: '2px solid #FFD118',
    height: '90%',
    boxShadow:
      '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14)',
  },
  coWorkerImageStyle: {
    borderRadius: 200,
    height: '175px',
    width: '175px',
    border: '2px solid #FFD118',
    boxShadow:
      '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14)',
  },
  NcoWorkerImageStyle: {
    borderRadius: 200,
    height: '175px',
    width: '175px',
    border: '2px solid #FFD118',
    boxShadow:
      '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14)',
  },
  McoWorkerImageStyle: {
    borderRadius: 100,
    width: '80%',
    border: '2px solid #FFD118',
    boxShadow:
      '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14)',
  },
  coWorkerName: {
    fontSize: '25px',
    marginBottom: '10px',
    color: '#000',
  },
  coWorkerPosition: {
    fontSize: '14px',
    marginBottom: '30px',
    textAlign: 'justify',
    color: '#000000ad',
  },
  coWorkerFrontPosition: {
    display: 'flex',
    flex: '0.5',
    justifyContent: ' center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000ad',
    paddingTop: '10px',
  },
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="team-container">
        <div className="team-heading">People</div>
        <div className="founder-details-container">
          <div className="founder-image-container">
            <div className="founder-image">
              <img
                src={require('../../../assets/image/lakshmi_narayana.jpg')}
                style={styles.founderImageStyle}
                alt="Lakshmi Narayan"
              />
            </div>
            <div className="founder-name">Lakshmi Narayana R</div>
            <div className="founder-position">Founder & CEO</div>
          </div>
          <div className="founder-description">
            <div>
              Mr. Lakshmi Narayana R worked at Akamai technologies. He was more
              passionate about equity research, then after moving from Akamai,
              started a career in teaching the equity analysis and investments
              to the professionals and beginners.
            </div>
            <div style={{ marginTop: '2%' }}>
              Expertise in following the trends and has 8 years of experience in
              equity investments. Highly ambitious and goal oriented. Ready to
              take up challenges in a hard shape. Believes equity investment is
              one of the best way to create wealth .
            </div>
            <div style={{ marginTop: '2%' }}>
              Has got several awards recognition for academic and professional
              performances.
            </div>
            {window.innerWidth >= 600 && <br />}
            <div className="founder-socialMedia-icons-holder">
              <a
                href="https://www.linkedin.com/in/lakshmi-narayana-r-8112871a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-linkedin" />
                </div>
              </a>
              <a
                href="https://twitter.com/Lakshmi_nani"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-twitter-square" />
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            height: 1,
            width: '90%',
            backgroundColor: 'rgba(204, 204, 204, 0.8)',
          }}
        /> */}

        {/* <div className="founder-details-container">
          <div className="founder-image-container">
            <div className="founder-image">
              <img
                src={require('../../../assets/image/DilipKumarSar.jpg')}
                style={styles.founderImageStyle}
                alt="Lakshmi Narayan"
              />
            </div>
            <div className="founder-name">Dr Dilip kumar Sar</div>
            <div className="founder-position">Vice President</div>
          </div>
          <div className="founder-description">
            <div>
              Dr Dilip kumar Sar is an academician having more than 15 years of
              experience in various prestigious organizations in different
              capacities.
            </div>
            <div style={{ marginTop: '2%' }}>
              Dr Sar is more passionate towards equity research, wealth creation
              and management. He has a great knowledge towards wealth creation,
              which can directly help individuals to accomplish their financial
              goals
            </div>
            <div style={{ marginTop: '2%' }}>
              In addition to this he has a strong belief that his expertise will
              definitely help the opportunistic individuals and fulfill their
              financial targets.
            </div>
            {window.innerWidth >= 600 && <br />}
            <div className="founder-socialMedia-icons-holder">
              <a
                href="http://linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-linkedin" />
                </div>
              </a>
              <a
                href="https://mobile.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-twitter-square" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            height: 1,
            width: '90%',
            backgroundColor: 'rgba(204, 204, 204, 0.8)',
          }}
        /> */}

        {/* <div className="founder-details-container">
          <div className="founder-image-container">
            <div className="founder-image">
              <img
                src={require("../../../assets/image/Uttam.PNG")}
                style={styles.founderImageStyle}
                alt="Lakshmi Narayan"
              />
            </div>
            <div className="founder-name">Uttam Shetty</div>
            <div className="founder-position">
              Registered Investment Advisor
            </div>
          </div>
          <div className="founder-description">
            <div>
              Mr. Uttam Shetty is a SEBI Registered Investment advisor with more
              than 5 years experience in the Financial domain. He specializes in
              equity research, risk management and handling client portfolio. He
              works with clients to understand their short- and long-term goals,
              level of risk tolerance and overall lifestyle to provide tailored
              advice and effective financial plans to meet their financial
              objectives.
            </div>
            <div style={{ marginTop: "2%" }}>
              He has excellent Analytical Thinking, Problem-solving, reasoning
              and Relationship Management Skills
            </div>
            <div style={{ marginTop: "2%" }}>
              He has been recognized and awarded by many prestigious
              institutions.
            </div>
            {window.innerWidth >= 600 && <br />}
            <div className="founder-socialMedia-icons-holder">
              <a
                href="http://linkedin.com/in/uttam-shetty-0aa976158"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-linkedin" />
                </div>
              </a>
              <a
                href="https://mobile.twitter.com/uttamshetty11"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-twitter-square" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            height: 1,
            width: "90%",
            backgroundColor: "rgba(204, 204, 204, 0.8)",
          }}
        /> */}

        {/* <div className="founder-details-container">
          <div className="founder-image-container">
            <div className="founder-image">
              <img
                src={require("../../../assets/image/Raghu.png")}
                style={styles.founderImageStyle}
                alt="Lakshmi Narayan"
              />
            </div>
            <div className="founder-name">Raghu S</div>
            <div className="founder-position">Director</div>
          </div>
          <div className="founder-description">
            <div>
              Mr. Raghu S worked at Global Foundries. He is more passionate
              about equity research, then after moving from Global Foundries,
              started a career in teaching equity analysis and investments to
              the professionals and beginners.
            </div>

            {window.innerWidth >= 600 && <br />}
            <div className="founder-socialMedia-icons-holder">
              <a
                href="http://linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-linkedin" />
                </div>
              </a>
              <a
                href="https://mobile.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-twitter-square" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            height: 1,
            width: "90%",
            backgroundColor: "rgba(204, 204, 204, 0.8)",
          }}
        /> */}
        {/* <div className="founder-details-container">
          <div className="founder-image-container">
            <div className="founder-image">
              <img
                src={require('../../../assets/image/AravindSrinivasan.jpeg')}
                style={styles.founderImageStyle}
                alt="Aravind Srinivasan"
              />
            </div>
            <div className="founder-name">Aravind Srinivasan</div>
            <div className="founder-position">Chief Technical Officer</div>
          </div>
          <div className="founder-description">
            <div>
              Mr.Aravind Srinivasan is an entrepreneur & a technical strategist
              specializing in maximization of Return On Investment and risk
              management of our client's portfolio.
            </div>
            <div style={{ marginTop: '2%' }}>
              With 8+ years of technical experience from both the corporate and
              the business worlds, he has an astute technical approach by
              working closely with numerous clients providing tailor-made
              strategies to create passive wealth for them.
            </div>
            <div style={{ marginTop: '2%' }}>
              He is one of the finest financial strategists having a clear-cut
              analytical approach towards investors' capital protection keeping
              ROI as his only Bullseye.
            </div>
            {window.innerWidth >= 600 && <br />}
            <div className="founder-socialMedia-icons-holder">
              <a
                href="http://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-linkedin" />
                </div>
              </a>
              <a
                href="https://mobile.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMedia-icon">
                  <i className="fab fa-twitter-square" />
                </div>
              </a>
            </div>
          </div>
        </div> */}
        {/* <div
          style={{
            height: 1,
            width: '90%',
            backgroundColor: 'rgba(204, 204, 204, 0.8)',
          }}
        /> */}

        {/* <div className="team-coWorkers-container"> */}
        {/* <div className="team-coWorkers-subContainer">
            <div className="team-coWorker">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="flip-card-front-img"
                      src={require('../../../assets/image/Yashas.jpg')}
                      alt="Avatar"
                      style={styles.coWorkerImageStyle}
                    />
                  </div>
                  <div className="flip-card-back">
                    <div style={styles.coWorkerName}>Yashas D A</div>
                    <div style={styles.coWorkerPosition}>
                      Associate Research Analyst
                    </div>
                    <div className="coWorkerDetails">
                      An Experienced US Tax Analyst specialized in Private
                      Equity and Hedge Funds Filings. He is good in Time
                      management and Product management .
                      <br />
                      He is inspired by how Hedge Funds work, Transitioned to a
                      Research Associate and looking for a bright Future.
                    </div>
                  </div>
                </div>
              </div>
              <div className="coWorker-Details-front">
                <div className="coWorker-name">Yashas D A</div>
                <div className="coWorker-position">
                  Associate Research Analyst
                </div>
                <div className="coWorker-socialMedia-icons-holder">
                  <a
                    href="https://www.linkedin.com/in/yashas-d-a-9b1416b1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-linkedin" />
                    </div>
                  </a>
                  <a
                    href="https://www.twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-twitter-square" />
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="team-coWorker">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="flip-card-front-img"
                      src={require('../../../assets/image/Nishmitha.jpg')}
                      alt="Avatar"
                      style={styles.NcoWorkerImageStyle}
                    />
                  </div>
                  <div className="flip-card-back">
                    <div style={styles.coWorkerName}>Nishmitha Shetty</div>
                    <div style={styles.coWorkerPosition}>Data Analyst</div>
                    <div className="coWorkerDetails">
                      Nishmitha Shetty worked at ITI Company for testing of
                      secrecy projects for defence & troubleshooting of field
                      equipment for a period of one year. Currently working at
                      Gturns as Data Analyst, experienced in Equity Research &
                      Handling Clients portfolio..
                    </div>
                  </div>
                </div>
              </div>
              <div className="coWorker-Details-front">
                <div className="coWorker-name">Nishmitha Shetty</div>
                <div className="coWorker-position">Data Analyst</div>
                <div className="coWorker-socialMedia-icons-holder">
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-linkedin" />
                    </div>
                  </a>
                  <a
                    href="https://www.twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-twitter-square" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="team-coWorker">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="flip-card-front-img"
                      src={require('../../../assets/image/Meghana.jpg')}
                      alt="Avatar"
                      style={styles.coWorkerImageStyle}
                    />
                  </div>
                  <div className="flip-card-back">
                    <div style={styles.coWorkerName}>Meghana Ramesh</div>
                    <div style={styles.coWorkerPosition}>
                      Senior Data Analyst
                    </div>
                    <div className="coWorkerDetails">
                      She joined here as a fresher . She is very passionate,
                      hardworking and dedicated . Has sound knowledge on data
                      analysis and research .
                    </div>
                  </div>
                </div>
              </div>
              <div className="coWorker-Details-front">
                <div className="coWorker-name">Meghana Ramesh</div>
                <div className="coWorker-position">Senior Data Analyst</div>
                <div className="coWorker-socialMedia-icons-holder">
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-linkedin" />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-twitter-square" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        {/* <div className="team-coWorkers-subContainer"></div> */}
        {/* <div className="team-coWorkers-subContainer"> */}
        {/* <div className="team-coWorker">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="flip-card-front-img"
                      src={require("../../../assets/image/shreekanthM.JPG")}
                      alt="Avatar"
                      style={styles.coWorkerImageStyle}
                    />
                  </div>
                  <div className="flip-card-back">
                    <div style={styles.coWorkerName}>Shreekanth M </div>
                    <div style={styles.coWorkerPosition}>
                      Partner & Business developer
                    </div>
                    <div className="coWorkerDetails">
                      Mr. SHREEKANTH.M has overall 4 years experience in
                      Business development and Project analyst. Prior to GTurns
                      he has worked with premier sports and infra & G V
                      importers . He is very passionate about building sales and
                      marketing strategies and have also attended many national
                      level conferences on International marketing. Had a strong
                      track record of business to business sales at corporate
                      level.
                    </div>
                  </div>
                </div>
              </div>
              <div className="coWorker-Details-front">
                <div className="coWorker-name">Shreekanth M</div>
                <div className="coWorker-position">
                  Partner & Business developer
                </div>
                <div className="coWorker-socialMedia-icons-holder">
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-linkedin" />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-twitter-square" />
                    </div>
                  </a>
                </div>
              </div>
            </div> */}
        {/* <div className="team-coWorker">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="flip-card-front-img"
                      src={require('../../../assets/image/Kavya.png')}
                      alt="Avatar"
                      style={styles.coWorkerImageStyle}
                    />
                  </div>
                  <div className="flip-card-back">
                    <div style={styles.coWorkerName}>Kavya G N</div>
                    <div style={styles.coWorkerPosition}>Data Analyst</div>
                    <div className="coWorkerDetails">
                      Kavya worked as a Relationship Officer in Quess corp ltd
                      deputed by Axis Bank for a period of nearly 2 years.
                      Currently working at Gturns as Data Analyst, experienced
                      in Equity Research & Handling Clients portfolio.
                    </div>
                  </div>
                </div>
              </div>
              <div className="coWorker-Details-front">
                <div className="coWorker-name">Kavya G N</div>
                <div className="coWorker-position">Data Analyst</div>
                <div className="coWorker-socialMedia-icons-holder">
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-linkedin" />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="socialMedia-icon">
                      <i className="fab fa-twitter-square" />
                    </div>
                  </a>
                </div>
              </div>
            </div> */}
        {/* <div className="team-coWorker"></div> */}
        {/* <div className="team-coWorker"></div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    );
  }
}

export default Header;
