import React, { Component } from "react";
import "../../../assets/css/aboutUsStyles.css";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className="Header-bar"
        style={{
          backgroundColor: "transparent",
          width: "100%"
        }}
      >
        <div className="TransparentBlackLayer">
          <div className="aboutUs-container">
            <div className="heading-container">
              <div className="aboutUs-heading">Welcome To GTurns</div>
              <div className="aboutUs-description">
                <p>
                  Direct equity investors are often seen disappointed with the
                  performance of their portfolios, as they have very limited
                  knowledge and limited time, hence we are here to solve your
                  problem. GTurns posseses the required skills and provides
                  professional management of your investments to create wealth.
                </p>
                <p style={{ marginTop: "2%" }}>
                  We at GTurns Data Consultants Private Limited are here to
                  solve this problem and free you from the constraint and hassle
                  of giving enough time, and providing you the right information
                  for each trade, not only just by theory, but also by
                  relentless trading practice guided by real-time, data-driven
                  algorithms and decades of market expertise. We know that each
                  trade has a reason, each trade has a goal, whether it’s to
                  fuel a dream or to make access to the better things life has
                  to offer. With us, you finally have someone to share that
                  vision with and make it real.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AboutUs;
