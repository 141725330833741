import React, { Component } from "react";
import "../../../assets/css/DashboardStyles/servicesDialogStyles.css";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
const materialStyles = theme => ({
  whiteColor: {
    color: "#c1bebe",
    right: "-17%"
  }
});
const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "1.5px solid gray",
    fontSize: 14,
    padding: "10px 39px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "#c1bebe",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4
      // borderColor: "#80bdff",
      //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);
const Styles = {
  iconStyle: {
    color: "darkgray",
    cursor: "pointer",
    top: 10,
    position: "relative"
  },
  buttonStyle: {
    backgroundColor: "#FFD118",
    color: "black",
    height: "15px",
    minWidth: "25px",
    padding: 0,
    fontSize: "6px",
    borderRadius: "2px"
  },
  textBoxStyle: {
    outline: "none",
    height: "30px",
    border: "1.5px solid gray",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    width: "100%",
    color: "#c1bebe",
    textAlign: "center"
  },
  backButton: {
    backgroundColor: "transparent",
    color: "rgb(132, 129, 129)",
    right: 25
  },
  nextButton: {
    backgroundColor: "transparent",
    color: "#FFD118",
    right: 25
  }
};

class dialogEducationPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      errorYear: "",
      amount: "",
      isSIP: false,
      errorAmount: "",
      dropDownValues: [1, 2, 3, 4, "5+"]
    };
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleNextPress = this.handleNextPress.bind(this);
    this.handleEnterAmountChange = this.handleEnterAmountChange.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
  }
  handleYearChange(e) {
    this.setState({ year: e.target.value, errorYear: "" });
  }
  handleNextPress() {
    console.log("next pressed", this.props);
    let inputValues = {};
    let okToAdd = true;
    let yearValidation = this.validateYear(this.state.year);
    let amountValidation = this.validateAmount(this.state.amount);
    if (amountValidation.valid) {
      inputValues.amount = this.state.amount;
    } else {
      okToAdd = false;
      this.setState({
        errorAmount:
          this.state.amount === ""
            ? "Please enter the amount"
            : "Minimum investment is " + (this.state.isSIP ? 5000 : 100000)
      });
    }
    if (yearValidation.valid) inputValues.years = this.state.year;
    else {
      okToAdd = false;
      this.setState({ errorYear: "Please select a year" });
    }
    if (okToAdd) {
      inputValues.heading = "Do we really need child Educational Plan?";
      inputValues.miniDescription =
        "Yes you do! Here's why - At the present rate of inflation, the increasing cost of education worries every parent. Today, a typical MBA course from top business school can cost anything between 5 to 10 lakh. Taking into account the present inflation rate, the education cost will only rise in the future. So, 10 years from now if your child wishes to pursue MBA from top business school, it will not cost anything less than 25 lakh. GTurns educational plan helps you beat inflation with better returns, so that your child can have a better education without any stress.";
      inputValues.mainDesc = {};
      inputValues.mainDesc.title = "Plan for your children’s education";
      inputValues.mainDesc.p =
        "It is your dream to have your children study in Ivy League institutes. But as everyone knows, Education is costly now. It needs whopping amounts of money to sail your children through their education. Plan for your children's education with equity investment.";
      inputValues.mainDesc.shortTermReturn = "20%";
      inputValues.mainDesc.mediumTermReturn = "20%";
      inputValues.mainDesc.longTermReturn = "20%";
      inputValues.isEndGoal = true;
      inputValues.isSIP = this.state.isSIP;
      this.props.getPlanningComponentProps(inputValues);
      this.props.history.push("/planingComponents#educationplan");
    }
  }
  validateAmount(amount) {
    let n = 100000;
    if (this.state.isSIP) {
      n = 5000;
    }
    if (amount !== "" && amount >= n) {
      return { valid: true };
    } else {
      return { valid: false };
    }
  }
  validateYear(year) {
    if (year !== "") {
      return { valid: true };
    } else {
      return { valid: false };
    }
  }
  handleEnterPress(event) {
    console.log(event.keyCode, event.which);
    if (event.which === 13) {
      this.handleNextPress();
    }
  }
  handleEnterAmountChange(event) {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      this.setState({ amount: event.target.value, errorAmount: "" });
    } else {
      //this.setState({ amount: parseInt(this.state.amount) });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className="dialogEductionPlanBackgroundImage">
        <div className="dialogTransparentBackground">
          <div className="dialog-body-container">
            <div className="dialog-cancle-icon-container">
              <i
                className="material-icons"
                onClick={this.props.handleClose}
                style={Styles.iconStyle}
              >
                clear
              </i>
            </div>
            <div className="dialog-gturns-icon-container">
              <img
                src={require("../../../assets/image/gTurns_gradient@4x.png")}
                alt="Gturns Logo"
                style={{
                  width: "80px",
                  cursor: "pointer"
                }}
              />
            </div>
            <div className="dialog-service-heading-container">
              Start investing for your child's education
            </div>
            <div
              className="dialog-dream-car-period-selector"
              style={{ display: "flex", color: "#fff" }}
            >
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  border: "0px solid #fff",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.setState({ isSIP: false });
                }}
              >
                <div>
                  <i className="material-icons">
                    <i className="material-icons">
                      {!this.state.isSIP
                        ? "radio_button_checked"
                        : "radio_button_unchecked"}
                    </i>
                  </i>
                </div>
                <div style={{ marginLeft: 10 }}>One Time</div>
              </div>
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  border: "0px solid #fff",
                  display: "flex",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.setState({ isSIP: true });
                }}
              >
                <div>
                  <i className="material-icons">
                    {this.state.isSIP
                      ? "radio_button_checked"
                      : "radio_button_unchecked"}
                  </i>
                </div>
                <div style={{ marginLeft: 10 }}>Monthly(SIP)</div>
              </div>
            </div>
            <div className="dialog-no-of-years-question-text-and-text-box-container">
              <div className="dialog-dream-car-question-container">
                When will your child likely to start college?
              </div>

              <div className="dialog-dream-car-text-box-and-buttons-container">
                {/* <FormControl>
                  <NativeSelect
                    className="native-select"
                    value={this.state.year}
                    // fullWidth
                    onChange={this.handleYearChange}
                    input={
                      <BootstrapInput
                        name="age"
                        id="age-customized-native-simple"
                      />
                    }
                    classes={{
                      icon: classes.whiteColor
                    }}
                  >
                    <option className="yearSelector" value="">
                      Please select no. of years
                    </option>
                    {this.state.dropDownValues.map((item, id) => {
                      return (
                        <option
                          className="yearSelector"
                          key={id}
                          style={{ width: "100%" }}
                          value={item}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl> */}
                <select
                  className="native-select"
                  value={this.state.year}
                  onChange={this.handleYearChange}
                >
                  <option className="yearSelector" value="">
                    Please select no. of years
                  </option>
                  {this.state.dropDownValues.map((item, id) => {
                    return (
                      <option
                        className="yearSelector"
                        key={id}
                        style={{ width: "100%" }}
                        value={item}
                      >
                        {item}
                      </option>
                    );
                  })}
                </select>
                <div className="error-message">{this.state.errorYear}</div>
              </div>
            </div>
            <div className="dialog-dream-car-investment-question-and-text-box-container">
              <div className="dialog-dream-car-question-container">
                {this.state.isSIP
                  ? "How much would you like invest Monthly?"
                  : "How much would you like to invest ?"}
              </div>

              <div className="dialog-dream-car-investment-text-box-container">
                <input
                  className="amount-select"
                  type="text"
                  placeholder="Enter amount"
                  onChange={this.handleEnterAmountChange}
                  onKeyPress={this.handleEnterPress}
                  value={this.state.amount}
                />
                <div className="error-message">{this.state.errorAmount}</div>
              </div>
            </div>
            <div className="dialog-dream-car-next-back-buttons">
              <div className="dialog-dream-car-back-button" />
              <div className="dialog-dream-car-next-button">
                <Button
                  style={Styles.nextButton}
                  onClick={this.handleNextPress}
                >
                  NEXT
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(materialStyles)(dialogEducationPlan));
