import React, { Component } from "react";
import "../../../assets/css/faqStyle.css";
import Paginations from "../Pagination/pagination";
import Faqheader from "./faqheader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import apic from "../../../Core/Apic/apiClint";
import SearchIcon from "@material-ui/icons/Search";

const styles = {
  typographyStyle: {
    color: "black",
    fontWeight: "300",
    lineHeight: "2em",
    letterSpacing: "0.3px",
    fontSize: "15px"
  },
  expanpanel: {
    boxShadow: "#cec6c6 0px 0px 0px 0px"
  },
  expanpanelSummery: {
    background: "white"
  },
  expandIcon: {
    color: "black"
  },
  gridStyle: {
    flexDirection: "column",
    display: "flex"
  },
  answerTypography: {
    color: "rgb(117, 113, 113)",
    fontSize: "16px",
    paddingTop: "5px",
    width: "100%",
    fontWeight: "300"
  },
  expansionpanelDetails: {
    display: "flex",
    alignItems: "center",
    justifyContents: "center",
    width: "100%"
  },
  textFieldStyles: {
    width: "100%",
    fontSize: "17px",
    height: 50,
    border: "none",
    paddingLeft: 10,
    marginLeft: 20,
    outline: "none",
    color: "gray"
  }
};

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqData: [],
      faqSearhText: "",
      currentPage: 1
    };
    this.handleLoadFaq = this.handleLoadFaq.bind(this);
    this.handleSearchText = this.handleSearchText.bind(this);
  }
  handleLoadFaq() {
    let user = {};
    apic.getFaqs(user, (result, status) => {
      console.log(status, "stauts before case");
      switch (status) {
        case 0:
          console.log("Failed to connect to the server");
          alert("Failed to connect to the server");
          break;
        case 200:
          console.log("Result");
          let jsonRes = JSON.parse(result);
          status === 200 && this.saveFaqs(jsonRes);
          break;
        case 400:
          console.log("Invalid Params");
          alert(result);
          break;
        case 401:
          alert("Invalid Params");
          break;
        default:
          console.log("current status", status);
          alert(result, status);
      }
    });
  }
  handleSearchText(e) {
    this.setState({ faqSearhText: e.target.value });
  }
  saveFaqs(faqs) {
    if (faqs !== null && faqs.length > 0) {
      localStorage.setItem("faqReco", JSON.stringify(faqs));
    }
    this.getFaqData();
  }
  getFaqData() {
    let faqRec = JSON.parse(localStorage.getItem("faqReco"));
    this.setState({ faqData: faqRec });
  }
  componentWillMount() {
    this.getFaqData();
    this.handleLoadFaq();
  }
  componentDidMount() {
    this.getFaqData();
    this.handleLoadFaq();
  }
  goToNextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
  };
  goToPreviousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
  };
  render() {
    var { faqData } = this.state;
    return (
      <div className="faq-main-container">
        <Faqheader />
        <div className="faq-questions-container">
          <div className="faq-search-box-container">
            <input
              style={styles.textFieldStyles}
              placeholder="Search for FAQ"
              onChange={this.handleSearchText}
            />
            <SearchIcon style={{ color: "darkgray", margin: "10px" }} />
          </div>
          <div className="faq-body-container">
            {this.state.currentPage === 1 ? (
              <Grid
                container
                wrap="nowrap"
                style={styles.gridStyle}
                spacing={10}
              >
                {faqData !== null && faqData.length > 0 ? (
                  faqData
                    .slice(0, 10)
                    .filter(QNA => {
                      return QNA.question
                        .toLocaleLowerCase()
                        .includes(this.state.faqSearhText.toLocaleLowerCase());
                    })
                    .map((QA, id) => (
                      <Grid
                        item
                        xs={12}
                        key={id}
                        style={{
                          padding: 0,
                          paddingLeft: 40,
                          paddingRight: 40
                        }}
                      >
                        <ExpansionPanel
                          className="faq-expansion-panel"
                          style={styles.expanpanel}
                        >
                          <ExpansionPanelSummary
                            style={styles.expanpanelSummery}
                            expandIcon={
                              <i
                                className="material-icons"
                                style={styles.expandIcon}
                              >
                                arrow_drop_down
                              </i>
                            }
                          >
                            <Grid>
                              <Typography
                                component={"span"}
                                style={styles.typographyStyle}
                              >
                                {QA.question}
                              </Typography>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            style={styles.expansionpanelDetails}
                          >
                            <Typography
                              component={"span"}
                              style={styles.answerTypography}
                            >
                              {QA.answerType === "image" ? (
                                <img
                                  src={QA.answer}
                                  style={{ width: "80%" }}
                                  alt="FAQPHOTO"
                                />
                              ) : (
                                QA.answer
                              )}
                            </Typography>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <div className="faq-panel-lower-border" />
                        </div>
                      </Grid>
                    ))
                ) : (
                  <Grid
                    item
                    style={{
                      textAlign: "center",
                      opacity: "0.7",
                      textTransform: "uppercase",
                      letterSpacing: "1px"
                    }}
                  >
                    FAQ's are not found .
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid
                container
                wrap="nowrap"
                style={styles.gridStyle}
                spacing={10}
              >
                {faqData !== null && faqData.length > 0 ? (
                  faqData
                    .slice(10, 30)
                    .filter(QNA => {
                      return QNA.question
                        .toLocaleLowerCase()
                        .includes(this.state.faqSearhText.toLocaleLowerCase());
                    })
                    .map((QA, id) => (
                      <Grid
                        item
                        xs={12}
                        key={id}
                        style={{
                          padding: 0,
                          paddingLeft: 40,
                          paddingRight: 40
                        }}
                      >
                        <ExpansionPanel
                          className="faq-expansion-panel"
                          style={styles.expanpanel}
                        >
                          <ExpansionPanelSummary
                            style={styles.expanpanelSummery}
                            expandIcon={
                              <i
                                className="material-icons"
                                style={styles.expandIcon}
                              >
                                arrow_drop_down
                              </i>
                            }
                          >
                            <Grid>
                              <Typography
                                component={"span"}
                                style={styles.typographyStyle}
                              >
                                {QA.question}
                              </Typography>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            style={styles.expansionpanelDetails}
                          >
                            <Typography
                              component={"span"}
                              style={styles.answerTypography}
                            >
                              {QA.answerType === "image" ? (
                                <img
                                  src={QA.answer}
                                  style={{ width: "80%" }}
                                  alt="FAQPHOTO"
                                />
                              ) : (
                                QA.answer
                              )}
                            </Typography>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <div className="faq-panel-lower-border" />
                        </div>
                      </Grid>
                    ))
                ) : (
                  <Grid
                    item
                    style={{
                      textAlign: "center",
                      opacity: "0.7",
                      textTransform: "uppercase",
                      letterSpacing: "1px"
                    }}
                  >
                    FAQ's are not found .
                  </Grid>
                )}
              </Grid>
            )}
            <Paginations
              currentPage={this.state.currentPage}
              goToNext={this.goToNextPage}
              goToPrevious={this.goToPreviousPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
